import styled from 'styled-components';
import { color } from 'styles/enums';
import { FlexMixin, SofiaSmallMixin } from 'styles/mixins';
import checkmark from './images/checkmark.svg';

export const StyledCurrencies = styled.div`
  padding: 0 0 0.5rem;
`;

export const StyledTitle = styled.div`
  margin-bottom: 1rem;
`;

export const StyledInput = styled.input.attrs(({ checked }: { checked: true }) => ({
  style: {
    backgroundImage: `url(${checked ? checkmark : ''})`,
  },
}))`
  min-width: 1.3rem;
  max-height: 1.3rem;

  background-color: ${color.red};

  appearance: none;
  overflow: hidden;

  margin-right: 1rem;

  &:checked {
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const StyledField = styled.label`
  ${FlexMixin({})};

  margin-bottom: 1rem;

  cursor: pointer;

  span {
    ${SofiaSmallMixin};
    line-height: 1.6rem;
  }
`;
