import React from 'react';
import { PageLayout } from 'common/components/layouts';
import { StyledTradePage } from './styles';

const TradePage = () => (
  <PageLayout>
    <StyledTradePage>Coming soon</StyledTradePage>
  </PageLayout>
);

export default TradePage;
