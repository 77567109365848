import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { faqs, tutorial } from 'app/Router';
import { routes } from 'app/Router/utils';

const Title = () => {
  const location = useLocation();
  const path = routes(location.pathname).find(({ href }: { href: string }) => href === window.location.pathname);
  const title = `${path ? `${path.title === faqs.name ? tutorial.name : path.title} | ` : ''}Hoard Marketplace`;

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default Title;
