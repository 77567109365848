import { useState } from 'common/hooks';

export const useSearch = () => {
  const { state, onChange } = useState('');

  const handleSearch = ({ search }: { search: string }) => onChange(search);

  return {
    state,
    onSearch: handleSearch,
  };
};
