const encodeQuery = (key: string, value: string) => `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

export const queryBuild = ({
  page,
  exclude,
  ordering,
  search,
  multi_contracts,
  multi_currencies,
}: queryBuildParams) => {
  let query = '';

  Object.entries({ page, exclude, ordering, search, multi_contracts, multi_currencies }).forEach(([key, value]) => {
    if (value && !!value.length) {
      if (Array.isArray(value)) {
        query += encodeQuery(key, value.join(','));
      } else if (value) {
        query += encodeQuery(key, value);
      }
    }
  });

  return query.replace('&', '');
};
