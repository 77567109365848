import React from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'app/Router/utils';
import { StyledBackground } from './styles';

const Background = () => {
  const location = useLocation();
  const path = routes(location.pathname).find(({ href }: { href: string }) => href === window.location.pathname);

  // @ts-ignore
  return <StyledBackground path={path} />;
};

export default Background;
