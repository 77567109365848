import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { method } from 'common/enums/method';
import { state } from 'common/enums/state';
import { api, web3 } from './enums/api';
import asyncRequest from 'common/utils/http';

export const addressFetchRequest = asyncRequest({
  web3: true,
  method: method.get,
  path: web3.contracts,
});

export const categoriesFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsCategories,
});

export const contractsFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsContracts,
});

export const currenciesFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsCurrencies,
});

export const domainFetchRequest = asyncRequest({
  web3: true,
  method: method.get,
  path: web3.domain,
});

const initialState = {
  address: {
    pawnshop: '',
  },
  categories: [],
  contracts: [],
  currencies: [],
  domain: {},
  pawnshop: {
    max_timelock_period: 8640000,
    platform: {
      min_lender_profit: [],
      deposit_fee: [],
      redemption_fee: [],
      flash_fee: [],
    },
  },
};

export const slice = createSlice({
  name: state.app,
  initialState,
  reducers: {
    setMaxTimelockPeriod: (state, { payload }) => ({
      ...state,
      pawnshop: { ...state.pawnshop, max_timelock_period: payload },
    }),
    setPlatform: (state, { payload }) => ({
      ...state,
      pawnshop: { ...state.pawnshop, platform: payload },
    }),
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(addressFetchRequest.fulfilled, (state, { payload: { pawnshop } }) => ({
      ...state,
      address: { pawnshop },
    }));
    // @ts-ignore
    builder.addCase(categoriesFetchRequest.fulfilled, (state, { payload: { results } }) => ({
      ...state,
      categories: results,
    }));
    // @ts-ignore
    builder.addCase(contractsFetchRequest.fulfilled, (state, { payload: { results } }) => ({
      ...state,
      contracts: results,
    }));
    // @ts-ignore
    builder.addCase(currenciesFetchRequest.fulfilled, (state, { payload: { results } }) => ({
      ...state,
      currencies: results,
    }));
    // @ts-ignore
    builder.addCase(domainFetchRequest.fulfilled, (state, { payload }) => ({
      ...state,
      domain: payload,
    }));
  },
});

export const selectDomain = (state: RootState) => state.app.domain;
export const selectAddress = (state: RootState) => state.app.address;
export const selectCategories = (state: RootState) => state.app.categories;
export const selectContracts = (state: RootState) => state.app.contracts;
export const selectCurrencies = (state: RootState) => state.app.currencies;
export const selectPawnshop = (state: RootState) => state.app.pawnshop;

export const { setMaxTimelockPeriod, setPlatform } = slice.actions;

export default slice.reducer;
