import styled from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexAlignItemsCenterMixin, FlexMixin, SofiaMediumMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledFlashLoanOffersHeader = styled.div`
  ${FlexMixin({})};

  margin: -1rem 0 1rem;
  padding: 2rem;

  > span {
    height: 2rem;

    flex: 1;

    padding: 0 1rem;

    &:nth-child(4) {
      transform: translateX(4rem);
    }
  }
`;

export const StyledFlashLoanOffers = styled.div`
  ${FlexMixin({
    direction: flex.column,
  })};

  ${SofiaMediumMixin};

  animation: fadein 0.35s ease-in;

  > div {
    height: 9rem;

    ${FlexAlignItemsCenterMixin};

    background-color: ${addOpacityToColor(color.white, 5)};

    margin-bottom: 2rem;
    padding: 1rem 2rem;

    > * {
      height: 100%;

      flex: 1;

      padding: 0 1rem;

      &:nth-child(4),
      &:nth-child(5) {
        transform: translateX(4rem);
      }
    }

    button {
      height: 3rem;
      width: 10rem;

      ${SofiaMediumMixin};
    }
  }
`;

export const StyledCol = styled.div`
  ${FlexMixin({
    direction: flex.column,
    justifyContent: flex.flexStart,
  })};

  span {
    text-align: left;

    padding: 0;
  }
`;
