export const ABI = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'borrowerAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'receiverAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'lenderAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'FlashLoanMade',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'lenderAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'ItemClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'ItemDeposited',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'borrowerAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'ItemRedeemed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'ItemSupportStopped',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'ItemSupported',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'ownerAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'ItemWithdrawn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'borrowerAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'lenderAddress',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'LoanTaken',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'LoanTokenSupportStopped',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'LoanTokenSupported',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'time',
        type: 'uint256',
      },
    ],
    name: 'MaxTimelockPeriodSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        indexed: false,
        internalType: 'struct FractionMath.Fraction',
        name: 'minProfit',
        type: 'tuple',
      },
    ],
    name: 'MinLenderProfitSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'lenderAddres',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'OfferCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        indexed: false,
        internalType: 'struct FractionMath.Fraction',
        name: 'depositFee',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        indexed: false,
        internalType: 'struct FractionMath.Fraction',
        name: 'redemptionFee',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        indexed: false,
        internalType: 'struct FractionMath.Fraction',
        name: 'flashFee',
        type: 'tuple',
      },
    ],
    name: 'PawnshopFeesSet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'staker',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'RewardClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'staker',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'Staked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'staker',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'Unstaked',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'loanTokenAddress',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'depositTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct OfferModel.Item',
            name: 'collateralItem',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'itemValue',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'redemptionPrice',
                type: 'uint256',
              },
              {
                internalType: 'uint32',
                name: 'timelockPeriod',
                type: 'uint32',
              },
            ],
            internalType: 'struct OfferModel.LoanParams',
            name: 'loanParams',
            type: 'tuple',
          },
        ],
        internalType: 'struct OfferModel.Offer',
        name: 'offer',
        type: 'tuple',
      },
    ],
    name: 'cancelOffer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'claimAndWithdrawItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'claimItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'claimRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'stakerAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
    ],
    name: 'claimableReward',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
    ],
    name: 'depositFee',
    outputs: [
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'depositItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'emergencyStakeRecovery',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
    ],
    name: 'flashFee',
    outputs: [
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC3156FlashBorrower',
        name: 'receiver',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'lenderAddress',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'loanTokenAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'loanAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'returnAmount',
            type: 'uint256',
          },
        ],
        internalType: 'struct FlashOfferModel.FlashOffer',
        name: 'offer',
        type: 'tuple',
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes',
      },
    ],
    name: 'flashLoan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'contract IERC20',
        name: 'stakingToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'maxTimelockPeriod',
        type: 'uint256',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'isItemTokenSupported',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'isLoanTokenSupported',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'isSignatureUsed',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'itemDepositTimestamp',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'itemTokenAddress',
        type: 'address',
      },
    ],
    name: 'itemHandler',
    outputs: [
      {
        internalType: 'contract IHandler',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'itemOwner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'itemTokenByIndex',
    outputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'handlerAddress',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: 'isCurrentlySupported',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'loan',
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: 'uint32',
                name: 'nonce',
                type: 'uint32',
              },
              {
                internalType: 'uint32',
                name: 'timelockPeriod',
                type: 'uint32',
              },
              {
                internalType: 'address',
                name: 'loanTokenAddress',
                type: 'address',
              },
              {
                internalType: 'address',
                name: 'itemTokenAddress',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'itemTokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'itemValue',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'redemptionPrice',
                type: 'uint256',
              },
            ],
            internalType: 'struct StoredOfferModel.StoredOffer',
            name: 'offer',
            type: 'tuple',
          },
          {
            internalType: 'enum LoanModel.LoanStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'borrowerAddress',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'lenderAddress',
            type: 'address',
          },
          {
            internalType: 'uint48',
            name: 'redemptionFeeNumerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'redemptionFeeDenominator',
            type: 'uint48',
          },
          {
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
        ],
        internalType: 'struct LoanModel.Loan',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'loanTokenByIndex',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'enum EnumerableMap.SupportState',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxTimelockPeriod',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
    ],
    name: 'minLenderProfit',
    outputs: [
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'loanAmount',
        type: 'uint256',
      },
    ],
    name: 'minReturnAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    name: 'onERC721Received',
    outputs: [
      {
        internalType: 'bytes4',
        name: '',
        type: 'bytes4',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'redeemAndWithdrawItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'redeemItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'redemptionDeadline',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
    ],
    name: 'redemptionFee',
    outputs: [
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: 'signatureHash',
        type: 'bytes32',
      },
    ],
    name: 'redemptionPriceWithFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'time',
        type: 'uint256',
      },
    ],
    name: 'setMaxTimelockPeriod',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'minProfit',
        type: 'tuple',
      },
    ],
    name: 'setMinLenderProfit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'depositFee',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'redemptionFee',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'flashFee',
        type: 'tuple',
      },
    ],
    name: 'setPawnshopFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'stake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'staker',
        type: 'address',
      },
    ],
    name: 'stakedAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'stakingToken',
    outputs: [
      {
        internalType: 'contract IERC20',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'stopSupportingItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'stopSupportingLoanToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IHandler',
        name: 'handler',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'supportItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'minProfit',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'depositFee',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'redemptionFee',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'uint48',
            name: 'numerator',
            type: 'uint48',
          },
          {
            internalType: 'uint48',
            name: 'denominator',
            type: 'uint48',
          },
        ],
        internalType: 'struct FractionMath.Fraction',
        name: 'flashFee',
        type: 'tuple',
      },
    ],
    name: 'supportLoanToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'lenderAddress',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'nonce',
            type: 'uint32',
          },
          {
            internalType: 'uint40',
            name: 'expirationTime',
            type: 'uint40',
          },
          {
            internalType: 'address',
            name: 'loanTokenAddress',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'address',
                name: 'tokenAddress',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'depositTimestamp',
                type: 'uint256',
              },
            ],
            internalType: 'struct OfferModel.Item',
            name: 'collateralItem',
            type: 'tuple',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'itemValue',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'redemptionPrice',
                type: 'uint256',
              },
              {
                internalType: 'uint32',
                name: 'timelockPeriod',
                type: 'uint32',
              },
            ],
            internalType: 'struct OfferModel.LoanParams',
            name: 'loanParams',
            type: 'tuple',
          },
        ],
        internalType: 'struct OfferModel.Offer',
        name: 'offer',
        type: 'tuple',
      },
    ],
    name: 'takeLoan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
    ],
    name: 'totalClaimedRewards',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalItemTokens',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalLoanTokens',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'loanTokenAddress',
        type: 'address',
      },
    ],
    name: 'totalRewards',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalStaked',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'unstake',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
    ],
    name: 'wasLoanTokenEverSupported',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'withdrawItem',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
