import React from 'react';
import { borrow, flash, history, lend, loansTab } from 'app/Router';
import { TabPanel, Tabs, useTabs } from 'common/components/bits';
import { BorrowTab, FlashTab, LendTab } from '../../components';
import { PageLayout } from 'common/components/layouts';

const tabs = [
  { id: lend.id, name: lend.name, tab: lend.tab },
  { id: borrow.id, name: borrow.name, tab: borrow.tab },
  { id: flash.id, name: flash.name, tab: flash.tab },
];

const LoansPage = () => {
  // @ts-ignore
  const { activeTab, selectTab } = useTabs({ tabs });

  const handleSelect = (tabIndex: number) => {
    const { tab } = selectTab(tabIndex);

    history.push(loansTab({ tab }));
  };

  return (
    <PageLayout>
      <Tabs
        // @ts-ignore
        tabs={tabs}
        selectedIndex={activeTab.id}
        onSelect={handleSelect}
      >
        <TabPanel>
          <LendTab />
        </TabPanel>
        <TabPanel>
          <BorrowTab />
        </TabPanel>
        <TabPanel>
          <FlashTab />
        </TabPanel>
      </Tabs>
    </PageLayout>
  );
};

export default LoansPage;
