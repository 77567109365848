import { useCallback, useState } from 'react';
import { isEqual } from 'lodash';

const useCallbackState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const handleChange = useCallback((newState: any) => !isEqual(state, newState) && setState(newState), [state]);

  const handleReset = () => setState(initialState);

  return { state, onChange: handleChange, onReset: handleReset };
};

export default useCallbackState;
