export enum flex {
  row = 'row',
  column = 'column',
  center = 'center',
  spaceAround = 'space-around',
  spaceBetween = 'space-between',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  wrap = 'wrap',
  noWrap = 'nowrap',
}

export default flex;
