import styled, { css } from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexColumnCenterMixin, FlexMixin, SofiaLargeMixin, SofiaLargestMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledStakePage = styled.div`
  margin-bottom: 10rem;

  a {
    ${SofiaLargeMixin};
  }
`;

export const StyledStakeBalance = styled.div<{ row?: boolean }>`
  width: 120%;

  ${FlexMixin({ direction: flex.column, alignItems: flex.flexEnd })};

  ${SofiaLargeMixin};
  line-height: 1.9rem;

  ${({ row }) =>
    row &&
    css`
      width: 170%;

      ${FlexMixin({ direction: flex.row })};

      margin-bottom: 2rem;

      span {
        margin-right: 2rem;
      }

      div {
        margin-right: 1rem;
        padding-right: 1rem;
        border-right: 0.1rem solid ${color.red};

        &:last-of-type {
          border: none;
        }
      }
    `};
`;

export const StyledPlaceholder = styled.div`
  ${FlexColumnCenterMixin};

  min-height: 22.8rem;
  height: 60vh !important;

  ${SofiaLargestMixin};
  text-align: center;

  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 1rem 2rem 5rem 2rem;
`;
