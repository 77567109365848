import React from 'react';
import { style } from 'styles/enums';
import { adaptLoanStatus } from './utils';
import { StyledLoanStatus } from './styles';

const LoanStatus = ({ status, offer_status }: { status: number; offer_status?: number }) => (
  <StyledLoanStatus className={style.italic}>{adaptLoanStatus(status, offer_status)}</StyledLoanStatus>
);

export default LoanStatus;
