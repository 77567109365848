import styled, { css } from 'styled-components';
import { color, flex } from 'styles/enums';
import {
  FlexColumnCenterMixin,
  FlexMixin,
  SofiaLargerMixin,
  SofiaMediumMixin,
  SofiaSmallMixin,
  TruncateMixin,
} from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledAsset = styled.div`
  position: relative;

  background-color: ${addOpacityToColor(color.white, 5)};

  margin-bottom: 2.4rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${addOpacityToColor(color.white, 20)};
  }

  > div {
    min-height: 22rem;
  }
`;

export const StyledImage = styled.div`
  min-height: 23.4rem;

  ${FlexMixin({
    direction: flex.column,
    justifyContent: flex.spaceBetween,
    alignItems: flex.center,
  })};

  padding: 2rem;

  img {
    width: 16rem;
  }

  a {
    margin-top: 1rem;
  }

  div {
    width: 16rem;
    height: 16rem;

    ${FlexColumnCenterMixin};

    background-color: ${addOpacityToColor(color.black, 45)};
  }
`;

export const StyledDetails = styled.div`
  ${SofiaMediumMixin};

  padding: 2rem 7rem 2rem 0;

  span {
    ${SofiaLargerMixin};
  }
`;

export const StyledInfo = styled.div<{ toggled: boolean }>`
  width: 110%;
  ${SofiaSmallMixin};
  word-break: break-all;

  cursor: pointer;

  ${({ toggled }) =>
    toggled &&
    css`
      ${TruncateMixin};
    `};
`;

export const StyledDescription = styled.div<{ toggled: boolean }>`
  ${SofiaSmallMixin};

  margin-top: 3rem;

  cursor: pointer;

  ${({ toggled }) =>
    toggled &&
    css`
      ${TruncateMixin};
    `};
`;

export const StyledChildren = styled.div<{ nfts: boolean }>`
  height: 100%;

  ${FlexMixin({
    direction: flex.column,
    justifyContent: flex.spaceBetween,
    alignItems: flex.flexEnd,
  })};

  padding: 2rem;

  ${({ nfts }) =>
    nfts &&
    css`
      justify-content: flex-end;
    `};
`;
