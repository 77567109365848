import styled, { css } from 'styled-components';
import { color, flex } from 'styles/enums';
import {
  FlexAlignItemsCenterMixin,
  FlexMixin,
  FlexRowCenterMixin,
  SofiaMediumMixin,
  SofiaSmallMixin,
} from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';
import LoanCost from 'features/loans/components/LoanCost';

export const StyledForm = styled.form`
  ${FlexRowCenterMixin};

  > div {
    width: calc(100% - 23rem);

    ${FlexMixin({
      justifyContent: flex.spaceBetween,
      alignItems: flex.center,
    })};
  }
`;

export const StyledError = styled.div<{ error?: boolean; length?: number | undefined }>`
  width: 17rem;

  position: absolute;
  top: -2rem;
  right: -2.2rem;

  color: ${color.lightRed};
  ${SofiaSmallMixin};

  ${({ error, length }) =>
    error &&
    css`
      top: ${length && length > 30 ? -4 : -2}rem;
    `};
`;

export const StyledField = styled.div<{ length?: number }>`
  height: 4rem;

  position: relative;

  ${FlexAlignItemsCenterMixin};

  margin-right: 2.4rem;

  label {
    min-width: 5rem;

    align-self: flex-start;

    ${SofiaMediumMixin};
    text-align: right;

    margin-right: 1.5rem;

    overflow-wrap: normal;
  }

  input {
    max-width: ${({ length }) => (length === 6 ? 8.3 : 7.4)}rem;
    height: 4rem;

    border: none;
    box-shadow: inset 0 0.1rem 0.3rem 0 ${addOpacityToColor(color.black, 50)};

    padding: 0.8rem;
  }
`;

export const StyledSelect = styled.select`
  width: 9rem;
  height: 4rem;

  color: ${color.white};
  text-align-last: center;

  background-color: ${color.black};
  border: none;
`;

export const StyledLoanCost = styled(LoanCost)`
  width: unset;

  position: absolute;
  bottom: -3rem;
  left: 9rem;

  text-align: right;
  padding: 0;
`;
