import React from 'react';
import { HyperLink } from 'common/components/bits';
import { StyledTermsTab } from './styles';

const TermsTab = () => (
  <StyledTermsTab>
    <ol type="I">
      <li>
        Introduction
        <p>
          Hoard A/S (“Hoard”, “we”, “our”, “us”) is an owner and operator of the website https://hoard.market/ (“Site“).
          The Site is an user interface (“App“) to the decentralized Ethereum protocols that allows users (“you”,
          “your”) to perform peer-to-peer transactions of blockchain based assets. This document (“Terms of Service”,
          “Terms”) explains the terms and conditions by which you may access and use the App. Please read those Terms
          carefully and ensure that you understand each part. Before accessing or using the App you must acknowledge
          that you understand and agree to be bound by all of these Terms.
        </p>
      </li>
      <li>
        Access and use of the App
        <p>
          To access and use the App you must be able to form a legally binding contract online either as an individual
          or on behalf of a legal entity. You represent that you are at least eighteen years old and have the full
          right, power, and authority to enter into and comply with the obligations under these Terms on behalf of
          yourself and/or any company or legal entity for which you may access or use the App.
        </p>
        <p>The App is provided on “as is” and “as available” basis.</p>
      </li>
      <li>
        Disclaimer of Warranty
        <p>
          To the extent permitted by applicable law we provide the App without warranty of any kind, either expressed or
          implied, including, but not limited to the warranties of merchantability and fitness for a particular purpose.
          We do not represent or warrant that: access and use of the App will meet your requirements; access to the App
          will be continuous, uninterrupted, timely, secure or free of viruses or other harmful components; that the
          information contained in the App will be accurate, reliable, complete, legal, or safe; or that the App will be
          free from errors, defects, viruses, or other harmful components.
        </p>
        <p>
          You understand that we have the right to limit the availability of the App to any person, geographic area or
          jurisdiction we so desire and/or to terminate your access to and use of the site, at any time and at our sole
          discretion.
        </p>
        <p>
          You understand that using Ethereum protocols you may be required to pay a fee, such as gas charges on the
          Ethereum network to perform a transaction. You acknowledge and agree that you are solely responsible for
          having a sufficient balance to complete any transaction on Ethereum protocols.
        </p>
        <p>
          You acknowledge and agree that we have no control over any transactions over Ethereum protocols, the method of
          payment of any transactions or any actual payments of transactions. You acknowledge and agree that you are
          solely responsible for having a sufficient balance of the applicable cryptocurrency tokens stored at your
          wallet address used to interact with the App to complete any transaction.
        </p>
        <p>
          You acknowledge and understand the fact that Hoard is not a guarantor or surety for peer-to-peer loans
          provided by participants through Site. Hoard shall in no case be liable for any events having a negative or
          positive effect on the repayment of the loans. Hoard takes no part in the disputes that may arise between
          borrowers and lenders.
        </p>
        <p>
          While using the App you agree to comply with all applicable domestic and international laws, statutes,
          ordinances and regulations applicable to your use of the site.
        </p>
        <p>You agree to only use the App for lawful purposes.</p>
        <p>You acknowledge and agree that you access and use the App at your own risk.</p>
      </li>
      <li>
        Modification of these Terms of Service
        <p>
          We may amend this document from time to time. Please visit this page regularly to keep abreast of updates. You
          agree to be bound by any changes or modifications to these Terms of Service. If you do not agree with any
          changes or modifications to these Terms, you must immediately stop accessing and using the App.
        </p>
      </li>
      <li>
        Intellectual property
        <p>
          We own all intellectual property rights in the App and its contents. You may not copy, modify, adapt, rent,
          license, sell, publish, distribute, or otherwise permit any third party to access or use these works. You are
          not permitted to use this App to promote unlawful behaviour such as offensive, fraudulent or obscene content
          or infringe the security or other users by i.eg. sending them viruses or harmful code. You will not create any
          robot, spider, crawler, scraper, or other automated means to access to use this App nor You will not introduce
          any malware, virus, Trojan horse, worm, logic bomb, drop-dead device, backdoor, shutdown mechanism or other
          harmful material into the App.
        </p>
      </li>
      <li>
        Privacy policy
        <p>
          You can learn more about our Privacy Policy{' '}
          <HyperLink href="https://hoard.exchange/privacy.html" text="here" />.
        </p>
        <p>
          When you use the App, we may collect information about your computer and your interaction with the Site. See
          our <HyperLink href="https://hoard.exchange/cookies.html" text="Cookie Policy" /> for more information.
        </p>
      </li>
      <li>
        Indemnification
        <p>
          You agree to release and to indemnify, defend and hold harmless Hoard A/S and its parents, subsidiaries,
          affiliates and agencies, as well as the officers, directors, employees, shareholders and representatives of
          any of the foregoing entities, from and against any and all losses, liabilities, expenses, damages, costs
          (including attorneys' fees and court costs) claims or actions of any kind whatsoever arising or resulting from
          your use of the App, your violation of these Terms and any of your acts or omissions that implicate publicity
          rights, defamation or invasion of privacy.
        </p>
        <p>
          You agree to release Hoard A/S and its parents, subsidiaries, affiliates and agencies, as well as the
          officers, directors, employees, shareholders and representatives of any of the foregoing entities, from and
          against any and all losses, liabilities, expenses, damages, costs (including attorneys' fees and court costs)
          claims or actions of any kind whatsoever arising or resulting from events arising from risks as indicated in
          VIII.
        </p>
      </li>
      <li>
        Assumption of Risks of Cryptographic Systems and Currencies
        <p>
          By using the App in any way, you acknowledge the inherent risks associated with cryptographic systems; and
          warrant that you have an understanding of the usage and intricacies cryptographic tokens, smart contract based
          tokens such as those that follow the Ethereum Token Standard and blockchain-based software systems.
        </p>
        <p>You are aware in particular of the following risks:</p>
        <ol type="a">
          <li>
            <strong>Risk of software weaknesses</strong>: The Smart Contract System concept, the underlying software
            application and software platform (i.e. the Ethereum blockchain) is still in an early development stage and
            unproven, why there is no warranty that the process for creating HRD will be uninterrupted or error-free and
            why there is an inherent risk that the software could contain weaknesses, vulnerabilities or bugs causing,
            inter alia, the complete loss of ETH, other (financial) support of the Hoard Project and/or the Smart
            Contract System and/or underlying protocols and/or any other software involved may either delay and/or not
            execute a Contribution due to the overall Contribution volume, mining attacks and/or similar events.
          </li>
          <li>
            <strong>Risk of cryptographic weaknesses</strong>: The blockchain and all software dependent thereon, such
            as the Hoard Project and HRD are based on the effectiveness and reliability of cryptographic solutions.
            However, cryptography is evolving and cannot guarantee absolute security at all times. Advances in
            cryptography, such as code cracking, or technical advances such as the development of quantum computers,
            could present risks to all cryptography-based systems including the Hoard Project and HRD. This could result
            in the theft, loss, disappearance, destruction or devaluation of the HRD held by the users.
          </li>
          <li>
            <strong>Regulatory risk</strong>: The blockchain technology allows new forms of interaction and that it is
            possible that certain jurisdictions will apply existing regulations on, or introduce new regulations
            addressing, blockchain technology based applications, which may be contrary to the current setup of the
            Smart Contract System and which may, inter alia, result in substantial modifications of the Smart Contract
            System and/or the Hoard Project, including its termination and the loss of HRD for the user.
          </li>
          <li>
            <strong>Risk of insufficient information</strong>: The Hoard Project is at a very early developmental stage
            and its philosophy, consensus mechanism, algorithm, code and other technical specifications and parameters
            could be updated and changed frequently and constantly. While the Whitepaper contains the up-to-date key
            information related to HRD at the date of the Whitepaper, it is not complete or final and is subject to
            adjustments and updates that Hoard may make from time to time. Hoard is not in a position, nor obliged, to
            keep You closely posted on every detail of the Hoard Project’s development (including its progress and
            expected milestones, whether rescheduled or not) and therefore will not necessarily provide the user with
            timely or full access to all the information relating to the Hoard Project that may emerge from time to
            time.
          </li>
          <li>
            <strong>Risk of abandonment / lack of success</strong>: The creation of the HRD and the development of the
            Hoard Project may be abandoned for a number of reasons, including lack of interest from the public, lack of
            funding, lack of commercial success or prospects (e.g. caused by competing projects). The HRD is not
            expected to be popular, prevalent or widely used soon after its launch. The HRD and the Hoard Project may
            remain marginalised in the long run, appealing to only a minimal portion of users (if any). Every user
            therefore understands that there is no assurance that, even if the Hoard Project is partially or fully
            developed and launched, the user will receive any benefits through the HRD held by him.
          </li>
          <li>
            <strong>Risks related to unverified source code</strong>: The source code of the Hoard Platform may be
            licensed under open-source license terms and any party related or unrelated to the Hoard Project can propose
            updates, amendments, alterations or modifications to the source code. Hoard may not be able to verify or
            guarantee the precise results of such updates, amendments, alterations or modifications and as a result, any
            update, amendment, alteration or modification could lead to an unexpected or unintended outcome that
            adversely affects the Hoard Project or the HRD.
          </li>
          <li>
            <strong>Risks related to competition</strong>: The source code of the Hoard Platform may be licensed under
            open-source license terms. As a result, anyone can copy, replicate, reproduce, engineer, modify, improve or
            otherwise utilize the source code and/or underlying source code of the Hoard Platform in an attempt to
            develop a competing protocol, software, system or virtual platform or virtual machine, which is out of
            Hoard’s control and may consequently compete with or even overshadow or overtake the Hoard Project, which
            may adversely affect the Hoard Project or the HRD.
          </li>
          <li>
            <strong>Risk of withdrawing partners</strong>: The feasibility of the Hoard Project depends strongly on the
            collaboration of game developers and other crucial partners of Hoard. There is therefore no assurance that
            the Hoard Project as a whole or parts thereof will be successfully executed as set out in these Terms or
            otherwise.
          </li>
          <li>
            <strong>Risk associated with other applications</strong>: The Hoard Project may give rise to other,
            alternative projects, promoted by unaffiliated third parties, under which HRD will have no intrinsic value.
          </li>
          <li>
            <strong>Risk of Loss of Private Key</strong>: HRD can only be accessed by using an Ethereum wallet with a
            combination of the user account information (address), private key, password and any other protection used
            by the user. If the user private key file, password or the back-up seed is lost or stolen, the HRD
            associated with the user account (address) or password may be unrecoverable and permanently lost.
          </li>
          <li>
            <strong>Third party risk</strong>: Hoard may use third-party financial intermediaries and/or other third
            parties to manage and operate the Crowd Contribution processes. Hoard has no visibility into, or possibility
            to control, the software or mechanisms used by such third parties, and cannot verify or guarantee the proper
            functionality of the third parties’ software or operations.
          </li>
          <li>
            <strong>Risk of theft</strong>: The Smart Contract System concept, the underlying software application and
            software platform (i.e. the Ethereum blockchain), or other assets of the Hoard Project, may be exposed to
            attacks by hackers or other individuals that could result in theft or loss of HRD, other (financial) support
            of the Hoard Project or ETH, which may lead to loss or devaluation of contributed funds and or HRD ant
            impacting the ability to develop the Hoard Project.
          </li>
          <li>
            <strong>Risk of Ethereum mining attacks and other malicious attacks</strong>: as with other
            cryptocurrencies, the blockchain used for the Smart Contract System is susceptible to mining and other
            malicious attacks, including but not limited to double-spend attacks, majority mining power attacks,
            “selfish-mining” attacks, and race condition attacks. Any successful attacks present a risk to the Smart
            Contract System, expected proper execution and sequencing of HRD transactions, and expected proper execution
            and sequencing of contract computations.
          </li>
          <li>
            <strong>Risk of changes in Smart Contract System</strong>: The network of miners (or, in the future,
            potentially a network of staking nodes) will be ultimately in control of the Smart Contract System. A
            majority of these miners could agree at any point to make changes to the Smart Contract System and to run
            the new version of the Smart Contract System, which could lead to reduced functionality of the Hoard
            Platform and/or devaluation of HRD.
          </li>
          <li>
            <strong>Risk of incompatible wallet service</strong>: The wallet or wallet service, has to be technically
            compatible with the HRD. The failure to assure this may have the result that user will not gain access to
            his HRD.
          </li>
          <li>
            <strong>Risk of depreciation</strong>: no market liquidity may be guaranteed and that the value of HRD over
            time may experience extreme volatility or depreciate in full.
          </li>
          <li>
            <strong>Risk of Force Majeure</strong>: Hoard no event shall be responsible or liable for any failure or
            delay in the performance of the Crowd Contribution, HRD, Smart Contract System, Hoard Project, Hoard
            Platform, third-party service providers, exchanges and / or financial intermediaries arising out of or
            caused by, directly or indirectly, forces beyond its control, including, without limitation, embargos,
            governmental restrictions, strikes, work stoppages, accidents, acts of war or terrorism, civil or military
            disturbances, nuclear or natural catastrophes or acts of God, and interruptions, loss or malfunctions of
            utilities, communications or computer (software and hardware) services.
          </li>
        </ol>
        <br />
        <p>
          You understand that Ethereum and other blockchain technologies and associated crypto-based assets are highly
          volatile due to many factors including but not limited to adoption, speculation, technology and security
          risks. You also acknowledge that the cost of transacting on such technologies is variable and may increase at
          any time causing impact to any activities taking place on the Ethereum blockchain.
        </p>
        <p>
          You acknowledge these risks and represent that we cannot be held liable for such fluctuations, increased costs
          or blockchain-based software security risks. You further acknowledge that any blockchain protocols you
          interact with are entirely your own responsibility and liability, and that we do not own or control any
          Ethereum protocols, and cannot be held liable for any resulting losses that you experience while accessing or
          using the App.
        </p>
      </li>
      <li>
        No fiduciary duties
        <p>
          These Terms are not intended to, and does not, create or impose any fiduciary duties on us. To the fullest
          extent permitted by law, you acknowledge and agree that we owe no fiduciary duties or liabilities to you or
          any other party, and that to the extent any such duties or liabilities may exist at law or in equity, those
          duties and liabilities are hereby irrevocably disclaimed, waived,and eliminated. You further agree that the
          only duties and obligations that we owe you are those set out expressly in these Terms.
        </p>
      </li>
      <li>
        Exclusion of interfaces and applications from USA
        <p>
          As part of the functionality of the Site, we exclude all staking services and functionality from the United
          States of America (does not apply to Site functionality other than staking). We will take active control
          measures to ensure that staking services functionality are free from the above. In particular, we may disable
          the account of a user who uses the above without the need to state reasons.
        </p>
      </li>
      <li>
        Exclusion of USA users
        <p>
          Staking services and functionalities are not dedicated to users from the United States of America. We will
          take active control measures to ensure that no user of staking (services of functionalities) is of U.S.
          origin. In particular, we may disable the account of a user who is of U.S. origin without the need to state
          reasons.
        </p>
      </li>
      <li>
        Governing Law
        <p>
          These Terms shall be governed by and will be interpreted in accordance with Danish law, excluding its
          conflicts of law rules. Any dispute arising between you and us shall be finally settled by the ordinary courts
          of the registered domicile of Hoard.
        </p>
      </li>
    </ol>
  </StyledTermsTab>
);

export default TermsTab;
