import styled from 'styled-components';
import copy from './images/copy.svg';

export const StyledButtonCopy = styled.span`
  width: 2rem;
  height: 2rem;

  position: absolute;
  top: 1.5rem;
  right: 2rem;

  background-image: url(${copy});
  background-repeat: no-repeat;
  background-position: 98% 38%;

  cursor: pointer;
`;
