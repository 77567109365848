import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FlashLoanProps } from 'features/loans/types';
import { FlashLoanOfferData, FlashTabState } from './types';
import { faqsTab, tutorial } from 'app/Router';
import { Pagination, queryBuild, Sorting, usePagination, useSorting } from 'common/components/assets';
import { Button, ButtonCopy, HyperLink, Loader, Modal, notify, Toast } from 'common/components/bits';
import { Filters, FlashLoanOffers, FlashOffer, useFilters } from './components';
import { useState } from 'common/hooks';
import dispatchRequest from 'common/utils/dispatchRequest';
import { parseToString } from 'common/utils/parsers';
import { renderShortAddress } from 'common/utils/presenters';
import { selectCurrencies } from 'app/App/slice';
import { flashDataFetchRequest, flashLoansFetchRequest, selectFlashLoans } from 'features/loans/slice';
import { options } from './flash';
import { StyledCode, StyledCol, StyledFlashTab, StyledPlaceholder, StyledRow } from './styles';

const FlashTab = () => {
  const currencies = useSelector(selectCurrencies);
  const { count, loans } = useSelector(selectFlashLoans);

  const filters = useFilters();
  const pagination = usePagination({ count });
  const sorting = useSorting({ defaultOption: options[4], options });

  const { state, onChange, onReset } = useState({ offer: undefined, data: undefined });

  const query = queryBuild({
    page: parseToString(pagination.page),
    ordering: sorting.state,
    ...filters.state,
  });

  const handleFetch = () =>
    dispatchRequest({
      request: flashLoansFetchRequest,
      values: { query },
    });

  useEffect(() => {
    handleFetch();
  }, [query]);

  useEffect(() => {
    state.offer &&
      dispatchRequest({
        request: flashDataFetchRequest,
        values: { id: state.offer.id },
        onSuccess: (data: FlashLoanOfferData) => onChange((state: FlashTabState) => ({ ...state, data })),
      });
  }, [state.offer]);

  const handleUseOffer = (offer: FlashLoanProps) => onChange((state: FlashTabState) => ({ ...state, offer }));

  const handleCopy = () => notify(<Toast message="Copied to clipboard!" />);

  const loanButton = {
    text: 'Use Offer',
    onClick: handleUseOffer,
    disabled: state.offer,
  };

  return (
    <>
      <Row nogutter>
        <StyledCol xs={2} height={filters.height}>
          {!!currencies.length && <Filters {...filters} />}
        </StyledCol>
        <Col xs={10}>
          <StyledFlashTab height={filters.height}>
            <FlashOffer onFetch={handleFetch} disabled={state.offer} />
            <StyledRow>
              {count > 0 && (
                <>
                  <Col xs={6}>{<Sorting {...sorting} />}</Col>
                  <Col xs={3} offset={{ xs: 3 }}>
                    <Pagination {...pagination} />
                  </Col>
                </>
              )}
            </StyledRow>
            {!!loans.length ? (
              <FlashLoanOffers offers={loans} {...loanButton} />
            ) : (
              <StyledPlaceholder height={filters.height}>
                Currently there are no flash loan offers.
                <br />
                <br />
                Please wait for others to list new offers or
                <br />
                change filters.
              </StyledPlaceholder>
            )}
          </StyledFlashTab>
        </Col>
      </Row>
      <Modal
        open={state.offer}
        onClose={onReset}
        footer={
          <div>
            <Button text="Close" onClick={onReset} outlined />
            <CopyToClipboard text={JSON.stringify(state.data, null, 4)} onCopy={handleCopy}>
              <Button text="Copy Offer's Data" />
            </CopyToClipboard>
          </div>
        }
      >
        To continue with the flash loan execution you will have to <br /> copy the offer's data and interact directly
        with the Hoard <br /> Marketplace{' '}
        <HyperLink
          href="https://etherscan.io/address/0xB8434f35f15808d45b8285Cd4D59cd94894D385d"
          text="smart contract"
        />
        . More details in <Link to={faqsTab({ tab: tutorial.tab })}>FAQ's</Link>.
        <StyledCode>
          {state.data && (
            <>
              {JSON.stringify(
                { ...state.data, signature: renderShortAddress(state.data['signature'], 20, 25) },
                null,
                4,
              )}
              <ButtonCopy text={JSON.stringify(state.data, null, 4)} />
            </>
          )}
          <Loader visible={!state.data} spinner />
        </StyledCode>
      </Modal>
    </>
  );
};

export default FlashTab;
