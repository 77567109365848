import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import appReducer from 'app/App/slice';
import accountsReducer from 'features/accounts/slice';
import loansReducer from 'features/loans/slice';

export const store = configureStore({
  reducer: combineReducers({
    app: appReducer,
    accounts: accountsReducer,
    loans: loansReducer,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
