import React from 'react';
import { Col, Row } from 'react-grid-system';
import { PageLayoutProps } from './types';
import { renderSize } from 'common/utils/presenters';
import { StyledContainer, StyledPageLayout } from './styles';

const PageLayout = ({ children, columns = { xs: 12 }, offset = { xs: 0 } }: PageLayoutProps) => (
  <StyledPageLayout>
    <StyledContainer>
      <Row>
        <Col xs={renderSize(columns)} offset={{ xs: renderSize(offset) }}>
          {children}
        </Col>
      </Row>
    </StyledContainer>
  </StyledPageLayout>
);

export default PageLayout;
