import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { requiredNumber } from 'common/utils/validation';
import { current } from './values';

const schema = ({ page_count }: { page_count: number }) =>
  object().shape({
    page: requiredNumber(current.label).integer().min(1).max(page_count),
  });

const options = ({ defaultValues, validate }: { defaultValues: object; validate: object }) => ({
  mode: 'onChange',
  // @ts-ignore
  resolver: yupResolver(schema(validate)),
  defaultValues,
});

export default options;
