import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Option, SortingProps } from './types';
import { current } from './values';
import options from './options';
import { StyledSorting } from './styles';

const Sorting = ({ ordering, onSorting }: SortingProps) => {
  const location = useLocation();
  const defaultValues = { ordering: ordering[4].value };
  // @ts-ignore
  const { register, handleSubmit, reset, watch } = useForm(options({ defaultValues }));
  // @ts-ignore
  const selected = watch(current.name);

  useEffect(() => {
    // @ts-ignore
    handleSubmit(() => onSorting(selected))();
  }, [selected]);

  useEffect(() => {
    reset();
  }, [location.pathname]);

  return (
    <StyledSorting>
      <form>
        <label>Sort by:</label>
        {/*// @ts-ignore*/}
        <select {...register(current.name)}>
          {ordering.map(({ id, label, value }: Option) => (
            <option key={id} value={value}>
              {label}
            </option>
          ))}
        </select>
      </form>
    </StyledSorting>
  );
};

export default Sorting;
