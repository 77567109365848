import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';
import { requiredNumber, requiredString } from 'common/utils/validation';
import { expirationValue, loanValue, repaymentValue } from './values';

const schema = ({
  currency_amount,
  min_currency_profit_value,
  minimum_return_amount,
}: {
  currency_amount: number;
  min_currency_profit_value: number;
  minimum_return_amount: number;
}) =>
  object().shape({
    currency_id: number(),
    expiration_unit: string(),
    expiration_value: requiredString(expirationValue.label),
    loan_value: requiredNumber(loanValue.label).max(currency_amount, 'Not enough currency'),
    repayment_value: requiredNumber(repaymentValue.label).min(
      minimum_return_amount,
      `${repaymentValue.label} must be greater by at least ${min_currency_profit_value * 100}% than ${loanValue.label}`,
    ),
  });

const options = ({ defaultValues, validate }: { defaultValues: object; validate: object }) => ({
  mode: 'onChange',
  // @ts-ignore
  resolver: yupResolver(schema(validate)),
  defaultValues,
});

export default options;
