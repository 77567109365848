import styled from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledFlashOffer = styled.div`
  height: 13rem;

  ${FlexMixin({
    justifyContent: flex.flexEnd,
    alignItems: flex.flexEnd,
  })};

  border-bottom: 0.1rem solid ${addOpacityToColor(color.white, 20)};

  padding-bottom: 4rem;
  margin-top: -2rem;
  margin-bottom: 2rem;

  button {
    margin: 0 0 0 auto;
  }

  > span {
    margin-bottom: 1rem;
  }
`;
