import styled from 'styled-components';
import { color } from 'styles/enums';
import { SofiaLargeMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledGlossaryTab = styled.div`
  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 3rem 5rem;

  p {
    margin: 0 0 4rem;

    strong {
      ${SofiaLargeMixin};
    }
  }
`;
