import React from 'react';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { ToastProps } from 'common/components/bits/Toast/types';
import { LoanOfferProps, ResultLoanOfferProps } from 'features/loans/types';
import { OwnedLoanFormData } from '../OwnedLoanForm/types';
import { OwnedLoanAssetProps, OwnedLoanAssetState } from './types';
import { nfts, path } from 'app/Router';
import { action } from 'features/loans/enums/action';
import { Asset } from 'common/components/assets';
import { Button, Loader, notify, Toast } from 'common/components/bits';
import { LoanDueTime, LoanMinimumValue, LoanStatus, OwnedLoanOffers } from 'features/loans/components';
import { AcceptedLoanOffer, OwnedLoanForm } from '../index';
import { ToggleRef, useBalance, useContract, useCurrency, useNetwork, useState, useToggle } from 'common/hooks';
import useOffer, { adaptOfferToMessage } from 'features/loans/hooks/useOffer';
import dispatchRequest from 'common/utils/dispatchRequest';
import { max } from 'common/utils/library';
import { parseFromDecimal, parseToDecimal } from 'common/utils/parsers';
import usePawnshop from 'contracts/pawnshop';
import { selectAddress } from 'app/App/slice';
import { selectAccount } from 'features/accounts/slice';
import { loanCreateRequest, loanOffersFetchRequest } from 'features/loans/slice';
import { adaptLoanOffers } from 'features/loans/adapters';
import { ABI } from './contract';
import { StyledLoan, StyledToggle } from './styles';

const OwnedLoanAsset = ({
  id,
  accepted_offer,
  asset,
  currency_id,
  minimum_value,
  loan_taken_timestamp,
  others_offers_count,
  status,
  onFetch,
  disabled,
}: OwnedLoanAssetProps) => {
  const account = useSelector(selectAccount);
  const address = useSelector(selectAddress);

  const handleBalance = useBalance();
  const handleContract = useContract({ ABI });
  const handleCurrency = useCurrency();
  const decimal = handleCurrency(currency_id).decimal;
  const handleOffer = useOffer({ asset, currency_id });
  const { library } = useNetwork();
  const pawnshop = usePawnshop();

  const ownedLoanForm = useToggle({ outsideClick: true });
  const ownedLoanRedeem = useToggle({});
  const ownedLoanOffers = useToggle({});

  const { state, onChange } = useState({ loader: false, offers: [] });

  const handleSubmit = ({ currency_id, ...data }: OwnedLoanFormData) => {
    const minimum_value = parseToDecimal(data.minimum_value, handleCurrency(currency_id).decimal);

    dispatchRequest({
      request: loanCreateRequest,
      values: { id, data: { action: action.pledge, minimum_value, currency_id } },
      onSuccess: onFetch,
    });
  };

  const handleSuccess = ({ results }: { results: ResultLoanOfferProps[] }) => {
    onChange({ loader: false, offers: adaptLoanOffers(results) });
    ownedLoanOffers.toggleClick();
  };

  const handleFetch = () =>
    dispatchRequest({
      request: loanOffersFetchRequest,
      values: { id, query: { page_size: '50' } },
      onSuccess: handleSuccess,
    });

  const handleError = (error: ToastProps) => {
    notify(<Toast {...error} />);
    disabled.onReset();
  };

  const handleTransaction = async ({
    receipt: { blockNumber, status },
    callback,
    message,
    redirect,
  }: {
    receipt: { blockNumber: number; status: boolean };
    callback: () => void;
    message: string;
    redirect?: { path: string; text: string };
  }) => {
    let currentBlockNumber;

    while (!currentBlockNumber || !(blockNumber + 2 <= currentBlockNumber)) {
      currentBlockNumber = await library.eth.getBlockNumber();

      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    if (status) {
      notify(<Toast message={message} redirect={redirect} />);
      callback();
    }
  };

  const handleSend = ({
    transaction,
    callback,
    message,
    redirect,
  }: {
    transaction: any;
    callback: () => void;
    message: string;
    redirect?: { path: string; text: string };
  }) => {
    disabled.onChange(id);

    transaction
      .send({ from: account.address })
      .then((receipt: { blockNumber: number; status: boolean }) =>
        handleTransaction({ receipt, callback, message, redirect }),
      )
      .catch(handleError);
  };

  const handleCallback = () => {
    onFetch();
    disabled.onReset();
  };

  const handleRedeemCallback = () => {
    handleCallback();
    handleBalance();
  };

  const handleAcceptCallback = () => {
    handleCallback();
    onChange((state: OwnedLoanAssetState) => ({ ...state, loader: false }));
  };

  const handleWithdraw = () => {
    disabled.onChange(id);

    handleSend({
      transaction: pawnshop.withdrawItem(asset.contract.address, asset.token_id),
      callback: handleCallback,
      message: 'Withdraw succeeded',
      redirect: { path: path.nfts, text: nfts.goTo },
    });
  };

  const handleRedeem = () =>
    handleSend({
      transaction: pawnshop.redeemItem(accepted_offer.signature_hash),
      callback: handleRedeemCallback,
      message: 'Item repaid',
    });

  const handleRedeemAndWithdraw = () =>
    handleSend({
      transaction: pawnshop.redeemAndWithdrawItem(accepted_offer.signature_hash),
      callback: handleRedeemCallback,
      message: 'Item repaid and withdrawn',
    });

  const handleAllowance = (callback: () => void) =>
    handleContract(handleCurrency(currency_id).address)
      .methods.approve(address.pawnshop, max)
      .send({ from: account.address })
      .then(() => {
        notify(<Toast message={handleCurrency(currency_id).name + ' allowance increased to maximum'} />);
        callback();
      })
      .catch(handleError);

  const handleConfirm = (callback: () => void) => {
    disabled.onChange(id);

    handleContract(handleCurrency(currency_id).address)
      .methods.allowance(account.address, address.pawnshop)
      .call()
      .then((result: string) => {
        const allowance = parseFromDecimal(result, decimal);

        return parseFloat(allowance) < parseFloat(accepted_offer.loan_value) ? handleAllowance(callback) : callback();
      })
      .catch(handleError);
  };

  const handleAccept = async ({ lender_address, signature, ...data }: LoanOfferProps) => {
    onChange((state: OwnedLoanAssetState) => ({ ...state, loader: true }));
    // @ts-ignore
    const offer = (await handleOffer)({ data, nonce: data.nonce });

    handleSend({
      transaction: pawnshop.takeLoan(lender_address, signature, adaptOfferToMessage(offer)),
      callback: handleAcceptCallback,
      message: 'Offer accepted',
    });
  };

  const loanButton = {
    text: 'Accept Offer',
    onClick: handleAccept,
    disabled: !!disabled.id,
  };

  const no_offers = others_offers_count === 0;

  return (
    <>
      <Asset
        ref={ownedLoanForm.toggleRef}
        asset={asset}
        disabled={!!disabled.id && disabled.id === id}
        spinner={!state.loader}
      >
        <LoanStatus status={status} offer_status={accepted_offer && accepted_offer.status} />
        {account.address && (
          <StyledLoan accepted_offer={!isNull(accepted_offer)}>
            <LoanMinimumValue
              currency_id={currency_id}
              minimum_value={minimum_value}
              accepted_offer={!isNull(accepted_offer)}
            />
            {accepted_offer && <AcceptedLoanOffer currency_id={currency_id} offer={accepted_offer} />}
            {status === 2 && (
              <LoanDueTime accepted_offer={accepted_offer} loan_taken_timestamp={loan_taken_timestamp} />
            )}
            {status !== 2 && !accepted_offer && (
              <Button text="Withdraw Item" onClick={handleWithdraw} disabled={!!disabled.id} ghost />
            )}
            {status === 0 && (
              <ToggleRef ref={ownedLoanForm.toggleRef}>
                {ownedLoanForm.toggleOpen ? (
                  <OwnedLoanForm onSubmit={handleSubmit} disabled={!!disabled.id} />
                ) : (
                  <Button text="List Collateral" onClick={ownedLoanForm.toggleClick} disabled={!!disabled.id} />
                )}
              </ToggleRef>
            )}
            {status === 2 && (
              <StyledToggle>
                <div onClick={ownedLoanRedeem.toggleClick} />
                <Button
                  text={!ownedLoanRedeem.toggleOpen ? 'Repay' : 'Repay & Withdraw'}
                  onClick={() => handleConfirm(!ownedLoanRedeem.toggleOpen ? handleRedeem : handleRedeemAndWithdraw)}
                />
              </StyledToggle>
            )}
            {status === 1 && others_offers_count > 0 && (
              <Button
                text={`Check Offers (${others_offers_count})`}
                onClick={handleFetch}
                disabled={!!disabled.id || no_offers || ownedLoanOffers.toggleOpen}
                outlined
              />
            )}
          </StyledLoan>
        )}
      </Asset>
      {status === 1 && ownedLoanOffers.toggleOpen && (
        <ToggleRef ref={ownedLoanOffers.toggleRef}>
          <Loader visible={state.loader} spinner />
          <OwnedLoanOffers
            currency_id={currency_id}
            offers={state.offers}
            {...loanButton}
            onClose={ownedLoanOffers.toggleClick}
          />
        </ToggleRef>
      )}
    </>
  );
};

export default OwnedLoanAsset;
