import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Balance } from 'common/hooks/useBalance/types';
import { Currency } from 'common/hooks/useCurrency/types';
import { FlashOfferFormProps, FlashOfferFormState } from './types';
import { durationUnit } from 'features/loans/hooks/utils/duration';
import { confirm, currencyId, expirationUnit, expirationValue, loanValue, repaymentValue } from './values';
import { Button, type } from 'common/components/bits';
import { InputNumber } from 'common/components/form';
import { useCurrency, useNetwork, useState } from 'common/hooks';
import { parseDecimalPlaces, parseFromDecimal, parseToDecimal } from 'common/utils/parsers';
import usePawnshop from 'contracts/pawnshop';
import { selectAddress, selectCurrencies, selectPawnshop } from 'app/App/slice';
import { selectAccountBalance } from 'features/accounts/slice';
import options from './options';
import { StyledError, StyledField, StyledForm, StyledLoanCost, StyledSelect } from './styles';

const FlashOfferForm = ({ disabled, onSubmit }: FlashOfferFormProps) => {
  const address = useSelector(selectAddress);
  const balance = useSelector(selectAccountBalance);
  const currencies = useSelector(selectCurrencies);
  const {
    platform: { min_lender_profit },
  } = useSelector(selectPawnshop);
  // @ts-ignore

  const handleCurrency = useCurrency();
  const { testnet } = useNetwork();
  const pawnshop = usePawnshop();

  const { state, onChange } = useState({
    currency_amount: '0.00',
    min_currency_profit_value: 0,
    minimum_return_amount: undefined,
  });

  const defaultValues = {
    // @ts-ignore
    currency_id: currencies[0].id,
    expiration_unit: durationUnit.hours,
    repayment_value: state.minimum_return_amount,
  };
  const validate = {
    currency_amount: parseFloat(state.currency_amount),
    min_currency_profit_value: state.min_currency_profit_value,
    minimum_return_amount: parseFloat(state.minimum_return_amount),
  };

  const {
    control,
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors, ...formState },
    watch,
    ...methods
    // @ts-ignore
  } = useForm(options({ defaultValues, validate }));

  const currency_id = watch(currencyId.name);
  const loan_value = watch(loanValue.name);
  const repayment_value = watch(repaymentValue.name);

  useEffect(() => {
    const currency = balance.find(({ id }: Balance) => id === currency_id) || { amount: '0.00' };

    onChange((state: FlashOfferFormState) => ({ ...state, currency_amount: currency.amount }));
  }, [currency_id]);

  useEffect(() => {
    const min_currency_profit = min_lender_profit.find(({ id }) => id === currency_id);

    if (min_currency_profit) {
      // @ts-ignore
      onChange((state: FlashOfferFormState) => ({ ...state, min_currency_profit_value: min_currency_profit.value }));
    }
  }, [currency_id]);

  useEffect(() => {
    !!loan_value &&
      !!address.pawnshop.length &&
      pawnshop
        .minReturnAmount(
          handleCurrency(currency_id).address,
          parseToDecimal(loan_value, handleCurrency(currency_id).decimal),
        )
        .call()
        .then((result: string) => {
          const minimum_return_amount = parseDecimalPlaces(
            parseFromDecimal(result, handleCurrency(currency_id).decimal, 6),
          );

          onChange((state: FlashOfferFormState) => ({ ...state, minimum_return_amount }));

          setValue(repaymentValue.name, minimum_return_amount);
          trigger(loanValue.name);
          trigger(repaymentValue.name);
        });
  }, [address.pawnshop, loan_value, currency_id, state.min_currency_profit_value]);

  return (
    <FormProvider
      control={control}
      register={register}
      trigger={trigger}
      handleSubmit={handleSubmit}
      setValue={setValue}
      formState={{ errors, ...formState }}
      watch={watch}
      {...methods}
    >
      <StyledForm
        // @ts-ignore
        onSubmit={handleSubmit((data) => onSubmit({ ...data, loan_value, repayment_value }))}
      >
        <div>
          <StyledField>
            {errors[loanValue.name] && (
              <StyledError
                error={errors[loanValue.name]}
                length={errors[loanValue.name] && errors[loanValue.name].message.length}
              >
                {errors[loanValue.name].message}
              </StyledError>
            )}
            <InputNumber {...loanValue} />
            <StyledSelect {...register(currencyId.name, { valueAsNumber: true })}>
              {currencies.map(({ id, name }: Currency) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </StyledSelect>
          </StyledField>
          <StyledField length={6}>
            {errors[repaymentValue.name] && (
              <StyledError
                error={errors[repaymentValue.name]}
                length={errors[repaymentValue.name] && errors[repaymentValue.name].message.length}
                style={{ width: '21rem', right: '-5.2rem' }}
              >
                {errors[repaymentValue.name].message}
              </StyledError>
            )}
            <InputNumber {...repaymentValue} decimalPlaces={6} />
            {!errors[repaymentValue.name] && (
              <StyledLoanCost loan_value={loan_value} repayment_value={repayment_value} />
            )}
          </StyledField>
          <StyledField>
            {errors[expirationValue.name] && (
              <StyledError>{errors[expirationValue.name].message.split(' ').slice(0, -1).join(' ')}</StyledError>
            )}
            <InputNumber {...expirationValue} decimalPlaces={0} />
            <StyledSelect {...register(expirationUnit.name)} disabled={disabled}>
              {testnet && <option value={durationUnit.minutes}>{durationUnit.minutes}</option>}
              <option value={durationUnit.hours}>{durationUnit.hours}</option>
              <option value={durationUnit.days}>{durationUnit.days}</option>
            </StyledSelect>
          </StyledField>
        </div>
        <Button type={type.submit} text={confirm.label} disabled={disabled} short />
      </StyledForm>
    </FormProvider>
  );
};

export default FlashOfferForm;
