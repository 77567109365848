import { FlashLoanOffer } from './types';
import { parseToString } from 'common/utils/parsers';

export const adaptFlashOfferToMessage = (offer: FlashLoanOffer) => ({
  nonce: parseToString(offer.nonce),
  expirationTime: parseToString(offer.expirationTime),
  loanTokenAddress: offer.loanTokenAddress,
  loanAmount: offer.loanAmount,
  returnAmount: offer.returnAmount,
});
