import React from 'react';
import { upperFirst } from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { borrow, flash, lend, stake } from 'app/Router';
import { style } from 'styles/enums';
import { ButtonCopy, HyperLink } from 'common/components/bits';
import {
  acceptOffer,
  borrowTab,
  checkOffers,
  checkOffersTab,
  claim,
  claimAction,
  confirmCollateral,
  confirmFlashOffer,
  confirmOffer,
  confirmStake,
  confirmUnstake,
  copyOffersData,
  deposit,
  flashTab,
  lendTab,
  listCollateral,
  listCollateralsTab,
  login,
  makeOffer,
  makeOffersTab,
  minimalOffer,
  nfts,
  offerFlashLoan,
  repay,
  repayLoansTab,
  stakeAction,
  stakeAll,
  stakeTab,
  unstakeAction,
  unstakeAll,
  useFlashOffer,
  viewFinishedTab,
} from './images';
import { abi, flashBorrower, flashLoanExecute, flashLoanFunction, flashLoanFunctionImplementation } from './samples';
import { StyledCode, StyledHyperLink, StyledImage, StyledTutorialTab } from './styles';

const TutorialTab = () => {
  const renderGoToPageTab = (tab: string, page?: string) => (
    <li>
      Go to <strong>{page || lend.path}</strong> page
      {!!tab?.length && (
        <>
          {' '}
          and then <strong>{upperFirst(tab)}</strong> tab
        </>
      )}
      .
    </li>
  );

  const renderVisitTab = (tab: string) => (
    <li>
      Visit <strong>{tab}</strong> tab or search the list (<strong>View All</strong>).
    </li>
  );

  const renderFollowInstructions = () => <li>Follow your MetaMask wallet instructions.</li>;

  const renderClickButtonAction = (button: string, action: string, withdraw?: boolean) => (
    <li>
      Click{' '}
      {!withdraw ? (
        <strong>{button}</strong>
      ) : (
        <>
          <strong>{button}</strong> or switch to <strong>{button} and Withdraw</strong>
        </>
      )}{' '}
      next to the item you {!!action.length ? `want to ${action}` : 'choose'}.
    </li>
  );

  return (
    <StyledTutorialTab>
      <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={[]}>
        <AccordionItem uuid="login">
          <AccordionItemHeading>
            <AccordionItemButton>How to log in?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ol>
              <li>
                Click <strong>Log In</strong> in the upper right corner of the screen and follow your MetaMask wallet
                instructions to connect.
              </li>
              <span className={style.italic}>
                NOTE: If you do not have MetaMask installed on your browser click <strong>Install MetaMask</strong> in
                the upper right corner.
              </span>
              <StyledImage>
                <img src={login} alt="login" />
              </StyledImage>
              {renderFollowInstructions()}
            </ol>
            <p>You are logged in now. You should see your account balance in the upper right corner of the screen.</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="get_loan">
          <AccordionItemHeading>
            <AccordionItemButton>How to get a loan?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <p>
                Loan is a peer-to-peer lending platform. Borrowers request a loan by listing their NFTs as collateral
                and Lenders make offers charging interests.
              </p>
              <p>If you own a NFT and would like to obtain a loan follow the steps below.</p>
            </div>
            <Accordion className="accordion__ghost" allowMultipleExpanded allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Deposit NFT</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Before you request a loan you have to deposit your NFT to the Marketplace smart contract.
                    <br />
                    Follow the instructions below.
                  </p>
                  <ol>
                    <li>
                      Click <strong>NFTs</strong> to view your NFT assets.
                    </li>
                    <StyledImage>
                      <img src={nfts} alt="nfts" />
                    </StyledImage>
                    {renderClickButtonAction('Deposit', 'use as collateral')}
                    <StyledImage>
                      <img src={deposit} alt="deposit" />
                    </StyledImage>
                    {renderFollowInstructions()}
                  </ol>
                  <p>
                    Your item is now deposited and ready to be used as collateral.
                    <br />
                    View your item in <strong>Loan &gt; Borrower &gt; List Collaterals</strong> tab.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Request a Loan</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {renderGoToPageTab(borrow.tab)}
                    <StyledImage>
                      <img src={borrowTab} alt="borrow tab" />
                    </StyledImage>
                    {renderVisitTab('List Collaterals')}
                    <StyledImage>
                      <img src={listCollateralsTab} alt="list collaterals tab" />
                    </StyledImage>
                    {renderClickButtonAction('List Collateral', 'list as collateral')}
                    <StyledImage>
                      <img src={listCollateral} alt="list collateral" />
                    </StyledImage>
                    <li>Input a minimal offer and currency of a loan you want to request.</li>
                    <span className={style.italic}>NOTE: Loan value will be reduced by platform fee.</span>
                    <StyledImage>
                      <img src={minimalOffer} alt="minimal offer" />
                    </StyledImage>
                    <li>
                      To request loan click <strong>Confirm Collateral</strong>.
                    </li>
                    <StyledImage>
                      <img src={confirmCollateral} alt="confirm collateral" />
                    </StyledImage>
                  </ol>
                  <p>
                    Your loan request is now visible to Lenders and awaits offers.
                    <br />
                    Once a lender makes you a loan offer you will be able to accept it following below steps.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Accept a Loan Offer</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {renderGoToPageTab(borrow.tab)}
                    <StyledImage>
                      <img src={borrowTab} alt="borrow tab" />
                    </StyledImage>
                    {renderVisitTab('Check Offers')}
                    <StyledImage>
                      <img src={checkOffersTab} alt="check offers tab" />
                    </StyledImage>
                    {renderClickButtonAction('Check Offer', 'view')}
                    <StyledImage>
                      <img src={checkOffers} alt="check offers" />
                    </StyledImage>
                    {renderClickButtonAction('Accept Offer', 'accept')}
                    <span className={style.italic}>
                      NOTE: You will receive a loan value reduced by the platform fee.
                    </span>
                    <StyledImage>
                      <img src={acceptOffer} alt="accept offer" />
                    </StyledImage>
                    {renderFollowInstructions()}
                  </ol>
                  <p>
                    You received funds into your account and your loan is in progress.
                    <br />
                    The loan value was reduced by the platform fee.
                    <br />
                    View your loan in <strong>Loan &gt; Borrower &gt; Repay Loans</strong> tab.
                  </p>
                  <span className={style.italic}>
                    <span className={style.warning}>WARNING:</span> Remember to repay your loan on time! Once the Loan
                    Duration expires your NFT will be transferred to a lender.
                  </span>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Repay Loan</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {renderGoToPageTab(borrow.tab)}
                    <StyledImage>
                      <img src={borrowTab} alt="borrow tab" />
                    </StyledImage>
                    {renderVisitTab('Repay Loans')}
                    <StyledImage>
                      <img src={repayLoansTab} alt="repay loans tab" />
                    </StyledImage>
                    {renderClickButtonAction('Repay', 'repay', true)}
                    <StyledImage>
                      <img src={repay} alt="repay" />
                    </StyledImage>
                    {renderFollowInstructions()}
                  </ol>
                  <p>
                    Your loan is repaid and finished.
                    <br />
                    The repayment value was deducted from your account.
                    <br />
                    <br />
                    If you clicked <strong>Repay</strong> your item is visible in{' '}
                    <strong>Loan &gt; Borrower &gt; List Collaterals</strong> and is ready to be used as collateral
                    again.
                    <br />
                    If you clicked <strong>Repay and Withdraw</strong> your item is visible in <strong>NFTs</strong> tab
                    and can be deposited again to be used as collateral.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="give_loan">
          <AccordionItemHeading>
            <AccordionItemButton>How to give a loan?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <p>
                Loan is a peer-to-peer lending platform. Borrowers request a loan by listing their NFTs as collateral
                and Lenders make offers charging interests.
              </p>
              <p>
                If you own ERC20 tokens and would like to make profit by offering a loan follow the instructions below.
              </p>
            </div>
            <Accordion className="accordion__ghost" allowMultipleExpanded allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Offer a loan</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {renderGoToPageTab(lend.tab)}
                    <StyledImage>
                      <img src={lendTab} alt="lend tab" />
                    </StyledImage>
                    {renderVisitTab('Make Offers')}
                    <StyledImage>
                      <img src={makeOffersTab} alt="make offers tab" />
                    </StyledImage>
                    {renderClickButtonAction('Make Offer', '')}
                    <StyledImage>
                      <img src={makeOffer} alt="make offer" />
                    </StyledImage>
                    <li>Input:</li>
                    <ul>
                      <li>Repayment value (this a total value you want to be repaid)</li>
                      <li>Loan duration</li>
                      <li>Offer due in - how long will the offer be valid for</li>
                    </ul>
                    <li>
                      Click <strong>Confirm Offer</strong> to submit.
                    </li>
                    <StyledImage>
                      <img src={confirmOffer} alt="confirm offer" />
                    </StyledImage>
                  </ol>
                  <p>
                    Your offer is now visible to the Borrower and awaits acceptance.
                    <br />
                    Once the Borrower accepts your offer the loan value will automatically be deducted from your
                    account.
                    <br />
                    View your loan in <strong>Loan &gt; Lend &gt; Loans in Progress</strong> tab.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Claim item</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    This action will be possible if Borrower does not repay the loan on time and the ownership of the
                    NFT used as collateral is transferred to you.
                  </p>
                  <ol>
                    {renderGoToPageTab(lend.tab)}
                    <StyledImage>
                      <img src={lendTab} alt="lend tab" />
                    </StyledImage>
                    {renderVisitTab('View Finished')}
                    <StyledImage>
                      <img src={viewFinishedTab} alt="view finished tab" />
                    </StyledImage>
                    {renderClickButtonAction('Claim', 'claim', true)}
                    <StyledImage>
                      <img src={claim} alt="claim" />
                    </StyledImage>
                    {renderFollowInstructions()}
                  </ol>
                  <p>
                    If you clicked <strong>Claim Item</strong> your item is visible in{' '}
                    <strong>Loan &gt; Borrower &gt; List Collaterals</strong> and is ready to be used as collateral.
                    <br />
                    If you clicked <strong>Claim and Withdraw</strong> your item is visible in <strong>NFTs</strong> tab
                    and can be deposited again to be used as collateral.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="give_flash_loan">
          <AccordionItemHeading>
            <AccordionItemButton>How to give a flash loan?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ol>
              {renderGoToPageTab(flash.tab)}
              <StyledImage>
                <img src={flashTab} alt="flash tab" />
              </StyledImage>
              <li>
                Click <strong>Offer Flash Loan</strong>
              </li>
              <StyledImage>
                <img src={offerFlashLoan} alt="offer flash loan" />
              </StyledImage>
              <li>Input:</li>
              <ul>
                <li>Loan value</li>
                <li>Repayment value (this a total value you want to be repaid)</li>
                <li>Offer due in - how long will the offer be valid for</li>
              </ul>
              <li>
                Click <strong>Confirm Offer</strong> to submit.
              </li>
              <StyledImage>
                <img src={confirmFlashOffer} alt="confirm flash loan" />
              </StyledImage>
              {renderFollowInstructions()}
            </ol>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="get_flash_loan">
          <AccordionItemHeading>
            <AccordionItemButton>How to get a flash loan?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ol>
              {renderGoToPageTab(flash.tab)}
              <StyledImage>
                <img src={flashTab} alt="flash tab" />
              </StyledImage>
              {renderClickButtonAction('Use Offer', '')}
              <StyledImage>
                <img src={useFlashOffer} alt="use flash offer" />
              </StyledImage>
              <li>
                Read the message and click <strong>Copy Offer’s Data</strong> to continue.
                <StyledImage>
                  <img src={copyOffersData} alt="copy offer's data" />
                </StyledImage>
                <p>
                  Offer’s data was copied to the clipboard and is ready to be used to execute the flash loan.
                  <br />
                  Copied data: lender address, a signature, a nonce, an expiration time, an ERC20 token address
                  (currency),
                  <br />
                  a loan amount, a return amount.
                  <br />
                  <br />
                  <span className={style.italic}>
                    NOTE: Each flash loan offer can only be used once before it expires.
                  </span>
                  <br />
                  <br />
                  To execute the flash loan you will have to interact directly with the Hoard Marketplace smart
                  contract:{' '}
                  <StyledHyperLink
                    href="https://etherscan.io/address/0xB8434f35f15808d45b8285Cd4D59cd94894D385d"
                    text="0xB8434f35f15808d45b8285Cd4D59cd94894D385d"
                    address
                  />
                  <br />
                  <br />
                  See required ABI below:
                </p>
                <StyledCode>
                  {abi}
                  <ButtonCopy text={abi} />
                </StyledCode>
                <p>
                  Where <span className={style.italic}>IERC3156FlashBorrower</span> is defined as:
                </p>
                <StyledCode>
                  {flashBorrower}
                  <ButtonCopy text={flashBorrower} />
                </StyledCode>
              </li>
              <li>
                Create a receiving contract.
                <p>
                  You need to provide a contract that will be called upon executing a flash loan.
                  <br />
                  The contract must implement the <span className={style.italic}>IERC3156FlashBorrower</span> interface
                  which declares the function:
                </p>
                <StyledCode>
                  {flashLoanFunction}
                  <ButtonCopy text={flashLoanFunctionImplementation} />
                </StyledCode>
                <p>
                  <span className={style.italic}>
                    NOTE: Please remark that it is the receiving contract that repays the loan and it has to own enough
                    amount at the end of the call.
                  </span>
                  <br />
                  <br />
                  The receiving contract MUST approve <span className={style.warning}>loanAmount + fee</span> of the
                  ERC20 <span className={style.warning}>loanTokenAddress</span> for the pawnshop contract.
                  <br />
                  This value contains the return amount and the platform fee.
                  <br />
                  The <span className={style.warning}>sender</span> is the caller of{' '}
                  <span className={style.warning}>flashLoan()</span> on the pawnshop contract.
                  <br />
                  The <span className={style.warning}>data</span> is case specific and is simply passed from the caller
                  to the receiving contract.
                  <br />
                  <br />
                  Here is a sample implementation:
                </p>
                <StyledCode>
                  {flashLoanFunctionImplementation}
                  <ButtonCopy text={flashLoanFunctionImplementation} />
                </StyledCode>
              </li>
              <li>
                Execute flash loan.
                <p>
                  Before you start you may want to check if the lender has approved{' '}
                  <span className={style.warning}>loanAmount</span> of the ERC20{' '}
                  <span className={style.warning}>loanTokenAddress</span> for the pawnshop contract and has the enough
                  balance.
                  <br />
                  <br />
                  Here is a JavaScript example of how to execute a flash loan using{' '}
                  <span className={style.italic}>ethers.js</span> library.
                </p>
                <StyledCode>
                  {flashLoanExecute}
                  <ButtonCopy text={flashLoanExecute} />
                </StyledCode>
              </li>
            </ol>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="stake">
          <AccordionItemHeading>
            <AccordionItemButton>How to stake (your ETHHRD UNIV2 tokens)?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <p>
                HRD token holders can stake HRD derivative tokens (ETHHRD UNIV2) into a Marketplace and will receive
                rewards proportional to their staking contributions.
              </p>
              <span className={style.italic}>
                NOTE: To obtain ETHHRD UNIV2 - you have to add liquidity to the{' '}
                <HyperLink
                  href="https://app.uniswap.org/#/add/v2/ETH/0xC617D51E3a1f621dA8aE67b2f652d6aC02Eb8D95"
                  text="ETH/HRD pool on Uniswap"
                />
                .
              </span>
            </div>
            <Accordion className="accordion__ghost" allowMultipleExpanded allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Stake</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {renderGoToPageTab('', stake.name)}
                    <StyledImage>
                      <img src={stakeTab} alt="stake tab" />
                    </StyledImage>
                    <li>
                      Click <strong>{stake.name}</strong>.
                    </li>
                    <StyledImage>
                      <img src={stakeAction} alt="stake action" />
                    </StyledImage>
                    <li>
                      Input a tokens amount you want to stake or click <strong>All</strong> to have all your ETHHRD
                      UNIV2 tokens staked.
                    </li>
                    <StyledImage>
                      <img src={stakeAll} alt="stake all" />
                    </StyledImage>
                    <li>
                      Click <strong>Confirm</strong> to submit.
                    </li>
                    <StyledImage>
                      <img src={confirmStake} alt="confirm stake" />
                    </StyledImage>
                    {renderFollowInstructions()}
                  </ol>
                  <p>
                    Your staking tokens are now staked.
                    <br />
                    Once transactions take place and fees are paid on the Marketplace you will receive your rewards.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Unstake</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {renderGoToPageTab('', stake.name)}
                    <StyledImage>
                      <img src={stakeTab} alt="stake tab" />
                    </StyledImage>
                    <li>
                      Click <strong>Unstake</strong>.
                    </li>
                    <StyledImage>
                      <img src={unstakeAction} alt="unstake action" />
                    </StyledImage>
                    <li>
                      Input a tokens amount you want to withdraw or click <strong>All</strong> to have all your ETHHRD
                      UNIV2 tokens withdrawn.
                    </li>
                    <StyledImage>
                      <img src={unstakeAll} alt="unstake all" />
                    </StyledImage>
                    <li>
                      Click <strong>Confirm</strong> to submit.
                    </li>
                    <StyledImage>
                      <img src={confirmUnstake} alt="confirm unstake" />
                    </StyledImage>
                    {renderFollowInstructions()}
                  </ol>
                  <p>Your staking tokens have been returned (view balance your in Stake tokens tab).</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Claim rewards</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {renderGoToPageTab('', stake.name)}
                    <StyledImage>
                      <img src={stakeTab} alt="stake tab" />
                    </StyledImage>
                    <li>
                      Click <strong>Claim</strong>.
                    </li>
                    <StyledImage>
                      <img src={claimAction} alt="claim action" />
                    </StyledImage>
                    {renderFollowInstructions()}
                  </ol>
                  <p>
                    All your rewards were sent to your Metamask wallet (view balance in the upper right corner of the
                    screen).
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </StyledTutorialTab>
  );
};

export default TutorialTab;
