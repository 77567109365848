import React from 'react';
import { useSelector } from 'react-redux';
import { LoanOfferProps } from 'features/loans/types';
import { OwnedLoanOffersProps } from './types';
import { fromUnixTime } from 'date-fns';
import { PlatformFee } from 'common/components/assets';
import { Button, ButtonClose, HyperLink, Tooltip, tooltip } from 'common/components/bits';
import { LoanCost } from 'features/loans/components';
import { adaptLoanOfferStatus } from 'features/loans/components/BorrowTab/components';
import { useCurrency, useNetwork } from 'common/hooks';
import { renderDuration } from 'features/loans/hooks/utils/duration';
import { parseDecimalPlaces, parseFromDecimal, parseTimesDecimal } from 'common/utils/parsers';
import { renderDate, renderShortAddress } from 'common/utils/presenters';
import { selectPawnshop } from 'app/App/slice';
import { selectAccount } from 'features/accounts/slice';
import { StyledCol, StyledOwnedLoanOffers, StyledOwnedLoanOffersHeader, StyledStatus } from './styles';

const OwnedLoanOffers = ({ currency_id, offers, text, onClick, disabled, onClose }: OwnedLoanOffersProps) => {
  const account = useSelector(selectAccount);
  const {
    platform: { deposit_fee },
  } = useSelector(selectPawnshop);
  const currency_deposit_fee = deposit_fee.find(({ id }) => id === currency_id) || { value: 0 };

  const handleCurrency = useCurrency();
  const decimal = handleCurrency(currency_id).decimal;
  const { testnet } = useNetwork();

  return (
    <div style={{ marginBottom: '3rem' }}>
      <StyledOwnedLoanOffersHeader>
        <span>User</span>
        <span>Offer due</span>
        <span>Loan value</span>
        <span>Repayment value</span>
        <span>Loan duration</span>
        <ButtonClose onClose={onClose} />
      </StyledOwnedLoanOffersHeader>
      <StyledOwnedLoanOffers>
        {offers.map((offer: LoanOfferProps) => {
          const loan_value = parseFromDecimal(offer.loan_value, decimal);
          const loan_value_platform_fee = parseTimesDecimal(
            offer.loan_value,
            decimal,
            currency_deposit_fee.value,
            false,
          );
          const repayment_value = parseDecimalPlaces(parseFromDecimal(offer.repayment_value, decimal, 6));

          const tooltipDepositFee = {
            [tooltip.for]: `${offer.id}_owned_deposit`,
            [tooltip.tip]: `Reduced by ${currency_deposit_fee.value * 100}% platform fee`,
          };

          const address_href = `https://${testnet ? 'rinkeby.' : ''}etherscan.io/address/${offer.lender_address}`;

          return (
            <div key={offer.id}>
              <HyperLink href={address_href} text={renderShortAddress(offer.lender_address)} address />
              <span>{offer.status === 0 ? renderDate(fromUnixTime(offer.expiration_time)) : '---'}</span>
              <StyledCol>
                <span>
                  {loan_value} {handleCurrency(currency_id).name}
                </span>
                {currency_deposit_fee.value > 0 && account.address !== offer.lender_address && (
                  <>
                    <PlatformFee {...tooltipDepositFee}>
                      {loan_value_platform_fee} {handleCurrency(currency_id).name}
                    </PlatformFee>
                    <Tooltip id={tooltipDepositFee[tooltip.for]} />
                  </>
                )}
              </StyledCol>
              <StyledCol>
                <span>
                  {repayment_value} {handleCurrency(currency_id).name}
                </span>
                <LoanCost loan_value={loan_value} repayment_value={repayment_value} />
              </StyledCol>
              <span>{renderDuration(offer.timelock_period)}</span>
              <div>
                {offer.status === 0 ? (
                  <Button text={text} onClick={() => onClick(offer)} disabled={disabled} small />
                ) : (
                  <StyledStatus>{adaptLoanOfferStatus(offer.status)}</StyledStatus>
                )}
              </div>
            </div>
          );
        })}
      </StyledOwnedLoanOffers>
    </div>
  );
};

export default OwnedLoanOffers;
