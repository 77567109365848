import { Response } from './types';

const onRejected = (response: Response) => {
  switch (response.statusCode) {
    case 400:
    case 401:
      return { ok: response.ok, body: response.body };
    default:
      console.log(response);
      return {};
  }
};

export default onRejected;
