import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { color } from 'styles/enums';
import { FlexRowCenterMixin, SofiaLargeMixin, SofiaRegularMixin } from 'styles/mixins';

export const StyledNavigation = styled.nav`
  ${FlexRowCenterMixin};
`;

export const StyledNavLink = styled(NavLink)`
  color: ${color.white};
  ${SofiaLargeMixin};
  text-decoration: none;

  border-bottom: 0.2rem solid transparent;

  padding: 0.8rem 1.2rem !important;

  &:hover {
    color: ${color.white};
  }

  &.selected {
    border-bottom-color: ${color.red};
  }

  @media (max-width: 1139px) {
    ${SofiaRegularMixin};
  }
`;
