import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { StakeFormProps } from './types';
import { confirm, stakedAmount } from './values';
import { Button, type } from 'common/components/bits';
import { InputNumber } from 'common/components/form';
import { parseToString } from 'common/utils/parsers';
import options from './options';
import { StyledButton, StyledError, StyledField, StyledForm } from './styles';

const StakeForm = ({ stake_token, disabled, onSubmit }: StakeFormProps) => {
  const defaultValues = {};
  const validate = { stake_token };
  const {
    handleSubmit,
    formState: { errors, ...formState },
    setValue,
    watch,
    ...methods
    // @ts-ignore
  } = useForm(options({ defaultValues, validate }));

  const staked_amount = watch(stakedAmount.name);

  const handleMaximum = () => setValue(stakedAmount.name, parseToString(stake_token), { shouldValidate: true });

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      formState={{ errors, ...formState }}
      setValue={setValue}
      watch={watch}
      {...methods}
    >
      <StyledForm onSubmit={handleSubmit(() => onSubmit({ staked_amount }))}>
        <StyledField>
          {errors[stakedAmount.name] && <StyledError>{errors[stakedAmount.name].message}</StyledError>}
          <InputNumber {...stakedAmount} label="" />
          <StyledButton
            text="All"
            onClick={handleMaximum}
            disabled={disabled || parseFloat(parseToString(stake_token)) === 0}
            ghost
          />
        </StyledField>
        <Button type={type.submit} text={confirm.label} disabled={disabled} short />
      </StyledForm>
    </FormProvider>
  );
};

export default StakeForm;
