export enum expirationUnit {
  name = 'expiration_unit',
}

export enum expirationValue {
  name = 'expiration_value',
  label = 'Offer due in',
}

export enum loanValue {
  name = 'loan_value',
  label = 'Loan value',
  message = 'Not enough currency',
}

export enum repaymentValue {
  name = 'repayment_value',
  label = 'Repayment value',
}

export enum timelockUnit {
  name = 'timelock_unit',
}

export enum timelockValue {
  name = 'timelock_value',
  label = 'Loan duration',
  message = 'Loan duration should be shorter or equal than',
}

export enum confirm {
  label = 'Confirm Offer',
}
