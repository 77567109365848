import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { requiredNumber, requiredString } from 'common/utils/validation';
import { minimumValue } from 'features/loans/components/BorrowTab/components/OwnedLoanForm/values';
import { expirationValue, loanValue, repaymentValue, timelockValue } from './values';

const schema = ({
  currency_amount,
  minimum_value,
  minimum_profit,
  min_currency_profit_value,
}: {
  currency_amount: number;
  minimum_value: number;
  minimum_profit: number;
  min_currency_profit_value: number;
}) =>
  object().shape({
    expiration_unit: string(),
    expiration_value: requiredString(expirationValue.label),
    loan_value: requiredNumber(loanValue.label)
      .min(minimum_value, `${loanValue.label} must be greater or equal to ${minimumValue.label}`)
      .max(currency_amount, 'Not enough currency'),
    repayment_value: requiredNumber(repaymentValue.label).min(
      minimum_profit,
      `${repaymentValue.label} must be greater by at least ${min_currency_profit_value * 100}% than ${
        minimumValue.label
      }`,
    ),
    timelock_unit: string(),
    timelock_value: requiredString(timelockValue.label),
  });

const options = ({ defaultValues, validate }: { defaultValues: object; validate: object }) => ({
  mode: 'onChange',
  // @ts-ignore
  resolver: yupResolver(schema(validate)),
  defaultValues,
});

export default options;
