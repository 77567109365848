import request from 'superagent';
import Cookies from 'universal-cookie';
import { httpRequestProps } from './types';
import onFulfilled from './onFulfilled';
import onRejected from './onRejected';

const url = ({ web3, path = '', id, subpath }: Partial<httpRequestProps>) =>
  `/api/${web3 ? 'web3' : 'v1'}/${path.includes('?') ? path : `${path}/`}${subpath ? `${id}/${subpath}` : id}`;

const httpRequest = ({ web3 = false, method, path, id = '', subpath, query = '', data = {} }: httpRequestProps) =>
  request(method.toUpperCase(), url({ web3, path, id, subpath }))
    .query(query)
    .set({ 'X-CSRFToken': new Cookies().get('csrftoken') })
    .send(data)
    .then(onFulfilled)
    .catch(onRejected);

export default httpRequest;
