import styled from 'styled-components';
import { color } from 'styles/enums';
import { FlexColumnCenterMixin, SofiaLargestMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledTradePage = styled.div`
  ${FlexColumnCenterMixin};

  min-height: 22.8rem;
  height: 60vh !important;

  ${SofiaLargestMixin};
  text-align: center;

  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 1rem 2rem 5rem 2rem;
`;
