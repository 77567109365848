export enum path {
  dashboard = '/',
  faqs = '/faqs',
  faqsTab = '/faqs/:tab',
  loans = '/loans',
  loansTab = '/loans/:tab',
  stake = '/stake',
  trade = '/trade',
  nfts = '/nfts',
}

export enum lend {
  id = 0,
  name = 'Lend',
  tab = 'lend',
  path = 'Loan',
}

export enum borrow {
  id = 1,
  name = 'Borrow',
  tab = 'borrow',
  path = 'Loan',
}

export enum flash {
  id = 2,
  name = 'Flash Loan',
  tab = 'flash',
  path = 'Loan',
}

export enum stake {
  name = 'Stake',
}

export enum trade {
  name = 'Trade',
}

export enum nfts {
  name = 'NFTs',
  goTo = 'Go to NFTs',
}

export enum faqs {
  name = 'FAQs',
}

export enum tutorial {
  id = 0,
  name = 'Tutorial',
  tab = 'tutorial',
}

export enum glossary {
  id = 1,
  name = 'Glossary',
  tab = 'glossary',
}

export enum terms {
  id = 2,
  name = 'Terms of service',
  tab = 'terms-of-service',
}

export enum support {
  id = 3,
  name = 'Support',
  tab = 'support',
}
