import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { assetMultiContracts } from '../values';
import { parseToString } from 'common/utils/parsers';
import { selectContracts } from 'app/App/slice';
import { StyledContracts, StyledField, StyledInput, StyledTitle } from './styles';

const Contracts = () => {
  const contracts = useSelector(selectContracts);

  const { register, watch } = useFormContext();

  const values = watch(assetMultiContracts.name);

  return !!contracts.length ? (
    <StyledContracts>
      <StyledTitle>Contracts</StyledTitle>
      {contracts.map(({ id, name }: { id: number; name: string }) => (
        <StyledField key={id}>
          <StyledInput
            type="checkbox"
            {...register(assetMultiContracts.name)}
            value={id}
            checked={values.includes(parseToString(id))}
          />
          <span>{name}</span>
        </StyledField>
      ))}
    </StyledContracts>
  ) : null;
};

export default Contracts;
