import React from 'react';
import { Link } from 'react-router-dom';
import { ToastProps } from './types';
import { HyperLink } from '../index';
import { useNetwork } from 'common/hooks';
import { error } from './utils';
import { StyledToast } from './styles';

const Toast = ({ code, receipt, message, redirect, warning }: ToastProps) => {
  const { testnet } = useNetwork();

  const href = `https://${testnet ? 'rinkeby.' : ''}etherscan.io/tx/${receipt?.transactionHash}`;

  return (
    <StyledToast error={code || receipt} warning={warning}>
      {code || receipt ? error(code) : !!warning?.length ? warning : message || 'Transaction succeeded'}
      {receipt && <HyperLink href={href} text="Go to transaction details" />}
      {redirect && <Link to={redirect.path}>{redirect.text}</Link>}
    </StyledToast>
  );
};

export default Toast;
