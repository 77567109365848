import { ReactNode } from 'react';
import { toast } from 'react-toastify';

export const error = (code?: number) => {
  switch (code) {
    case 1:
      return 'Do you have multiple wallets installed?';
    case 2:
      return 'NFT not yet supported';
    case 4001:
      return 'Transaction cancelled';
    case -32002:
      return 'Proceed with MetaMask login';
    case -32602:
      return 'Switch MetaMask to the address you are logged in with';
    default:
      return 'Something went wrong';
  }
};

const notify = (content: string | ReactNode, top: boolean = true) =>
  // @ts-ignore
  toast.dark(content, { position: `${top ? 'top' : 'bottom'}-right` });

export default notify;
