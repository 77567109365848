import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import { store } from 'app/store';
import App from 'app/App';
import getLibrary from 'common/utils/library';

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Provider store={store}>
      <App />
    </Provider>
  </Web3ReactProvider>,
  document.getElementById('app'),
);
