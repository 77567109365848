import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { floor } from 'lodash';
import { Balance } from 'common/hooks/useBalance/types';
import { useBalance } from 'common/hooks';
import { selectCurrencies } from 'app/App/slice';
import { selectAccount, selectAccountBalance } from 'features/accounts/slice';
import { StyledAccountBalance } from './styles';

const AccountBalance = () => {
  const account = useSelector(selectAccount);
  const balance = useSelector(selectAccountBalance);
  const currencies = useSelector(selectCurrencies);

  const handleBalance = useBalance();

  useEffect(() => {
    !!currencies.length && account.address && handleBalance();
  }, [account, currencies]);

  return (
    <>
      {balance.map(({ id, name, amount }: Balance) => (
        <StyledAccountBalance key={id}>
          {floor(parseFloat(amount), 2)} {name}
        </StyledAccountBalance>
      ))}
    </>
  );
};

export default AccountBalance;
