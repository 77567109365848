import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoansProps } from './types';
import { method } from 'common/enums/method';
import { state } from 'common/enums/state';
import { api } from './enums/api';
import { subpath } from './enums/subpath';
import asyncRequest from 'common/utils/http';
import { accountLogoutRequest } from 'features/accounts/slice';
import { adaptFlashLoans, adaptLoans } from './adapters';

export const lendLoansFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsLoans,
});

export const borrowLoansFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsLoansOwned,
});

export const loanOffersFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsLoans,
  subpath: subpath.offers,
});

export const loanCreateRequest = asyncRequest({
  method: method.post,
  path: api.assetsLoans,
});

export const loanOfferCreateRequest = asyncRequest({
  method: method.post,
  path: api.assetsLoans,
  subpath: subpath.offers,
});

export const flashLoansFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsFlash,
});

export const flashDataFetchRequest = asyncRequest({
  method: method.get,
  path: api.assetsFlashData,
});

export const flashLoanCreateRequest = asyncRequest({
  method: method.post,
  path: api.assetsFlash,
});

const initialState: LoansProps = {
  lend: {
    count: 0,
    loans: [],
  },
  borrow: {
    count: 0,
    loans: [],
  },
  flash: {
    count: 0,
    loans: [],
  },
};

export const slice = createSlice({
  name: state.loans,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(lendLoansFetchRequest.fulfilled, (state, { payload: { count, results } }) => ({
      ...state,
      lend: {
        count,
        loans: adaptLoans(results),
      },
    }));
    // @ts-ignore
    builder.addCase(borrowLoansFetchRequest.fulfilled, (state, { payload: { count, results } }) => ({
      ...state,
      borrow: {
        count,
        loans: adaptLoans(results),
      },
    }));
    // @ts-ignore
    builder.addCase(flashLoansFetchRequest.fulfilled, (state, { payload: { count, results } }) => ({
      ...state,
      flash: {
        count,
        loans: adaptFlashLoans(results),
      },
    }));
    builder.addCase(accountLogoutRequest.fulfilled, () => initialState);
  },
});

export const selectLendLoans = (state: RootState) => state.loans.lend;
export const selectBorrowLoans = (state: RootState) => state.loans.borrow;
export const selectFlashLoans = (state: RootState) => state.loans.flash;

export default slice.reducer;
