import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UnstakeFormProps } from './types';
import { confirm, unstakedAmount } from './values';
import { Button, type } from 'common/components/bits';
import { InputNumber } from 'common/components/form';
import { parseToString } from 'common/utils/parsers';
import options from './options';
import { StyledButton, StyledError, StyledField, StyledForm } from './styles';

const UnstakeForm = ({ staked_amount, disabled, onSubmit }: UnstakeFormProps) => {
  const defaultValues = {};
  const validate = { staked_amount };
  const {
    handleSubmit,
    formState: { errors, ...formState },
    setValue,
    watch,
    ...methods
    // @ts-ignore
  } = useForm(options({ defaultValues, validate }));

  const unstaked_amount = watch(unstakedAmount.name);

  const handleMaximum = () => setValue(unstakedAmount.name, parseToString(staked_amount), { shouldValidate: true });

  // @ts-ignore
  return (
    <FormProvider
      handleSubmit={handleSubmit}
      formState={{ errors, ...formState }}
      setValue={setValue}
      watch={watch}
      {...methods}
    >
      <StyledForm onSubmit={handleSubmit(() => onSubmit({ unstaked_amount }))}>
        <StyledField>
          {errors[unstakedAmount.name] && <StyledError>{errors[unstakedAmount.name].message}</StyledError>}
          <InputNumber {...unstakedAmount} label="" />
          <StyledButton
            text="All"
            onClick={handleMaximum}
            disabled={disabled || parseFloat(parseToString(staked_amount)) === 0}
            ghost
          />
        </StyledField>
        <Button type={type.submit} text={confirm.label} disabled={disabled} short />
      </StyledForm>
    </FormProvider>
  );
};

export default UnstakeForm;
