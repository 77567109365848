import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import { ceil } from 'lodash';
import { AssetProps } from 'common/components/assets/Asset/types';
import { ToastProps } from 'common/components/bits/Toast/types';
import { WalletState } from './types';
import { borrow, loansTab } from 'app/Router';
import { style } from 'styles/enums';
import {
  Asset,
  Filters,
  Pagination,
  queryBuild,
  Search,
  useFilters,
  usePagination,
  useSearch,
} from 'common/components/assets';
import { Button, Modal, notify, Toast } from 'common/components/bits';
import { useContract, useNetwork, useState } from 'common/hooks';
import { PageLayout } from 'common/components/layouts';
import dispatchRequest from 'common/utils/dispatchRequest';
import { parseToString } from 'common/utils/parsers';
import usePawnshop from 'contracts/pawnshop';
import { selectCategories, selectContracts } from 'app/App/slice';
import { accountAssetsFetchRequest, selectAccount, selectAccountAssetsCount } from 'features/accounts/slice';
import { ABI } from './contract';
import { StyledCol, StyledPlaceholder, StyledRow, StyledWalletPage } from './styles';

const WalletPage = () => {
  const account = useSelector(selectAccount);
  const accountAssetsCount = useSelector(selectAccountAssetsCount);
  const categories = useSelector(selectCategories);
  const contracts = useSelector(selectContracts);

  const filters = useFilters();
  const pagination = usePagination({ count: accountAssetsCount ? ceil(accountAssetsCount / 6) : 1 });
  const search = useSearch();

  const handleContract = useContract({ ABI });
  const { library } = useNetwork();
  const pawnshop = usePawnshop();

  const cryptoKittiesContracts = ['0x06012c8cf97BEaD5deAe237070F9587f8E7A266d'];

  const { state: accountAssets, onChange: onChangeAssets, onReset: onResetAssets } = useState([]);

  const { state, onChange, onReset } = useState({ asset: undefined, modal: false });

  const query = queryBuild({
    page: parseToString(pagination.page),
    search: search.state,
    ...filters.state,
  });

  const handleFetch = () =>
    dispatchRequest({
      request: accountAssetsFetchRequest,
      values: { query },
      onSuccess: ({ results }: { results: [] }) => onChangeAssets(results),
    });

  useEffect(() => {
    account.is_authenticated && !!contracts.length && handleFetch();
  }, [account, contracts, query]);

  useEffect(() => {
    return () => {
      onResetAssets();
      onReset();
    };
  }, [account]);

  const handleClose = () => onChange((state: WalletState) => ({ ...state, modal: false }));

  const handleTransaction = async ({
    receipt: { blockNumber, status },
    callback,
    message,
    redirect,
  }: {
    receipt: { blockNumber: number; status: boolean };
    callback: () => void;
    message: string;
    redirect?: { path: string; text: string };
  }) => {
    let currentBlockNumber;

    while (!currentBlockNumber || !(blockNumber + 2 <= currentBlockNumber)) {
      currentBlockNumber = await library.eth.getBlockNumber();

      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    if (status) {
      notify(<Toast message={message} redirect={redirect} />);
      callback();
    }
  };

  const handleSend = ({
    transaction,
    callback,
    message,
    redirect,
  }: {
    transaction: any;
    callback: () => void;
    message: string;
    redirect?: { path: string; text: string };
  }) => {
    handleClose();

    transaction
      .send({ from: account.address })
      .then((receipt: { blockNumber: number; status: boolean }) =>
        handleTransaction({ receipt, callback, message, redirect }),
      )
      .catch((error: ToastProps) => {
        notify(<Toast {...error} />);
        onReset();
      });
  };

  const handleDeposit = (asset: AssetProps) =>
    handleSend({
      transaction: pawnshop.depositItem(asset.contract.address, asset.token_id),
      callback: () => {
        handleFetch();
        onReset();
      },
      message: 'Deposit succeeded',
      redirect: { path: loansTab({ tab: borrow.tab }), text: 'Go to Borrow' },
    });

  const handleApproveSingle = async (asset: AssetProps) => {
    const item_handler = await pawnshop.itemHandler(asset.contract.address).call();

    handleSend({
      transaction: handleContract(asset.contract.address).methods.approve(item_handler, asset.token_id),
      callback: () => handleDeposit(asset),
      message: 'NFT approved',
    });
  };

  const handleApproveAll = async (asset: AssetProps) => {
    const item_handler = await pawnshop.itemHandler(asset.contract.address).call();

    handleSend({
      transaction: handleContract(asset.contract.address).methods.setApprovalForAll(item_handler, true),
      callback: () => handleDeposit(asset),
      message: 'NFTs approved',
    });
  };

  const handleCryptoKittyContract = (asset: AssetProps, item_handler: string) =>
    handleContract(asset.contract.address)
      .methods.kittyIndexToApproved(asset.token_id)
      .call()
      .then((result: string) => (result === item_handler ? handleDeposit(asset) : handleApproveSingle(asset)));

  const handleApproval = async (asset: AssetProps) => {
    const item_supported = await pawnshop.isItemTokenSupported(asset.contract.address).call();

    if (!item_supported) return notify(<Toast code={2} />);

    const item_handler = await pawnshop.itemHandler(asset.contract.address).call();

    onChange((state: WalletState) => ({ ...state, asset }));

    cryptoKittiesContracts.includes(asset.contract.address)
      ? handleCryptoKittyContract(asset, item_handler)
      : handleContract(asset.contract.address)
          .methods.isApprovedForAll(account.address, item_handler)
          .call()
          .then((approved: boolean) =>
            approved ? handleDeposit(asset) : onChange((state: WalletState) => ({ ...state, modal: true })),
          );
  };

  return (
    <PageLayout>
      <Row nogutter>
        <StyledCol xs={2} height={filters.height}>
          <Search {...search} />
          {(!!categories.length || !!contracts.length) && <Filters {...filters} />}
        </StyledCol>
        <Col xs={10}>
          <StyledWalletPage height={filters.height}>
            {!!accountAssets.length ? (
              <>
                <StyledRow>
                  <Col xs={3} offset={{ xs: 9 }}>
                    {accountAssetsCount && accountAssetsCount > 0 && <Pagination {...pagination} />}
                  </Col>
                </StyledRow>
                {accountAssets.map((asset: AssetProps) => (
                  <Asset
                    key={asset.id}
                    asset={asset}
                    disabled={!state.modal && state.asset && state.asset.id === asset.id}
                  >
                    <Button text="Deposit" onClick={() => handleApproval(asset)} disabled={state.asset} short />
                  </Asset>
                ))}
              </>
            ) : (
              <StyledPlaceholder height={filters.height}>Transfer more NFTs to your MetaMask wallet.</StyledPlaceholder>
            )}
          </StyledWalletPage>
        </Col>
      </Row>
      <Modal
        open={state.modal}
        onClose={onReset}
        footer={
          <div>
            <Button text="Approve All" onClick={() => handleApproveAll(state.asset)} short />
            <Button text="Approve Single" onClick={() => handleApproveSingle(state.asset)} short />
          </div>
        }
      >
        Do you want all your <span className={style.warning}>{state.asset ? state.asset.contract.name : ''}</span> NFTs{' '}
        <br /> to be automatically approved by Hoard Marketplace?
        <br />
        <br />
        <span className={style.italic}>NOTE: Approve does not mean Deposit.</span>
        <br />
        <span>You still need to deposit selected assets.</span>
      </Modal>
    </PageLayout>
  );
};

export default WalletPage;
