import { css } from 'styled-components';

export const SofiaSmallMixin = css`
  font-size: 1.3rem;
  line-height: 2rem;
`;

export const SofiaMediumMixin = css`
  font-size: 1.4rem;
  line-height: 2.1rem;
`;

export const SofiaRegularMixin = css`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const SofiaLargeMixin = css`
  font-size: 1.9rem;
  line-height: 2.6rem;
`;

export const SofiaLargerMixin = css`
  font-size: 2.1rem;
  line-height: 2.8rem;
`;

export const SofiaLargestMixin = css`
  font-size: 2.8rem;
  line-height: 4rem;
`;
