import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Balance } from './types';
import { Currency } from 'common/hooks/useCurrency/types';
import { useContract, useState } from 'common/hooks/index';
import { orderById, parseDecimalPlaces, parseFromDecimal } from 'common/utils/parsers';
import { selectCurrencies } from 'app/App/slice';
import { selectAccount, setBalance } from 'features/accounts/slice';
import { ABI } from './contract';

const useBalance = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const currencies = useSelector(selectCurrencies);

  const handleContract = useContract({ ABI });

  const { state, onChange } = useState({ balance: [] });

  useEffect(() => {
    !!state.balance.length &&
      state.balance.length === currencies.length &&
      dispatch(setBalance(orderById(state.balance)));
  }, [state, currencies]);

  return () =>
    currencies.forEach((currency: Currency) => {
      handleContract(currency.address)
        .methods.balanceOf(account.address)
        .call()
        .then((result: string) => {
          const amount = parseDecimalPlaces(parseFromDecimal(result, currency.decimal, currency.decimal));

          onChange((state: { balance: Balance[] }) => ({
            ...state,
            balance: [...state.balance, { id: currency.id, name: currency.name, amount }],
          }));
        });
    });
};

export default useBalance;
