import styled from 'styled-components';
import { color } from 'styles/enums';
import { FlexColumnCenterMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';
import spinner from './images/spinner.svg';

export const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  ${FlexColumnCenterMixin};

  background-color: ${addOpacityToColor(color.black, 45)};
`;

export const StyledSpinner = styled.div`
  width: 10rem;
  height: 10rem;

  background-image: url(${spinner});
`;
