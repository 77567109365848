import styled from 'styled-components';
import { color } from 'styles/enums';
import { SofiaSmallMixin } from 'styles/mixins';

export const StyledAccountBalance = styled.span`
  ${SofiaSmallMixin};
  line-height: 1.3rem;

  border-right: 0.1rem solid ${color.red};

  padding: 0.2rem 0.5rem 0;
`;
