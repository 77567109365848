import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Currency } from 'common/hooks/useCurrency/types';
import { faqsTab, history, lend, loansTab, path, tutorial } from '../Router';
import { Background, Footer, Header, Placeholder, Title } from './components';
import { useNetwork, useState } from 'common/hooks';
import usePawnshop from 'contracts/pawnshop';
import FaqsPage from 'features/faq/pages/FaqsPage';
import LoansPage from 'features/loans/pages/LoansPage';
import StakePage from 'features/stake/pages/StakePage';
import TradePage from 'features/trade/pages/TradePage';
import WalletPage from 'features/accounts/pages/WalletPage';
import dispatchRequest from 'common/utils/dispatchRequest';
import { orderById } from 'common/utils/parsers';
import {
  addressFetchRequest,
  contractsFetchRequest,
  currenciesFetchRequest,
  domainFetchRequest,
  selectAddress,
  selectCurrencies,
  setMaxTimelockPeriod,
  setPlatform,
} from 'app/App/slice';
import { selectAccount } from 'features/accounts/slice';
import GlobalStyle from 'styles/global';

const App = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const address = useSelector(selectAddress);
  const currencies = useSelector(selectCurrencies);

  const { blocked, library } = useNetwork();
  const pawnshop = usePawnshop();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    dispatchRequest({ request: addressFetchRequest });
    dispatchRequest({ request: contractsFetchRequest, values: { query: { page_size: '50' } } });
    dispatchRequest({ request: currenciesFetchRequest });
    dispatchRequest({ request: domainFetchRequest });
  }, []);

  const { state, onChange } = useState({ min_lender_profit: [], deposit_fee: [], redemption_fee: [], flash_fee: [] });

  useEffect(() => {
    !blocked &&
      !!currencies.length &&
      state.min_lender_profit.length === currencies.length &&
      state.deposit_fee.length === currencies.length &&
      state.redemption_fee.length === currencies.length &&
      state.flash_fee.length === currencies.length &&
      dispatch(
        setPlatform({
          min_lender_profit: orderById(state.min_lender_profit),
          deposit_fee: orderById(state.deposit_fee),
          redemption_fee: orderById(state.redemption_fee),
          flash_fee: orderById(state.flash_fee),
        }),
      );
  }, [account, state, currencies, blocked]);

  const adaptFraction = (result: { numerator: string; denominator: string }) =>
    parseInt(result.numerator) / parseInt(result.denominator);

  const adaptFee = (id: number, result: string[]) => ({
    id,
    value: adaptFraction({ numerator: result[0], denominator: result[1] }),
  });

  useEffect(() => {
    !blocked &&
      !!address.pawnshop.length &&
      currencies.forEach((currency: Currency) => {
        Promise.all([
          pawnshop.minLenderProfit(currency.address).call(),
          pawnshop.depositFee(currency.address).call(),
          pawnshop.redemptionFee(currency.address).call(),
          pawnshop.flashFee(currency.address).call(),
        ]).then((result: string[][]) => {
          onChange((state: { min_lender_profit: []; deposit_fee: []; redemption_fee: []; flash_fee: [] }) => ({
            ...state,
            min_lender_profit: [...state.min_lender_profit, adaptFee(currency.id, result[0])],
            deposit_fee: [...state.deposit_fee, adaptFee(currency.id, result[1])],
            redemption_fee: [...state.redemption_fee, adaptFee(currency.id, result[2])],
            flash_fee: [...state.flash_fee, adaptFee(currency.id, result[3])],
          }));
        });
      });
  }, [address.pawnshop, currencies, blocked]);

  useEffect(() => {
    !blocked &&
      !!address.pawnshop.length &&
      pawnshop
        .maxTimelockPeriod()
        .call()
        .then((result: string) => dispatch(setMaxTimelockPeriod(parseInt(result))));
  }, [address.pawnshop, blocked]);

  return (
    <Router history={history}>
      <GlobalStyle />
      <Title />
      <Header />
      <ToastContainer hideProgressBar />
      <Background />
      <Footer />
      <Switch>
        <Route exact path={path.dashboard}>
          <Redirect to={loansTab({ tab: lend.tab })} />
        </Route>
        <Route exact path={path.faqs}>
          <Redirect to={faqsTab({ tab: tutorial.tab })} />
        </Route>
        <Route exact path={path.faqsTab} component={FaqsPage} />
        <Route exact path={path.loans}>
          <Redirect to={loansTab({ tab: lend.tab })} />
        </Route>
        <Route path={path.loansTab} component={!library || !blocked ? LoansPage : Placeholder} />
        <Route exact path={path.stake} component={!library || !blocked ? StakePage : Placeholder} />
        <Route exact path={path.trade} component={!library || !blocked ? TradePage : Placeholder} />
        <Route exact path={path.nfts} component={!library || !blocked ? WalletPage : Placeholder} />
      </Switch>
    </Router>
  );
};

export default App;
