import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notify, Toast } from '../index';
import { StyledButtonCopy } from './styles';

const ButtonCopy = ({ text }: { text: string }) => {
  const handleCopy = () => notify(<Toast message="Copied to clipboard!" />);

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <StyledButtonCopy />
    </CopyToClipboard>
  );
};

export default ButtonCopy;
