import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { requiredNumber } from 'common/utils/validation';
import { stakedAmount } from './values';

const schema = ({ stake_token }: { stake_token: number }) =>
  object().shape({
    staked_amount: requiredNumber(stakedAmount.label).max(stake_token, 'Not enough stake tokens'),
  });

const options = ({ defaultValues, validate }: { defaultValues: object; validate: object }) => ({
  mode: 'onChange',
  // @ts-ignore
  resolver: yupResolver(schema(validate)),
  defaultValues,
});

export default options;
