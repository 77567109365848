import styled from 'styled-components';
import logout from './images/logout.svg';

export const StyledAccountLogout = styled.div`
  width: 3rem;
  height: 3rem;

  border-radius: 50%;
  background-image: url(${logout});

  margin-left: 2.5rem;
  margin-right: 0.8rem;

  cursor: pointer;
`;
