import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LoanOfferFormProps } from './types';
import { durationUnit } from 'features/loans/hooks/utils/duration';
import {
  confirm,
  expirationUnit,
  expirationValue,
  loanValue,
  repaymentValue,
  timelockUnit,
  timelockValue,
} from './values';
import { Button, type } from 'common/components/bits';
import { InputNumber } from 'common/components/form';
import { LoanCost } from 'features/loans/components';
import { useCurrency, useNetwork, useState } from 'common/hooks';
import { parseFromDecimal, parseTimesDecimal, parseToDecimal } from 'common/utils/parsers';
import options from './options';
import { StyledError, StyledField, StyledForm, StyledSelect } from './styles';

const LoanOfferForm = ({
  currency_id,
  currency_amount,
  min_currency_profit_value,
  minimum_value,
  disabled,
  error,
  onSubmit,
}: LoanOfferFormProps) => {
  const handleCurrency = useCurrency();
  const currency = handleCurrency(currency_id);
  const { testnet } = useNetwork();

  const { state, onChange } = useState({
    minimum_profit: parseTimesDecimal(minimum_value, currency.decimal, min_currency_profit_value),
  });

  const defaultValues = {
    expiration_unit: durationUnit.hours,
    loan_value: parseFromDecimal(minimum_value, currency.decimal),
    repayment_value: state.minimum_profit,
    timelock_unit: durationUnit.hours,
  };
  const validate = {
    currency_amount: parseFloat(currency_amount),
    minimum_value: parseFloat(parseFromDecimal(minimum_value, currency.decimal)),
    minimum_profit: parseFloat(state.minimum_profit),
    min_currency_profit_value,
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { dirtyFields, errors, ...formState },
    setError,
    watch,
    ...methods
    // @ts-ignore
  } = useForm(options({ defaultValues, validate }));

  const loan_value = watch(loanValue.name);
  const repayment_value = watch(repaymentValue.name);

  useEffect(() => {
    dirtyFields[loanValue.name] &&
      onChange(() => ({
        ...state,
        minimum_profit: parseTimesDecimal(
          parseToDecimal(loan_value, currency.decimal),
          currency.decimal,
          min_currency_profit_value,
        ),
      }));
  }, [loan_value]);

  useEffect(() => {
    error && setError(error.name, error.option);
  }, [error]);

  return (
    <FormProvider
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={{ dirtyFields, errors, ...formState }}
      setError={setError}
      watch={watch}
      {...methods}
    >
      <StyledForm
        // @ts-ignore
        onSubmit={handleSubmit((data) => onSubmit({ ...data, loan_value, repayment_value }))}
      >
        <StyledField
          error={errors[loanValue.name]}
          length={errors[loanValue.name] && errors[loanValue.name].message.length}
        >
          {errors[loanValue.name] && <StyledError>{errors[loanValue.name].message}</StyledError>}
          <InputNumber {...loanValue} />
          <StyledSelect disabled>
            <option>{currency.name}</option>
          </StyledSelect>
        </StyledField>
        <StyledField
          error={errors[repaymentValue.name]}
          length={errors[repaymentValue.name] && errors[repaymentValue.name].message.length}
        >
          {errors[repaymentValue.name] && <StyledError>{errors[repaymentValue.name].message}</StyledError>}
          <InputNumber {...repaymentValue} decimalPlaces={6} />
          {!errors[repaymentValue.name] && <LoanCost loan_value={loan_value} repayment_value={repayment_value} />}
        </StyledField>
        <StyledField error={errors[timelockValue.name]} length={30}>
          {errors[timelockValue.name] && <StyledError>{errors[timelockValue.name].message}</StyledError>}
          <InputNumber {...timelockValue} decimalPlaces={0} />
          <StyledSelect {...register(timelockUnit.name)} disabled={disabled}>
            {testnet && <option value={durationUnit.minutes}>{durationUnit.minutes}</option>}
            <option value={durationUnit.hours}>{durationUnit.hours}</option>
            <option value={durationUnit.days}>{durationUnit.days}</option>
          </StyledSelect>
        </StyledField>
        <StyledField error={errors[expirationValue.name]} length={30}>
          {errors[expirationValue.name] && (
            <StyledError>{errors[expirationValue.name].message.split(' ').slice(0, -1).join(' ')}</StyledError>
          )}
          <InputNumber {...expirationValue} decimalPlaces={0} />
          <StyledSelect {...register(expirationUnit.name)} disabled={disabled}>
            {testnet && <option value={durationUnit.minutes}>{durationUnit.minutes}</option>}
            <option value={durationUnit.hours}>{durationUnit.hours}</option>
            <option value={durationUnit.days}>{durationUnit.days}</option>
          </StyledSelect>
        </StyledField>
        <Button type={type.submit} text={confirm.label} disabled={disabled} />
      </StyledForm>
    </FormProvider>
  );
};

export default LoanOfferForm;
