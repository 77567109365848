import styled from 'styled-components';
import { Path } from 'app/Router/types';
import { lend, nfts, stake, trade } from 'app/Router';
import { zIndex } from 'styles/enums';
import background from './images/background.png';

const backgroundGradient = (title: string) => {
  switch (title) {
    case lend.path:
    case nfts.name:
      return '#7381ac 0%, #060e23 92%';
    case stake.name:
      return '#19654d 0%, #06230e 92%';
    case trade.name:
      return '#7b4646 0%, #230606 92%';
    default:
      return '#455369 0%, #040404 92% ';
  }
};

export const StyledBackground = styled.div<{ path: Path }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${zIndex.Hidden};

  background-size: cover;
  background-position: center;
  background-image: url(${background}),
    radial-gradient(
      ${({ path }) =>
        // @ts-ignore
        backgroundGradient(path ? path.title : '')}
    );
`;
