import styled, { css } from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexMixin, SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledStatusForm = styled.form`
  ${FlexMixin({
    justifyContent: flex.spaceBetween,
    alignItems: flex.center,
  })}

  margin: 3rem 0 5rem;
`;

export const StyledButton = styled.button<{ active: boolean }>`
  width: 100%;
  height: 3rem;

  color: ${color.white};
  ${SofiaSmallMixin};
  text-transform: uppercase;
  text-align: center;

  background-color: transparent;
  border: 0.1rem solid transparent;
  border-bottom-color: ${addOpacityToColor(color.white, 20)};

  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      border-bottom-color: ${color.red};
    `};
`;
