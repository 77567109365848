import styled from 'styled-components';
import { Container } from 'react-grid-system';
import { color, flex } from 'styles/enums';
import { FixedContainerMixin, FlexMixin, SofiaSmallMixin } from 'styles/mixins';

export const StyledFooter = styled.footer`
  height: 3rem;

  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  background-color: ${color.red};

  padding: 1rem 0;

  @media (max-width: 1139px) {
    height: 5rem;
  }
`;

export const StyledContainer = styled(Container)`
  ${FixedContainerMixin};
  min-width: unset;

  height: 1rem;

  ${SofiaSmallMixin};

  ${FlexMixin({
    justifyContent: flex.flexEnd,
    alignItems: flex.center,
  })};

  text-align: right;

  @media (max-width: 1139px) {
    min-width: unset !important;
    height: 3rem;
  }
`;
