import { Option } from './types';
import { useState } from 'common/hooks';

export const useSorting = ({ defaultOption, options }: { defaultOption: Option; options: Option[] }) => {
  const { state, onChange } = useState(defaultOption.value);

  const handleSorting = (ordering: string) => onChange(ordering);

  return {
    state,
    ordering: options,
    onSorting: handleSorting,
  };
};
