import styled from 'styled-components';
import { TruncateMixin } from 'styles/mixins';

export const StyledLoanCost = styled.span`
  width: 100%;

  text-align: center;

  ${TruncateMixin};

  padding: 0 1rem;
`;
