import React from 'react';
import { faqsTab, glossary, history, support, terms, tutorial } from 'app/Router';
import { TabPanel, Tabs, useTabs } from 'common/components/bits';
import { PageLayout } from 'common/components/layouts';
import { GlossaryTab, SupportTab, TermsTab, TutorialTab } from 'features/faq/components';

const tabs = [
  { id: tutorial.id, name: tutorial.name, tab: tutorial.tab },
  { id: glossary.id, name: glossary.name, tab: glossary.tab },
  { id: terms.id, name: terms.name, tab: terms.tab },
  { id: support.id, name: support.name, tab: support.tab },
];

const FaqsPage = () => {
  // @ts-ignore
  const { activeTab, selectTab } = useTabs({ tabs });

  const handleSelect = (tabIndex: number) => {
    const { tab } = selectTab(tabIndex);

    history.push(faqsTab({ tab }));
  };

  return (
    <PageLayout>
      <Tabs
        // @ts-ignore
        tabs={tabs}
        selectedIndex={activeTab.id}
        onSelect={handleSelect}
        fluid
      >
        <TabPanel>
          <TutorialTab />
        </TabPanel>
        <TabPanel>
          <GlossaryTab />
        </TabPanel>
        <TabPanel>
          <TermsTab />
        </TabPanel>
        <TabPanel>
          <SupportTab />
        </TabPanel>
      </Tabs>
    </PageLayout>
  );
};

export default FaqsPage;
