import { useSelector } from 'react-redux';
import { Currency } from './types';
import { selectCurrencies } from 'app/App/slice';

const useCurrency = () => {
  const currencies = useSelector(selectCurrencies);

  return (currency_id: number): Currency =>
    currencies.find(({ id }: Currency) => id === currency_id) || {
      id: 0,
      name: '',
      address: '',
      decimal: 18,
    };
};

export default useCurrency;
