import React from 'react';
import { LoanDueTimeProps } from './types';
import { differenceInDays, fromUnixTime } from 'date-fns';
import { renderDate } from 'common/utils/presenters';
import { StyledLoanDueTime } from './styles';

const LoanDueTime = ({ accepted_offer, loan_taken_timestamp }: LoanDueTimeProps) => {
  const due_in = fromUnixTime(loan_taken_timestamp + accepted_offer.timelock_period);
  const warning = differenceInDays(due_in, Date.parse(new Date().toISOString())) < 1;

  return accepted_offer ? <StyledLoanDueTime warning={warning}>Due: {renderDate(due_in)}</StyledLoanDueTime> : null;
};

export default LoanDueTime;
