import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useState } from 'common/hooks';

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 42, 4, 5, 56],
});

const useNetwork = () => {
  const { account, activate, error, library } = useWeb3React();

  useEffect(() => {
    activate(injected);
  }, []);

  const dev = process.env.NODE_ENV === 'development' || window.location.hostname.includes('dev');

  const { state: chainId, onChange } = useState(undefined);

  const handleReload = () => window.location.reload();

  const mainnet = chainId === 1;
  const rinkeby = chainId === 4;
  const blocked = (dev && !rinkeby) || (!dev && !mainnet);
  const testnet = !library ? dev : !mainnet;

  useEffect(() => {
    if (library) {
      library.eth.getChainId().then((chainId: number) => onChange(chainId));
      library.givenProvider.addListener('networkChanged', handleReload);

      return () => {
        library.givenProvider.removeListener('networkChanged', handleReload);
      };
    }
  }, [library]);

  return { blocked, dev, account, error, library, testnet };
};

export default useNetwork;
