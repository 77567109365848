import React from 'react';
import { useLocation } from 'react-router-dom';
import { navlinks } from 'app/Router/utils';
import { StyledNavigation, StyledNavLink } from './styles';

const Navigation = () => {
  const location = useLocation();

  return (
    <StyledNavigation>
      {navlinks(location.pathname).map(({ title, href }: { title: string | any; href: string }) => (
        <StyledNavLink
          key={href}
          to={href}
          activeClassName="selected"
          isActive={() => location.pathname.split('/')[1] === href.split('/')[1]}
        >
          {title}
        </StyledNavLink>
      ))}
    </StyledNavigation>
  );
};

export default Navigation;
