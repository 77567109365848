import styled, { css } from 'styled-components';
import { color } from 'styles/enums';
import { SofiaMediumMixin } from 'styles/mixins';

export const StyledLoanDueTime = styled.div<{ warning: boolean }>`
  ${SofiaMediumMixin};

  margin-left: 8.5rem;

  ${({ warning }) =>
    warning &&
    css`
      color: ${color.lightRed};
    `};
`;
