import React from 'react';
import { ButtonProps } from './types';
import { StyledButton } from './styles';

const Button = ({ type = 'button', text, ...props }: ButtonProps) => (
  <StyledButton type={type} {...props}>
    {text}
  </StyledButton>
);

export default Button;
