export const actions = [
  { id: 0, label: 'List collaterals' },
  { id: 1, label: 'Check offers' },
  { id: 2, label: 'Repay loans' },
  { id: 3, label: 'View finished' },
];

export const options = [
  { id: 0, label: 'newest', value: '-date_created' },
  { id: 1, label: 'oldest', value: 'date_created' },
  { id: 2, label: 'highest minimal offer', value: '-minimum_value' },
  { id: 3, label: 'lowest minimal offer', value: 'minimum_value' },
  { id: 4, label: 'awaiting action', value: 'status' },
  { id: 5, label: 'finished first', value: '-status' },
  { id: 6, label: 'closest due', value: 'due_time' },
  { id: 7, label: 'furthest due', value: '-due_time' },
];
