import { ceil } from 'lodash';
import { useState } from 'common/hooks';

export const usePagination = ({ count }: { count: number }) => {
  const { state: page, onChange: onPageChange } = useState(1);

  return {
    page,
    page_count: ceil(count / 6),
    onPageChange,
  };
};
