import { useParams } from 'react-router-dom';
import { TabProps } from './types';

const useTabs = ({ tabs }: { tabs: TabProps[] }) => {
  // @ts-ignore
  const params: { tab: string } = useParams();
  const emptyTab = { id: 0, name: '', tab: '' };

  const activeTab = tabs.find(({ tab }: TabProps) => tab === params.tab) || emptyTab;

  const selectTab = (tabIndex: number) => tabs.find((tab: TabProps) => tab.id === tabIndex) || emptyTab;

  return {
    activeTab,
    selectTab,
  };
};

export default useTabs;
