import React, { forwardRef, LegacyRef } from 'react';
import { Col, Row } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import { AssetCardProps } from './types';
import { path } from 'app/Router';
import { HyperLink, Loader } from 'common/components/bits';
import { useNetwork, useToggle } from 'common/hooks';
import { renderShortAddress } from 'common/utils/presenters';
import { StyledAsset, StyledChildren, StyledDescription, StyledDetails, StyledImage, StyledInfo } from './styles';

const Asset = forwardRef(
  ({ asset, borrower_address, children, disabled, spinner = true }: AssetCardProps, ref: LegacyRef<any>) => {
    const location = useLocation();
    const nfts = location.pathname === path.nfts;

    const assetDescription = useToggle({ open: !nfts });
    const assetId = useToggle({ open: true });

    const { testnet } = useNetwork();

    const asset_href = `https://${testnet ? 'testnets.' : ''}opensea.io/assets/${asset.contract.address}/${
      asset.token_id
    }`;
    const address_href = `https://${testnet ? 'rinkeby.' : ''}etherscan.io/address/${borrower_address}`;

    return (
      // @ts-ignore
      <StyledAsset ref={ref}>
        <Loader visible={disabled} spinner={spinner} />
        <Row nogutter>
          <Col xs={3}>
            <StyledImage>
              {asset.image_original_url ? (
                <img src={asset.image_original_url} alt={asset.name} />
              ) : (
                <div>{asset.name}</div>
              )}
              <HyperLink href={asset_href} text="View on Opensea" />
            </StyledImage>
          </Col>
          <Col xs={5}>
            <StyledDetails>
              <div>{asset.contract.name || '---'}</div>
              <span>{asset.name || '---'}</span>
              {borrower_address ? (
                <div>
                  <HyperLink href={address_href} text={renderShortAddress(borrower_address)} address />
                </div>
              ) : (
                <StyledInfo toggled={assetId.toggleOpen} onClick={assetId.toggleClick}>
                  ID: {asset.token_id}
                </StyledInfo>
              )}
              <StyledDescription toggled={assetDescription.toggleOpen} onClick={assetDescription.toggleClick}>
                {asset.description || '---'}
              </StyledDescription>
            </StyledDetails>
          </Col>
          <Col xs={4}>
            <StyledChildren nfts={nfts}>{children}</StyledChildren>
          </Col>
        </Row>
      </StyledAsset>
    );
  },
);

export default Asset;
