import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import { LoanProps } from 'features/loans/types';
import {
  Filters,
  Pagination,
  queryBuild,
  Search,
  Sorting,
  StatusForm,
  useFilters,
  usePagination,
  useSearch,
  useSorting,
  useStatusForm,
} from 'common/components/assets';
import { LoanAsset } from './components';
import { useState } from 'common/hooks';
import dispatchRequest from 'common/utils/dispatchRequest';
import { parseToString } from 'common/utils/parsers';
import { selectCategories, selectContracts, selectPawnshop } from 'app/App/slice';
import { selectAccount } from 'features/accounts/slice';
import { lendLoansFetchRequest, selectLendLoans } from 'features/loans/slice';
import { actions, options } from './lend';
import { StyledCol, StyledLendTab, StyledPlaceholder, StyledRow } from './styles';

const LendTab = () => {
  const account = useSelector(selectAccount);
  const categories = useSelector(selectCategories);
  const contracts = useSelector(selectContracts);
  const { count, loans } = useSelector(selectLendLoans);
  const { max_timelock_period } = useSelector(selectPawnshop);

  const filters = useFilters();
  const pagination = usePagination({ count });
  const search = useSearch();
  const sorting = useSorting({ defaultOption: options[4], options });
  const statusForm = useStatusForm({ actions });

  const query = queryBuild({
    page: parseToString(pagination.page),
    exclude: '5' + statusForm.state,
    ordering: sorting.state,
    search: search.state,
    ...filters.state,
  });

  const { state: id, ...disabled } = useState(undefined);

  const handleFetch = () =>
    dispatchRequest({
      request: lendLoansFetchRequest,
      values: { query },
    });

  useEffect(() => {
    !!contracts.length && handleFetch();
  }, [account, contracts, query]);

  return (
    <Row nogutter>
      <StyledCol xs={2} height={filters.height}>
        <Search {...search} />
        {(!!categories.length || !!contracts.length) && <Filters {...filters} />}
      </StyledCol>
      <Col xs={10}>
        <StyledLendTab height={filters.height}>
          <>
            <StyledRow>
              <Col xs={6}>
                <Sorting {...sorting} />
              </Col>
              <Col xs={3} offset={{ xs: 3 }}>
                {count > 0 && <Pagination {...pagination} />}
              </Col>
            </StyledRow>
            <StatusForm {...statusForm} />
            {!!loans.length ? (
              loans.map((loan: LoanProps) => (
                <LoanAsset
                  key={loan.id}
                  {...loan}
                  max_timelock_period={max_timelock_period}
                  onFetch={handleFetch}
                  disabled={{ id, ...disabled }}
                />
              ))
            ) : (
              <StyledPlaceholder height={filters.height}>
                Currently, no collaterals.
                <br />
                <br />
                Please wait for others to list collaterals or
                <br />
                change filters.
              </StyledPlaceholder>
            )}
          </>
        </StyledLendTab>
      </Col>
    </Row>
  );
};

export default LendTab;
