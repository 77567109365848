import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { TabsProps } from './types';
import { StyledTabs } from './styles';

const Tabs = ({ children, fluid, tabs = [], selectedIndex, onSelect }: TabsProps) => (
  <StyledTabs selectedIndex={selectedIndex} onSelect={onSelect}>
    <Row nogutter>
      <Col xs={fluid ? 12 : 10} offset={{ xs: fluid ? 0 : 2 }}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.id}>{tab.name}</Tab>
          ))}
        </TabList>
      </Col>
    </Row>
    {children}
  </StyledTabs>
);

export default Tabs;
export { TabPanel };
