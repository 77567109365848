export enum api {
  assetsCategories = 'assets/categories',
  assetsContracts = 'assets/contracts',
  assetsCurrencies = 'assets/currencies',
}

export enum web3 {
  domain = 'domain',
  contracts = 'contracts',
}
