export enum loanValue {
  name = 'loan_value',
  label = 'Loan value',
  message = 'Not enough currency',
}

export enum repaymentValue {
  name = 'repayment_value',
  label = 'Repayment value',
}

export enum currencyId {
  name = 'currency_id',
}

export enum expirationUnit {
  name = 'expiration_unit',
}

export enum expirationValue {
  name = 'expiration_value',
  label = 'Offer due in',
}

export enum confirm {
  label = 'Confirm Offer',
}
