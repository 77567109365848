import { useSelector } from 'react-redux';
import { AssetProps } from 'common/components/assets/Asset/types';
import { Offer, OfferData } from './types';
import { getUnixTime } from 'date-fns';
import { useCurrency, useNetwork } from 'common/hooks';
import usePawnshop from 'contracts/pawnshop';
import { adaptDurationToDate, adaptDurationToSeconds } from '../utils/duration';
import { selectAddress } from 'app/App/slice';

const useOffer = async ({ asset, currency_id }: { asset: AssetProps; currency_id: number }) => {
  const address = useSelector(selectAddress);

  const handleCurrency = useCurrency();
  const { library } = useNetwork();
  const pawnshop = usePawnshop();

  const depositTimestamp =
    library &&
    !!address.pawnshop.length &&
    (await pawnshop
      .itemDepositTimestamp(asset.contract.address, asset.token_id)
      .call()
      .then((result: string) => parseInt(result)));

  return ({ data, nonce }: { data: OfferData; nonce: number }): Offer => ({
    nonce,
    expirationTime:
      data.expiration_time || getUnixTime(adaptDurationToDate({ [data.expiration_unit || '']: data.expiration_value })),
    loanTokenAddress: handleCurrency(currency_id).address,
    collateralItem: {
      tokenAddress: asset.contract.address,
      tokenId: asset.token_id,
      depositTimestamp,
    },
    loanParams: {
      // @ts-ignore
      itemValue: data.loan_value,
      // @ts-ignore
      redemptionPrice: data.repayment_value,
      timelockPeriod:
        data.timelock_period || adaptDurationToSeconds({ [data.timelock_unit || '']: data.timelock_value }),
    },
  });
};

export default useOffer;
