import { Offer } from './types';
import { parseToString } from 'common/utils/parsers';

export const adaptOfferToMessage = (offer: Offer) => ({
  nonce: parseToString(offer.nonce),
  expirationTime: parseToString(offer.expirationTime),
  loanTokenAddress: offer.loanTokenAddress,
  collateralItem: {
    tokenAddress: offer.collateralItem.tokenAddress,
    tokenId: offer.collateralItem.tokenId,
    depositTimestamp: parseToString(offer.collateralItem.depositTimestamp),
  },
  loanParams: {
    itemValue: offer.loanParams.itemValue,
    redemptionPrice: offer.loanParams.redemptionPrice,
    timelockPeriod: parseToString(offer.loanParams.timelockPeriod),
  },
});
