import { FlashLoanProps, LoanOfferProps, LoanProps, ResultLoanOfferProps, ResultLoanProps } from './types';

export const adaptFlashLoans = (results: FlashLoanProps[]) =>
  results.map(({ id, currency_id, expiration_time, lender_address, loan_value, repayment_value }: FlashLoanProps) => ({
    id,
    currency_id,
    expiration_time,
    lender_address,
    loan_value,
    repayment_value,
  }));

export const adaptLoans = (results: ResultLoanProps[]): LoanProps[] =>
  results.map(
    ({
      id,
      accepted_offer,
      asset,
      borrower,
      currency_id,
      loan_taken_timestamp,
      minimum_value,
      others_offers_count,
      owned_offers,
      status,
    }) => ({
      id,
      accepted_offer: accepted_offer && adaptLoanOffer(accepted_offer),
      asset,
      borrower_address: borrower.address,
      currency_id,
      loan_taken_timestamp,
      minimum_value,
      others_offers_count,
      owned_offers: adaptLoanOffers(owned_offers),
      status,
    }),
  );

export const adaptLoanOffer = ({
  id,
  expiration_time,
  lender_address,
  loan_value,
  nonce,
  repayment_value,
  timelock_period,
  signature,
  signature_hash,
  status,
}: ResultLoanOfferProps): LoanOfferProps => ({
  id,
  expiration_time,
  lender_address,
  loan_value,
  nonce,
  repayment_value,
  timelock_period,
  signature,
  signature_hash,
  status,
});

export const adaptLoanOffers = (results: ResultLoanOfferProps[]): LoanOfferProps[] => results.map(adaptLoanOffer);
