import styled from 'styled-components';
import { color } from 'styles/enums';
import { SofiaLargeMixin, SofiaRegularMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledTermsTab = styled.div`
  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 1.5rem 6rem 5rem 3rem;

  li {
    ${SofiaLargeMixin};

    margin-bottom: 3rem;

    * {
      ${SofiaRegularMixin};
    }
  }
`;
