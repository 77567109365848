import { useSelector } from 'react-redux';
import { useContract } from 'common/hooks';
import { selectAddress } from 'app/App/slice';
import { ABI } from './contract';

const usePawnshop = () => {
  const address = useSelector(selectAddress);

  const handleContract = useContract({ ABI });

  return handleContract(address.pawnshop).methods;
};

export default usePawnshop;
