import styled from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexAlignItemsCenterMixin, FlexMixin, SofiaMediumMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledOwnedLoanOffersHeader = styled.div`
  ${FlexMixin({})};

  ${SofiaMediumMixin};

  margin: -1rem 0 0;
  padding: 1rem 2rem;

  > span {
    height: 2rem;

    flex: 1;

    &:first-of-type {
      max-width: 13rem;
    }
  }

  > button {
    height: 2rem;

    flex: 1;

    position: static;

    background-position-x: calc(100% - 0.5rem);
  }
`;

export const StyledOwnedLoanOffers = styled.div`
  max-height: 33rem;

  ${FlexMixin({
    direction: flex.column,
  })};

  ${SofiaMediumMixin};

  background-color: ${addOpacityToColor(color.white, 5)};

  padding: 1rem 2rem;

  overflow-y: auto;
  animation: fadein 0.35s ease-in;

  > div {
    height: 5rem;

    ${FlexAlignItemsCenterMixin};

    padding: 0.5rem 0;

    > * {
      height: 100%;

      flex: 1;
    }

    button {
      height: 3rem;
      width: 10rem;

      ${SofiaMediumMixin};
    }
  }
`;

export const StyledStatus = styled.span`
  text-transform: uppercase;

  padding: 0.3rem 0 0.3rem 1rem;
`;

export const StyledCol = styled.div`
  ${FlexMixin({
    direction: flex.column,
    justifyContent: flex.flexStart,
  })};

  span {
    text-align: left;

    padding: 0;
  }
`;
