import styled, { css } from 'styled-components';
import { color } from 'styles/enums';
import { FlexRowCenterMixin, SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledInputCheckbox = styled.div<{ active: boolean }>`
  width: 100%;
  height: 3rem;

  ${FlexRowCenterMixin};

  ${SofiaSmallMixin};
  text-transform: uppercase;
  text-align: center;

  background-color: transparent;
  border: 0.1rem solid transparent;
  border-bottom-color: ${addOpacityToColor(color.white, 20)};

  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      border-bottom-color: ${color.red};
    `};

  label {
    cursor: pointer;
  }
`;
