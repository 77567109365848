import { Decimal } from 'decimal.js';
import { orderBy } from 'lodash';

export const parseDecimalPlaces = (value: string) => {
  if (value) {
    let int = value.split('.')[0];
    let float = value.split('.')[1];

    while (float.charAt(float.length - 1) == '0') {
      if (float.length == 2) break;

      float = float.substr(0, float.length - 1);
    }

    return int + '.' + float;
  } else return value;
};

export const parseToDecimal = (value: string, decimal: number) => {
  if (!!value.length) {
    return new Decimal(value)
      .times(10 ** decimal)
      .toFixed(0)
      .valueOf();
  } else return '';
};

export const parseFromDecimal = (value: string, decimal: number, decimalPlaces: number = 2) =>
  new Decimal(value)
    .div(10 ** decimal)
    .toFixed(decimalPlaces)
    .valueOf();

export const parseTimesDecimal = (value: string, decimal: number, multiplier_value?: number, add: boolean = true) => {
  if (!!value.length) {
    const decimal_value = new Decimal(parseFromDecimal(value, decimal));
    const multiplier = decimal_value.times(multiplier_value || 1);

    return add
      ? decimal_value.add(multiplier).toFixed(6).valueOf()
      : decimal_value.sub(multiplier).toFixed(6).valueOf();
  }
};

export const parseToString = (value: number) => value.toString();

export const orderById = (value: []) => orderBy(value, 'id');
