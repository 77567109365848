import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import 'react-toastify/dist/ReactToastify.css';
import 'fonts/fonts.css';
import { color, flex } from './enums';
import { FlexAlignItemsCenterMixin, FlexMixin, SofiaLargeMixin, SofiaRegularMixin, SofiaSmallMixin } from './mixins';
import { addOpacityToColor } from './utils';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize};

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    ::selection {
      color: ${color.white};
      background-color: ${color.red};
    }

    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      background: ${addOpacityToColor(color.white, 20)};
      border-radius: 0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0;
    }
  }

  html {
    font-size: 10px;
  }

  body {
    min-height: 100vh;

    display: flex;

    font-family: 'Sofia Pro Regular', sans-serif;
    color: ${color.white};
    ${SofiaRegularMixin};
    
    background-color: ${addOpacityToColor(color.black, 95)};

    #app {
      width: 100%;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .italic {
      font-style: italic;
    }
    
    .warning {
      color: ${color.lightRed};
    }

    .ReactModal__Overlay {
      background-color: ${addOpacityToColor(color.black, 45)} !important;

      .ReactModal__Content {
        width: fit-content;
        height: fit-content;
        
        ${FlexMixin({
          direction: flex.column,
          justifyContent: flex.spaceAround,
          alignItems: flex.center,
        })};

        ${SofiaLargeMixin};

        border: none !important;
        border-radius: 0 !important;
        background-color: ${addOpacityToColor(color.black, 80)} !important;
        
        margin: 10rem auto;
        padding-bottom: 3rem !important;
      }
    }

    .Toastify {
      .Toastify__toast-container--top-right {
        top: 11rem;
      }

      .Toastify__toast--dark {  
        background-color: ${addOpacityToColor(color.black, 50)};
        
        .Toastify__toast-body {
          width: 100%;
          
          ${FlexAlignItemsCenterMixin};
          
          margin: 0;

          a {
            color: ${color.white};
            font-size: 1.3rem;
          }
        }
      }
    }

    .__react_component_tooltip {
      min-width: 20rem;
      
      ${SofiaSmallMixin};
      
      background: ${addOpacityToColor(color.white, 5)} !important;
      border-radius: 0 !important;
      
      padding: 0.8rem 1rem !important; 
      
      &:after {
        left: unset !important;
        right: -0.7rem !important;
      
        border-left-color:${addOpacityToColor(color.white, 5)} !important;
      }
    }
  }
`;

export default GlobalStyle;
