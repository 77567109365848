import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { InputNumberProps } from './types';
import { parseToString } from 'common/utils/parsers';

const InputNumber = ({ name, label, decimalPlaces = 2 }: InputNumberProps) => {
  const { control, register } = useFormContext();

  return (
    <>
      <label>{label}</label>
      <Controller
        control={control}
        {...register(name)}
        render={({ field: { value, onChange } }) => (
          <NumberFormat
            allowNegative={false}
            decimalScale={decimalPlaces}
            fixedDecimalScale
            value={value}
            onChange={onChange}
            placeholder={parseToString(1 / 10 ** decimalPlaces)}
          />
        )}
      />
    </>
  );
};

export default InputNumber;
