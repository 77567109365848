import styled from 'styled-components';
import { color } from 'styles/enums';
import { SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';
import magnifier from './images/magnifier.svg';

export const StyledField = styled.div`
  input,
  button {
    width: 100%;
    height: 2.5rem;
  }
`;

export const StyledInput = styled.input.attrs(() => ({
  style: {
    backgroundImage: `url(${magnifier})`,
  },
}))`
  ${SofiaSmallMixin};
  line-height: 1.6rem;

  border: none;
  box-shadow: inset 0 0.1rem 0.3rem 0 ${addOpacityToColor(color.black, 50)};

  background-repeat: no-repeat;
  background-position: calc(100% - 0.7rem) center;
  background-size: 1.6rem;

  margin-bottom: 0.7rem;
  padding: 0.5rem 2.7rem 0.5rem 0.7rem;
`;
