import styled, { css } from 'styled-components';
import color from 'styles/enums/colors';
import { HyperlinkMixin } from 'styles/mixins';

export const StyledHyperLink = styled.a<{ address?: boolean }>`
  ${HyperlinkMixin};

  ${({ address }) =>
    address &&
    css`
      color: ${color.lightRed};
    `};
`;
