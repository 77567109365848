import styled from 'styled-components';
import { flex } from 'styles/enums';
import { FlexMixin, SofiaMediumMixin } from 'styles/mixins';
import LoanCost from 'features/loans/components/LoanCost';

export const StyledAcceptedLoanOffer = styled.div`
  width: 23rem;

  ${FlexMixin({
    direction: flex.column,
  })};

  ${SofiaMediumMixin};

  margin: 2rem 0;

  > span {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
`;

export const StyledCol = styled.div`
  ${FlexMixin({
    direction: flex.column,
  })};

  margin-bottom: 0.5rem;

  > div {
    ${FlexMixin({})};
  }

  > span {
    margin-left: 8.5rem;
  }
`;

export const StyledLabel = styled.div`
  width: 8.5rem;
`;

export const StyledLoanConst = styled(LoanCost)`
  text-align: left;

  padding: 0;
  margin-top: -2rem;
`;
