import styled from 'styled-components';
import { color } from 'styles/enums';
import { FlexMixin, SofiaSmallMixin } from 'styles/mixins';

export const StyledSorting = styled.div`
  ${FlexMixin({})};

  select {
    width: 17rem;
    height: 2rem;

    color: ${color.white};
    ${SofiaSmallMixin};

    background-color: transparent;
    border: 0.1rem solid ${color.grey};

    margin-left: 1rem;
    padding: 0 1.5rem 0 0.5rem;
  }
`;
