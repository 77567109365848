import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { path } from 'app/Router';
import { Account } from 'features/accounts/components';
import Navigation from '../Navigation';
import logo from './images/logo.png';
import { StyledContainer, StyledHeader, StyledLogo } from './styles';

const Header = () => (
  <StyledHeader>
    <StyledContainer>
      <Row nogutter>
        <Col xs={4} lg={2}>
          <Link to={path.dashboard}>
            <StyledLogo src={logo} alt="Hoard logo" />
          </Link>
        </Col>
        <Col xs={8} lg={4}>
          <Navigation />
        </Col>
        <Col xs={12} lg={6}>
          <Account />
        </Col>
      </Row>
    </StyledContainer>
  </StyledHeader>
);

export default Header;
