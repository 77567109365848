import React from 'react';
import Decimal from 'decimal.js';
import { LoanCostProps } from './types';
import { StyledLoanCost } from './styles';

const LoanCost = ({ loan_value, repayment_value, ...props }: LoanCostProps) => {
  if (!loan_value || !repayment_value) return null;
  if (parseFloat(loan_value) === 0 || parseFloat(repayment_value) === 0) return null;

  const decimal_loan_value = new Decimal(loan_value);
  const decimal_repayment_value = new Decimal(repayment_value);

  const loan_cost = decimal_repayment_value
    .sub(decimal_loan_value)
    .div(decimal_loan_value)
    .times(100)
    .toFixed(2)
    .valueOf();

  return !loan_cost.includes('-') ? <StyledLoanCost {...props}>({loan_cost}%)</StyledLoanCost> : null;
};

export default LoanCost;
