const adaptFinishedLoanOfferStatus = (status?: number) => {
  switch (status) {
    case 5:
      return ' - Repaid';
    case 6:
      return ' - Foreclosed';
    default:
      return '';
  }
};

export const adaptLoanStatus = (status: number, offer_status?: number) => {
  switch (status) {
    case 0:
      return 'Ready to borrow';
    case 1:
      return 'Awaiting offers';
    case 2:
      return 'Loan in progress';
    case 3:
      return `Finished${adaptFinishedLoanOfferStatus(offer_status)}`;
    case 4:
      return 'Claimed';
    case 5:
      return 'Withdrawn';
    default:
      return 'Status not known';
  }
};
