import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { requiredNumber } from 'common/utils/validation';
import { unstakedAmount } from './values';

const schema = ({ staked_amount }: { staked_amount: number }) =>
  object().shape({
    unstaked_amount: requiredNumber(unstakedAmount.label).max(staked_amount, 'Not enough staked tokens'),
  });

const options = ({ defaultValues, validate }: { defaultValues: object; validate: object }) => ({
  mode: 'onChange',
  // @ts-ignore
  resolver: yupResolver(schema(validate)),
  defaultValues,
});

export default options;
