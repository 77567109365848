import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { StatusFormProps } from './types';
import { status } from './values';
import { type } from 'common/components/bits';
import { InputCheckbox } from 'common/components/form';
import { parseToString } from 'common/utils/parsers';
import options from './options';
import { StyledButton, StyledStatusForm } from './styles';

const StatusForm = ({ statuses, onSubmit }: StatusFormProps) => {
  const location = useLocation();
  const defaultValues = { status: status.all };
  // @ts-ignore
  const { control, handleSubmit, setValue, reset, watch, ...methods } = useForm(options({ defaultValues }));
  // @ts-ignore
  const selected = watch(status.name);

  const difference = statuses
    .map((status: { id: number; label: string }) => parseToString(status.id))
    // @ts-ignore
    .filter((id: string) => id !== selected);

  const view_all = difference.length === statuses.length;
  const excluded = view_all ? status.all : !!difference.length ? ',' + difference.join(',') : '';

  useEffect(() => {
    handleSubmit(() => onSubmit(excluded))();
  }, [location.pathname, excluded]);

  useEffect(() => {
    reset();
  }, [location.pathname]);
  // @ts-ignore
  const handleSelectAll = () => setValue(status.name, status.all);

  return (
    <FormProvider
      control={control}
      handleSubmit={handleSubmit}
      setValue={setValue}
      reset={reset}
      watch={watch}
      {...methods}
    >
      <StyledStatusForm>
        <StyledButton type={type.button} onClick={handleSelectAll} active={view_all}>
          View all
        </StyledButton>
        {statuses.map(({ id, label }: { id: number; label: string }) => (
          <InputCheckbox key={id} id={id} {...status} label={label} />
        ))}
      </StyledStatusForm>
    </FormProvider>
  );
};

export default StatusForm;
