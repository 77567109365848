import styled from 'styled-components';
import { Col } from 'react-grid-system';
import { color, flex } from 'styles/enums';
import { FlexMixin, FlexRowCenterMixin, SofiaLargestMixin, SofiaMediumMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledStakeCard = styled.div`
  min-height: 21rem;

  position: relative;

  background-color: ${addOpacityToColor(color.black, 45)};

  margin-bottom: 2rem;
  padding: 2rem;

  &:hover {
    background-color: ${addOpacityToColor(color.black, 30)};
  }

  img {
    width: 13rem;
    height: 13rem;
  }
`;

export const StyledCol = styled(Col)`
  ${FlexRowCenterMixin};
`;

export const StyledDetails = styled.div`
  height: 100%;
  ${FlexMixin({ direction: flex.column, justifyContent: flex.spaceBetween })};
`;

export const StyledTitle = styled.div`
  ${SofiaLargestMixin};
`;

export const StyledInfo = styled.div`
  ${SofiaMediumMixin};
`;

export const StyledWarning = styled(StyledInfo)`
  margin: 1rem 0;
`;

export const StyledChildren = styled.div`
  > div {
    min-height: 16.5rem;
    height: 100%;

    ${FlexMixin({ direction: flex.column, justifyContent: flex.spaceBetween, alignItems: flex.flexEnd })};
  }
`;
