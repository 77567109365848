import { compile } from 'path-to-regexp';
import { borrow, faqs, flash, glossary, lend, nfts, path, stake, support, terms, trade, tutorial } from './paths';

export const loansTab = compile(path.loansTab);
export const faqsTab = compile(path.faqsTab);

export const navlinks = (pathname: string) => [
  {
    title: lend.path,
    href: loansTab({ tab: pathname === path.nfts ? borrow.tab : lend.tab }),
  },
  {
    title: trade.name,
    href: path.trade,
  },
  {
    title: stake.name,
    href: path.stake,
  },
  {
    title: faqs.name,
    href: faqsTab({ tab: tutorial.tab }),
  },
];

export const routes = (pathname: string) => [
  ...navlinks(pathname),
  {
    title: borrow.path,
    href: loansTab({ tab: borrow.tab }),
  },
  {
    title: flash.path,
    href: loansTab({ tab: flash.tab }),
  },
  {
    title: nfts.name,
    href: path.nfts,
  },
  {
    title: tutorial.name,
    href: faqsTab({ tab: tutorial.tab }),
  },
  {
    title: glossary.name,
    href: faqsTab({ tab: glossary.tab }),
  },
  {
    title: terms.name,
    href: faqsTab({ tab: terms.tab }),
  },
  {
    title: support.name,
    href: faqsTab({ tab: support.tab }),
  },
];
