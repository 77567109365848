import styled from 'styled-components';
import { Tabs } from 'react-tabs';
import { color } from 'styles/enums';
import { SofiaLargeMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledTabs = styled(Tabs)`
  max-width: 111rem;
  width: 100%;
  height: 4rem;

  position: absolute;
  top: -4.6rem;

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }

  .react-tabs__tab-list {
    ${SofiaLargeMixin};

    border-bottom: none;

    margin: 0;
    padding: 0;
  }

  .react-tabs__tab {
    width: 20rem;

    display: inline-block;
    list-style: none;

    text-align: center;

    padding: 1rem;

    cursor: pointer;
  }

  .react-tabs__tab--selected {
    background-color: ${addOpacityToColor(color.black, 45)};
    border-bottom: 0.1rem solid ${addOpacityToColor(color.white, 20)};
    border-radius: 0;
  }

  .react-tabs__tab--disabled {
    color: ${color.grey};

    cursor: default;
  }

  .react-tabs__tab:focus {
    box-shadow: none;
  }

  .react-tabs__tab-panel {
    display: none;

    padding-bottom: 10rem;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;
