export const abi = `{
  inputs: [
    {
      internalType: 'contract IERC3156FlashBorrower',
      name: 'receiver',
      type: 'address',
    },
    {
      internalType: 'address',
      name: 'lenderAddress',
      type: 'address',
    },
    {
      internalType: 'bytes',
      name: 'signature',
      type: 'bytes',
    },
    {
      components: [
        {
          internalType: 'uint32',
          name: 'nonce',
          type: 'uint32',
        },
        {
          internalType: 'uint40',
          name: 'expirationTime',
          type: 'uint40',
        },
        {
          internalType: 'address',
          name: 'loanTokenAddress',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'loanAmount',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'returnAmount',
          type: 'uint256',
        },
      ],
      internalType: 'struct FlashOfferModel.FlashOffer',
      name: 'offer',
      type: 'tuple',
    },
    {
      internalType: 'bytes',
      name: 'data',
      type: 'bytes',
    },
  ],
  name: 'flashLoan',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
}`;

export const flashBorrower = `// SPDX-License-Identifier: MIT
pragma solidity 0.7.3;

interface IERC3156FlashBorrower {
   /**
    * @dev Receive a flash loan.
    * @param sender The initiator of the loan.
    * @param token The loan currency.
    * @param amount The amount of tokens lent.
    * @param fee The additional amount of tokens to repay.
    * @param data Arbitrary data structure, intended to contain user-defined parameters.
    */
    
   function onFlashLoan(
       address sender,
       address token,
       uint256 amount,
       uint256 fee,
       bytes calldata data
   ) external;
}
`;

export const flashLoanFunction = `function onFlashLoan(
        address sender,
        address loanTokenAddress,
        uint256 loanAmount,
        uint256 fee,
        bytes calldata data
    ) external;
`;

export const flashLoanFunctionImplementation = `function onFlashLoan(
        address sender,
        address loanTokenAddress,
        uint256 loanAmount,
        uint256 fee,
        bytes calldata data
    ) external override {
        require(msg.sender == address(_pawnshop), "Untrusted origin");
        require(sender == _my_address, "Untrusted borrower");

        // do some trading, loanAmount of loanTokenAddress is available

        IERC20(loanTokenAddress).approve(
            address(_pawnshop), allowance.add(loanAmount)
        );
    }
`;

export const flashLoanExecute = `import { ethers } from 'ethers';
import { BigNumber, utils, Wallet } from 'ethers';
import fs from 'fs';

const pawnshopAddress = '0xB8434f35f15808d45b8285Cd4D59cd94894D385d';

let receivingContractAddress = ...
let lenderAddress = ...
let signature = ...
let offerParams = {
    nonce: BigNumber.from(...),
    expirationTime: BigNumber.from(...),
    loanTokenAddress: ...,
    loanAmount: BigNumber.from(...),
    returnAmount: BigNumber.from(...),
};
let data = ...
let loanParams = [
    receivingContractAddress,
    lenderAddress,
    signature,
    offerParams,
    data,
];

const wallet = ...

let pawnshopABI = JSON.parse(fs.readFileSync('pawnshop.abi', 'utf8'));
let pawnshop = new ethers.Contract(pawnshopAddress, pawnshopABI, wallet);

let flashLoan = await pawnshop.flashLoan(...loanParams);
`;
