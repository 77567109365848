import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { color, flex } from 'styles/enums';
import { FlexAlignItemsCenterMixin, FlexMixin, SofiaSmallMixin } from 'styles/mixins';

export const StyledAccount = styled.div`
  width: 100%;

  ${FlexMixin({ direction: flex.column, alignItems: flex.flexEnd })}

  > div {
    ${FlexAlignItemsCenterMixin};

    @media (min-width: 1140px) {
      &:last-of-type {
        margin-right: 6.5rem;
      }
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: ${color.white};
  ${SofiaSmallMixin};
  text-decoration: none;
  
  border-bottom: 0.2rem solid transparent;
  
  margin: 0.5rem -0.5rem 0 0.5rem;

  &:hover {
    text-decoration: none;
  }

  &.selected {
    border-bottom-color: ${color.red};
  }
}
`;
