import React from 'react';
import { StyledHyperLink } from './styles';

const HyperLink = ({ href, text, address, ...props }: { href: string; text: string; address?: boolean }) => (
  <StyledHyperLink href={href} target="_blank" rel="noopener noreferrer" address={address} {...props}>
    {text}
  </StyledHyperLink>
);

export default HyperLink;
