import { Status } from './types';
import { useState } from 'common/hooks';

export const useStatusForm = ({ actions }: { actions: Status[] }) => {
  const { state, onChange } = useState('');

  const handleSubmit = (value: string) => onChange(value);

  return {
    state,
    statuses: actions,
    onSubmit: handleSubmit,
  };
};
