import React from 'react';
import { StyledContainer, StyledFooter } from './styles';

const Footer = () => (
  <StyledFooter>
    <StyledContainer>
      This is a beta version of the Hoard Marketplace. Be aware that you use it at your own risk.
    </StyledContainer>
  </StyledFooter>
);

export default Footer;
