import styled from 'styled-components';
import { SofiaSmallMixin } from 'styles/mixins';

export const StyledLoanStatus = styled.div`
  width: 20rem;

  ${SofiaSmallMixin};
  text-transform: uppercase;
  text-align: right;

  padding: 0.5rem 1rem;
  margin: 0 0 2rem auto;
`;
