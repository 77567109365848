import { formatDistanceToNowStrict } from 'date-fns';
import { isNull, isUndefined } from 'lodash';

export const renderDate = (date: Date) => formatDistanceToNowStrict(new Date(date.toISOString()), { addSuffix: true });

export const renderShortAddress = (address: string | null | undefined, start: number = 6, stop: number = 4) =>
  address ? address.substring(0, start) + '...' + address.substring(address.length, address.length - stop) : '';

export const renderSize = (sizeValue: { xs: number }) => {
  if (isNull(sizeValue) || isUndefined(sizeValue)) return undefined;

  return sizeValue.xs;
};
