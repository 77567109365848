import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { method } from 'common/enums/method';
import { state } from 'common/enums/state';
import { api } from './enums/api';
import asyncRequest from 'common/utils/http';

export const accountFetchRequest = asyncRequest({
  method: method.get,
  path: api.account,
});

export const accountAssetsFetchRequest = asyncRequest({
  method: method.get,
  path: api.accountAssets,
});

export const accountAssetsCountFetchRequest = asyncRequest({
  method: method.get,
  path: api.accountAssets,
});

export const accountNonceFetchRequest = asyncRequest({
  method: method.get,
  path: api.accountNonce,
});

export const accountTokenRequest = asyncRequest({
  method: method.get,
  path: api.accountCsrf,
});

export const accountLogoutRequest = asyncRequest({
  method: method.post,
  path: api.accountLogout,
});

const account = {
  username: '',
  email: '',
  address: '',
  is_authenticated: false,
};

const initialState = {
  account,
  assets: { count: undefined },
  balance: [],
  country_flag: '',
};

export const slice = createSlice({
  name: state.accounts,
  initialState,
  reducers: {
    setBalance: (state, { payload }) => ({ ...state, balance: payload }),
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(accountFetchRequest.fulfilled, (state, { payload: { account, country_flag } }) => ({
      ...state,
      account,
      country_flag,
    }));
    // @ts-ignore
    builder.addCase(accountAssetsCountFetchRequest.fulfilled, (state, { payload: { count } }) => ({
      ...state,
      assets: { count },
    }));
    builder.addCase(accountLogoutRequest.fulfilled, () => initialState);
  },
});

export const selectAccount = (state: RootState) => state.accounts.account;
export const selectAccountAssetsCount = (state: RootState) => state.accounts.assets.count;
export const selectAccountBalance = (state: RootState) => state.accounts.balance;
export const selectAccountCountryFlag = (state: RootState) => state.accounts.country_flag;

export const { setBalance } = slice.actions;

export default slice.reducer;
