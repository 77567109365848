import { formatDuration } from 'date-fns';
import { round } from 'lodash';
import { durationUnit } from './duration';

export const renderDuration = (value: number) => {
  let duration;

  if (value < 3600) {
    duration = { [durationUnit.minutes]: round(value / 60) };
  } else if (value >= 3600 && value < 3600 * 24) {
    duration = { [durationUnit.hours]: round(value / 3600, 1) };
  } else {
    duration = { [durationUnit.days]: round(value / 3600 / 24, 1) };
  }

  return formatDuration(duration);
};
