import { createAsyncThunk } from '@reduxjs/toolkit';
import { asyncRequestProps } from 'common/utils/http/types';
import httpRequest from './httpRequest';

const asyncRequest = ({ web3 = false, method, path, subpath }: asyncRequestProps) =>
  createAsyncThunk(
    `${path}/${subpath ? `${method}/${subpath}` : method}`,
    // @ts-ignore
    async ({ id, query, data }, { rejectWithValue }) => {
      const response = await httpRequest({ web3, method, path, id, subpath, query, data });

      if (response.ok) return response.body;
      return rejectWithValue(response.body);
    },
  );

export default asyncRequest;
