import styled from 'styled-components';
import { color } from 'styles/enums';
import { FlexColumnCenterMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledSupportTab = styled.div`
  min-height: 40rem;

  ${FlexColumnCenterMixin};

  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 3rem 5rem;
`;
