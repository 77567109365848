import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import detectEthereumProvider from '@metamask/detect-provider';
import { isNull } from 'lodash';
import { nfts, path } from 'app/Router/paths';
import { queryBuild } from 'common/components/assets';
import { HyperLink, notify, Toast } from 'common/components/bits';
import { AccountBalance, AccountLogin, AccountLogout } from '../index';
import { options } from 'features/loans/components/LendTab/lend';
import { useNetwork, useState } from 'common/hooks';
import dispatchRequest from 'common/utils/dispatchRequest';
import { renderShortAddress } from 'common/utils/presenters';
import {
  accountAssetsCountFetchRequest,
  accountFetchRequest,
  accountLogoutRequest,
  selectAccount,
  selectAccountAssetsCount,
} from 'features/accounts/slice';
import { flashLoansFetchRequest, lendLoansFetchRequest } from 'features/loans/slice';
import { StyledAccount, StyledNavLink } from './styles';

const Account = () => {
  const location = useLocation();
  const account = useSelector(selectAccount);
  const accountAssetsCount = useSelector(selectAccountAssetsCount);

  const { blocked, library, testnet } = useNetwork();

  const { state: metamask, onChange } = useState(library && library.givenProvider.isMetaMask);

  useEffect(() => {
    detectEthereumProvider().then(
      (result: any) =>
        !isNull(result) && (result !== window.ethereum ? notify(<Toast code={1} />) : onChange(result.isMetaMask)),
    );

    dispatchRequest({ request: accountFetchRequest });
  }, []);

  useEffect(() => {
    account.is_authenticated &&
      dispatchRequest({
        request: accountAssetsCountFetchRequest,
      });
  }, [account]);

  const query = queryBuild({
    page: '1',
    ordering: options[4].value,
  });

  const handleFetch = () => {
    dispatchRequest({
      request: lendLoansFetchRequest,
      values: { query: query + '&exclude=5' },
    });
    dispatchRequest({
      request: flashLoansFetchRequest,
      values: { query },
    });
  };

  const handleLogout = () => dispatchRequest({ request: accountLogoutRequest, onSuccess: handleFetch });

  const handleAccountChange = (accounts: string[]) => {
    const address = library.utils.toChecksumAddress(accounts[0]);
    const message = `Changed to ${renderShortAddress(address)}`;

    !!address.length
      ? notify(account.address === address ? <Toast message={message} /> : <Toast warning={message} />, false)
      : handleLogout();
  };

  useEffect(() => {
    if (library) {
      library.givenProvider.addListener('accountsChanged', handleAccountChange);

      return () => {
        library.givenProvider.removeListener('accountsChanged', handleAccountChange);
      };
    }
  }, [account, library]);

  const renderName = () => `${nfts.name} (${accountAssetsCount || '-'})`;

  const address_href = `https://${testnet ? 'rinkeby.' : ''}etherscan.io/address/${account.address}`;

  return account.is_authenticated ? (
    <StyledAccount>
      <div>
        <HyperLink href={address_href} text={account.address} address />
        <AccountLogout onLogout={handleLogout} />
      </div>
      {!blocked && (
        <div>
          <AccountBalance />
          <StyledNavLink
            to={path.nfts}
            activeClassName="selected"
            isActive={() => location.pathname.split('/')[1] === path.nfts.split('/')[1]}
          >
            {renderName()}
          </StyledNavLink>
        </div>
      )}
    </StyledAccount>
  ) : (
    <AccountLogin metamask={metamask} />
  );
};

export default Account;
