import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { multiCurrencies } from '../values';
import { parseToString } from 'common/utils/parsers';
import { selectCurrencies } from 'app/App/slice';
import { StyledCurrencies, StyledField, StyledInput, StyledTitle } from './styles';

const Currencies = () => {
  const currencies = useSelector(selectCurrencies);

  const { register, watch } = useFormContext();

  const values = watch(multiCurrencies.name);

  return !!currencies.length ? (
    <StyledCurrencies>
      <StyledTitle>Currencies</StyledTitle>
      {currencies.map(({ id, name }: { id: number; name: string }) => (
        <StyledField key={id}>
          <StyledInput
            type="checkbox"
            {...register(multiCurrencies.name)}
            value={id}
            checked={values.includes(parseToString(id))}
          />
          <span>{name}</span>
        </StyledField>
      ))}
    </StyledCurrencies>
  ) : null;
};

export default Currencies;
