import React from 'react';
import { StyledGlossaryTab } from './styles';
import { style } from 'styles/enums';

const GlossaryTab = () => (
  <StyledGlossaryTab>
    <p>
      <strong>Accept Offer</strong>
      <br />
      To accept an offer means to take a loan under terms set by a Lender. Once the offer is accepted and confirmed
      through MetaMask wallet the funds are transferred to the Borrower and the loan duration begins.
      <br />
      The Borrower receives the loan value deducted by the platform fee; 0.1% for loans in HRD, 0.2% for loans in other
      crypto currencies (see Platform fee).
      <br />
      The Borrower must pay a loan before the due date otherwise the loan is foreclosed and their collateral is
      transferred to the Lender.
    </p>
    <p>
      <strong>Borrower</strong>
      <br />A person who owns NFTs and would like to use it as a collateral to take a loan in cryptocurrency.
    </p>
    <p>
      <strong>Claim</strong>
      <br />
      To claim an item means to retrieve an item (NFT) which was used as collateral in{' '}
      <span className={style.italic}>a foreclosed loan</span>. The claimed item is visible in Loan &gt; Borrower &gt;
      List Collaterals and is ready to be used as collateral.
    </p>
    <p>
      <strong>Claim & Withdraw</strong>
      <br />
      To claim & withdraw means to retrieve an item (NFT) which was used as collateral in{' '}
      <span className={style.italic}>a foreclosed loan</span> and transfer it to the Lender’s wallet. The claimed item
      is visible in NFTs tab and can be deposited to the Marketplace protocol to be used as collateral.
    </p>
    <p>
      <strong>Claim rewards</strong>
      <br />
      To claim rewards mean to transferred due amounts from the staking pool to the user’s wallet.
    </p>
    <p>
      <strong>Deposit</strong>
      <br />
      To deposit an item (NFT) means to transfer it to the Marketplace protocol.
      <br />
      It is a necessary step before the NFT can be listed as collateral.
    </p>
    <p>
      <strong>Lender</strong>
      <br />A person who owns crypto funds and would like to lend them and charge interest.
    </p>
    <p>
      <strong>List collateral</strong>
      <br />
      To list a collateral means to request a loan. Once the NFT is listed as collateral it is visible to Lenders who
      can make a loan offer for it.
    </p>
    <p>
      <strong>Loan duration</strong>
      <br />
      Loan duration is a maximum time during which the Borrower has to repay the loan.
      <br />
      Maximum time set by the protocol is 24 months.
    </p>
    <p>
      <strong>Loan value</strong>
      <br />
      Loan value is an amount which the Lender is willing to lend. It has to be equal or greater than the minimal offer
      set by the Borrower.
    </p>
    <p>
      <strong>Make an offer</strong>
      <br />
      To make an offer means to offer a loan secured by the collateral (NFT) listed by Borrowers Lender sets out the
      following loan parameters: loan value, repayment value, loan duration, offer due in.
      <br />
      Once the offer is made and accepted by Borrower the funds are transferred from the Lender’s to Borrower’s account.
      <br />
      The collateral is kept in the Marketplace protocol and will be released to the Borrower once the loan is repaid or
      will be transferred to the Lender in case of foreclosure.
    </p>
    <p>
      <strong>Offer due in</strong>
      <br />
      It’s a time period after which the loan offer expires and is no longer available for the Borrower to accept.
    </p>
    <p>
      <strong>Platform fee</strong>
      <br />
      Platform fees are charged for every transaction that takes place on the Marketplace.
      <br />
      100% of those fees are allocated to fund staking rewards.
      <br />
      Only Borrowers pay fees. When they accept a loan they’re charged 0.1% of a value of transactions in HRD and 0.2 %
      of a value of a transaction in other crypto currencies.
      <br />
      The fee is being deducted from the loan value.
      <br />
      Borrowers can decrease their platform fees by becoming stakers on the Marketplace; as part of their fees will
      return to them in a form of staking rewards (see Stake tokens).
    </p>
    <p>
      <strong>Repay loan</strong>
      <br />
      To repay a loan means to return the repayment value to the lender’s account before a loan due date expires.
      <br />
      Once the loan is repaid the repayment value is transferred to the Lender account, collateral is released and the
      loan is finished.
    </p>
    <p>
      <strong>Repayment value</strong>
      <br />
      An amount which the Lender wants to receive at the end of a loan period. That includes an interest charged for
      lending the funds.
      <br />
      Minimal interest set by the protocol is 1%.
    </p>
    <p>
      <strong>Rewards</strong>
      <br />
      Rewards are the benefits paid to those who stake their staking HRD token (ETHHRD UNIV2 token) into the Marketplace
      protocol. Rewards are generated by platform fees paid by those who transact on the Marketplace. Rewards are kept
      in currency pools corresponding to the translation currencies that generated them. Stakers are entitled to the
      percentage of rewards proportional to their staking contributions.
    </p>
    <p>
      <strong>Staker</strong>
      <br />A person who locks their staking HRD tokens (ETHHRD UNIV2) in the Marketplace protocol to receive rewards.
    </p>
    <p>
      <strong>Stake tokens</strong>
      <br />
      Stake means to deposit staking HRD token (ETHHRD UNIV2 token) into the Marketplace protocol to receive rewards
      proportional to the staking contributions
    </p>
    <p>
      <strong>Staking HRD tokens (ETHHRD UNIV2 token)</strong>
      <br />
      Staking HRD tokens are HRD derived tokens.
      <br />
      They are created by adding ETH and HRD to the ETH/HRD trading pool on Uniswap and by becoming a Liquidity Provider
      (LP) there. In return LPs receive UNI V2 ETH/HRD tokens which proves their share in the trading pool.
    </p>
    <p>
      <strong>Unstake tokens</strong>
      <br />
      To unstake tokens mean to transfer staking HRD token (ETHHRD UNIV2 token) from the Marketplace protocol to the
      user’s wallet. By doing so user will no longer be entitled to receive staking rewards.
    </p>
    <p>
      <strong>Withdraw Item</strong>
      <br />
      To withdraw an item (NFT) means to transfer it from the Marketplace protocol back to the user’s wallet. The item
      must be deposited again before it can be used as collateral (see Deposit).
    </p>
  </StyledGlossaryTab>
);

export default GlossaryTab;
