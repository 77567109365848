import { yupResolver } from '@hookform/resolvers/yup';
import { number, object } from 'yup';
import { requiredNumber } from 'common/utils/validation';
import { minimumValue } from './values';

const schema = ({}) =>
  object().shape({
    minimum_value: requiredNumber(minimumValue.label),
    currency_id: number(),
  });

const options = ({ defaultValues, validate }: { defaultValues: object; validate: object }) => ({
  mode: 'onChange',
  resolver: yupResolver(schema(validate)),
  defaultValues,
});

export default options;
