import React from 'react';
import ReactModal from 'react-modal';
import { ModalProps } from './types';
import { ButtonClose } from '../index';
import { StyledChildren } from './styles';

ReactModal.setAppElement('#app');

const Modal = ({ children, footer, open, onClose }: ModalProps) =>
  open ? (
    <ReactModal isOpen={open}>
      <ButtonClose onClose={onClose} />
      <StyledChildren>{children}</StyledChildren>
      {footer}
    </ReactModal>
  ) : null;

export default Modal;
