import { FlashLoanOffer, FlashOfferData } from './types';
import { getUnixTime } from 'date-fns';
import { useCurrency } from 'common/hooks';
import { adaptDurationToDate } from '../utils/duration';

const useFlashOffer = async () => {
  const handleCurrency = useCurrency();

  return ({ data, nonce }: { data: FlashOfferData; nonce: number }): FlashLoanOffer => ({
    nonce,
    expirationTime:
      data.expiration_time || getUnixTime(adaptDurationToDate({ [data.expiration_unit || '']: data.expiration_value })),
    loanTokenAddress: handleCurrency(data.currency_id).address,
    loanAmount: data.loan_value,
    returnAmount: data.repayment_value,
  });
};

export default useFlashOffer;
