import React from 'react';
import { LoanMinimumValueProps } from './types';
import { useCurrency } from 'common/hooks';
import { parseFromDecimal } from 'common/utils/parsers';
import { StyledLoanMinimumValue } from './styles';

const LoanMinimumValue = ({ currency_id, minimum_value, accepted_offer }: LoanMinimumValueProps) => {
  const handleCurrency = useCurrency();
  const decimal = handleCurrency(currency_id).decimal;

  return currency_id ? (
    <StyledLoanMinimumValue accepted_offer={accepted_offer}>
      Minimal offer: {parseFromDecimal(minimum_value, decimal)} {handleCurrency(currency_id).name}
    </StyledLoanMinimumValue>
  ) : null;
};

export default LoanMinimumValue;
