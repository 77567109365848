import React from 'react';
import request from 'superagent';
import Cookies from 'universal-cookie';
import { ToastProps } from 'common/components/bits/Toast/types';
import { Response } from 'common/utils/http/types';
import { method } from 'common/enums/method';
import { Button, HyperLink, notify, Toast } from 'common/components/bits';
import { useNetwork } from 'common/hooks';
import dispatchRequest from 'common/utils/dispatchRequest';
import onFulfilled from 'common/utils/http/onFulfilled';
import onRejected from 'common/utils/http/onRejected';
import { accountFetchRequest, accountTokenRequest } from 'features/accounts/slice';
import { StyledAccountLogin } from './styles';

const AccountLogin = ({ metamask }: { metamask: boolean }) => {
  const login_url = '/api/web3/login_api/';
  const { account, library } = useNetwork();

  const handleSuccessLogin = () => dispatchRequest({ request: accountFetchRequest });

  const handleSignatureLogin = (account: string, signature: string) => {
    request(method.post, login_url)
      .set({ 'X-CSRFToken': new Cookies().get('csrftoken') })
      .send('address=' + account + '&signature=' + signature)
      .then((response: Response) => {
        const {
          // @ts-ignore
          body: { success },
        } = onFulfilled(response);

        success && handleSuccessLogin();
      })
      .catch(onRejected);
  };

  const handleError = (error: ToastProps) => notify(<Toast {...error} />);

  const handleConnect = () => handleError({ code: -32002 });

  const handleLogin = (account: string) =>
    request(method.get, login_url).then((response: Response) => {
      const {
        // @ts-ignore
        body: { success, data },
      } = onFulfilled(response);

      !new Cookies().get('csrftoken') &&
        dispatchRequest({
          request: accountTokenRequest,
        });

      // @ts-ignore
      success &&
        library.givenProvider.sendAsync(
          {
            method: 'personal_sign',
            params: [library.utils.toHex(data), account],
            from: account,
          },
          (error: ToastProps, { result: signature }: { result: string }) =>
            error ? handleError(error) : handleSignatureLogin(account, signature),
        );
    });

  return (
    <StyledAccountLogin>
      {!metamask && <HyperLink href="https://metamask.io/" text="Install MetaMask" />}
      {metamask && !account && <Button text="Connect" onClick={handleConnect} small />}
      {account && <Button text="Log In" onClick={() => handleLogin(account)} small />}
    </StyledAccountLogin>
  );
};

export default AccountLogin;
