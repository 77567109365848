import React from 'react';
import { useSelector } from 'react-redux';
import { ToastProps } from 'common/components/bits/Toast/types';
import { Balance } from 'common/hooks/useBalance/types';
import { LoanOfferProps } from 'features/loans/types';
import { OfferData } from 'features/loans/hooks/useOffer/types';
import { LoanOfferFormData } from '../LoanOfferForm/types';
import { LoanAssetProps, LoanAssetState } from './types';
import { timelockValue } from '../LoanOfferForm/values';
import { Asset } from 'common/components/assets';
import { Button, Loader, notify, Toast } from 'common/components/bits';
import { LoanDueTime, LoanMinimumValue, LoanStatus, OwnedLoanOffers } from 'features/loans/components';
import { LoanOfferForm } from '../index';
import { ToggleRef, useContract, useCurrency, useNetwork, useState, useToggle } from 'common/hooks';
import useOffer, { adaptOfferToMessage, primaryType, types } from 'features/loans/hooks/useOffer';
import { adaptDurationToSeconds, durationUnit } from 'features/loans/hooks/utils/duration';
import dispatchRequest from 'common/utils/dispatchRequest';
import { max } from 'common/utils/library';
import { parseFromDecimal, parseToDecimal } from 'common/utils/parsers';
import usePawnshop from 'contracts/pawnshop';
import { selectAddress, selectDomain, selectPawnshop } from 'app/App/slice';
import { accountNonceFetchRequest, selectAccount, selectAccountBalance } from 'features/accounts/slice';
import { loanOfferCreateRequest } from 'features/loans/slice';
import { ABI } from './contract';
import { StyledLoan, StyledPlaceholder, StyledToggle } from './styles';

const LoanAsset = ({
  id,
  accepted_offer,
  asset,
  borrower_address,
  currency_id,
  loan_taken_timestamp,
  minimum_value,
  owned_offers,
  status,
  max_timelock_period,
  onFetch,
  disabled,
}: LoanAssetProps) => {
  const account = useSelector(selectAccount);
  const address = useSelector(selectAddress);
  const balance = useSelector(selectAccountBalance);
  const currency = balance.find(({ id }: Balance) => id === currency_id) || { amount: '0.00' };
  const domain = useSelector(selectDomain);
  const {
    platform: { min_lender_profit },
  } = useSelector(selectPawnshop);
  // @ts-ignore
  const min_currency_profit = min_lender_profit.find(({ id }) => id === currency_id);

  const handleContract = useContract({ ABI });
  const handleCurrency = useCurrency();
  const decimal = handleCurrency(currency_id).decimal;
  const handleOffer = useOffer({ asset, currency_id });
  const { library } = useNetwork();
  const pawnshop = usePawnshop();

  const loanForm = useToggle({ outsideClick: true });
  const loanClaim = useToggle({});
  const loanOffers = useToggle({});

  const { state, onChange, onReset } = useState({ error: undefined, loader: false });

  const handleSuccessSubmit = () => {
    onFetch();
    loanForm.toggleClick();
    disabled.onReset();
  };

  const handleSubmit = (data: OfferData & { nonce: number; signature: string }) =>
    dispatchRequest({
      request: loanOfferCreateRequest,
      values: { id, data },
      onSuccess: handleSuccessSubmit,
    });

  const handleError = (error: ToastProps) => {
    notify(<Toast {...error} />);
    onReset();
    disabled.onReset();
  };

  const handleSign = async (data: LoanOfferFormData) => {
    const nonce = await dispatchRequest({
      request: accountNonceFetchRequest,
      onSuccess: ({ offer_nonce }: { offer_nonce: number }) => offer_nonce,
    });

    const offer = (await handleOffer)({
      data: {
        ...data,
        loan_value: parseToDecimal(data.loan_value, decimal),
        repayment_value: parseToDecimal(data.repayment_value, decimal),
      },
      nonce: nonce + 1,
    });

    // @ts-ignore
    library.eth.givenProvider.sendAsync(
      {
        method: 'eth_signTypedData_v3',
        params: [
          account.address,
          JSON.stringify({
            types,
            domain,
            primaryType,
            message: adaptOfferToMessage(offer),
          }),
        ],
        from: account.address,
      },
      (error: ToastProps, { result: signature }: { result: string }) =>
        error
          ? handleError(error)
          : handleSubmit({
              deposit_timestamp: offer.collateralItem.depositTimestamp,
              expiration_time: offer.expirationTime,
              loan_value: offer.loanParams.itemValue,
              nonce: nonce + 1,
              repayment_value: offer.loanParams.redemptionPrice,
              timelock_period: offer.loanParams.timelockPeriod,
              signature,
            }),
    );
  };

  const handleAllowance = (data: LoanOfferFormData) =>
    handleContract(handleCurrency(currency_id).address)
      .methods.approve(address.pawnshop, max)
      .send({ from: account.address })
      .then(() => {
        notify(<Toast message={handleCurrency(currency_id).name + ' allowance increased to maximum'} />);
        handleSign(data);
      })
      .catch(handleError);

  const handleConfirm = (data: LoanOfferFormData) => {
    disabled.onChange(id);

    const timelock_period = adaptDurationToSeconds({ [data.timelock_unit]: data.timelock_value });

    if (timelock_period > max_timelock_period) {
      onChange((state: LoanAssetState) => ({
        ...state,
        error: {
          name: timelockValue.name,
          option: { message: `${timelockValue.message} ${max_timelock_period / 3600 / 24} ${durationUnit.days}` },
        },
      }));
      disabled.onReset();
    } else {
      handleContract(handleCurrency(currency_id).address)
        .methods.allowance(account.address, address.pawnshop)
        .call()
        .then((result: string) => {
          const allowance = parseFromDecimal(result, decimal);

          return parseFloat(allowance) < parseFloat(data.loan_value) ? handleAllowance(data) : handleSign(data);
        })
        .catch(handleError);
    }
  };

  const handleTransaction = async ({
    receipt: { blockNumber, status },
    callback,
    message,
    redirect,
  }: {
    receipt: { blockNumber: number; status: boolean };
    callback: () => void;
    message: string;
    redirect?: { path: string; text: string };
  }) => {
    let currentBlockNumber;

    while (!currentBlockNumber || !(blockNumber + 2 <= currentBlockNumber)) {
      currentBlockNumber = await library.eth.getBlockNumber();

      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    if (status) {
      notify(<Toast message={message} redirect={redirect} />);
      callback();
    }
  };

  const handleSend = ({
    transaction,
    callback,
    message,
  }: {
    transaction: any;
    callback: () => void;
    message: string;
  }) => {
    disabled.onChange(id);

    transaction
      .send({ from: account.address })
      .then((receipt: { blockNumber: number; status: boolean }) => handleTransaction({ receipt, callback, message }))
      .catch(handleError);
  };

  const handleCallback = () => {
    onFetch();
    loanOffers.toggleOpen && loanOffers.toggleClick();
    onReset();
    disabled.onReset();
  };

  const handleClaim = () =>
    handleSend({
      transaction: pawnshop.claimItem(accepted_offer.signature_hash),
      callback: handleCallback,
      message: 'Item claimed',
    });

  const handleClaimAndWithdraw = () =>
    handleSend({
      transaction: pawnshop.claimAndWithdrawItem(accepted_offer.signature_hash),
      callback: handleCallback,
      message: 'Item claimed and withdrawn',
    });

  const handleCancel = async (data: LoanOfferProps) => {
    onChange((state: LoanAssetState) => ({ ...state, loader: true }));
    const offer = (await handleOffer)({ data, nonce: data.nonce });

    handleSend({
      transaction: pawnshop.cancelOffer(data.signature, adaptOfferToMessage(offer)),
      callback: handleCallback,
      message: 'Offer cancelled',
    });
  };

  const loanButton = {
    text: 'Cancel Offer',
    onClick: handleCancel,
    disabled: !!disabled.id,
  };

  const owned_active_offer = owned_offers.find(({ status }: LoanOfferProps) => status === 0);
  const owned_accepted_offer =
    accepted_offer && owned_offers.find(({ id }: LoanOfferProps) => id === accepted_offer.id);

  return (
    <>
      <Asset
        ref={loanForm.toggleRef}
        asset={asset}
        borrower_address={borrower_address}
        disabled={!!disabled.id && disabled.id === id}
        spinner={!state.loader}
      >
        <LoanStatus status={status} />
        {account.address ? (
          <StyledLoan>
            {status === 1 && <LoanMinimumValue currency_id={currency_id} minimum_value={minimum_value} />}
            {status === 2 && (
              <LoanDueTime accepted_offer={accepted_offer} loan_taken_timestamp={loan_taken_timestamp} />
            )}
            {status === 1 &&
              !owned_active_offer &&
              (loanForm.toggleOpen ? (
                <LoanOfferForm
                  currency_id={currency_id}
                  currency_amount={currency.amount}
                  // @ts-ignore
                  min_currency_profit_value={min_currency_profit.value}
                  minimum_value={minimum_value}
                  error={state.error}
                  disabled={!!disabled.id}
                  onSubmit={handleConfirm}
                />
              ) : (
                <Button text="Make Offer" onClick={loanForm.toggleClick} disabled={!!disabled.id} />
              ))}
            {status === 3 && accepted_offer && accepted_offer.status === 6 && owned_accepted_offer && (
              <StyledToggle>
                <div onClick={loanClaim.toggleClick} />
                <Button
                  text={!loanClaim.toggleOpen ? 'Claim' : 'Claim & Withdraw'}
                  onClick={!loanClaim.toggleOpen ? handleClaim : handleClaimAndWithdraw}
                />
              </StyledToggle>
            )}
            {!!owned_offers.length && (
              <Button
                text={`Show My Offers (${owned_offers.length})`}
                onClick={loanOffers.toggleClick}
                disabled={!!disabled.id || loanOffers.toggleOpen}
                outlined
              />
            )}
          </StyledLoan>
        ) : (
          <StyledPlaceholder>Please Log In to Make Offer.</StyledPlaceholder>
        )}
      </Asset>
      {loanOffers.toggleOpen && (
        <ToggleRef ref={loanOffers.toggleRef}>
          <Loader visible={state.loader} spinner />
          <OwnedLoanOffers
            currency_id={currency_id}
            offers={owned_offers}
            {...loanButton}
            onClose={loanOffers.toggleClick}
          />
        </ToggleRef>
      )}
    </>
  );
};

export default LoanAsset;
