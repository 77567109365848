import React from 'react';
import { useNetwork } from 'common/hooks';
import { StyledPlaceholder } from './styles';

const Placeholder = () => {
  const { dev, testnet } = useNetwork();

  return (
    <StyledPlaceholder>
      Your Wallet is connected to Ethereum {testnet ? 'Testnet' : 'Mainnet'}.
      <br />
      To use Hoard Marketplace please switch to
      {dev ? ' Rinkeby Testnet' : ' Mainnet'}.
    </StyledPlaceholder>
  );
};

export default Placeholder;
