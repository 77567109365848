import styled from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexAlignItemsCenterMixin, FlexMixin, SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';
import { Button } from 'common/components/bits';

export const StyledForm = styled.form`
  ${FlexMixin({ direction: flex.column })}

  margin: 2rem 1rem 1.5rem 0;

  button {
    margin: 0 0 0 auto;
  }
`;

export const StyledError = styled.span`
  width: 21rem;

  position: absolute;
  top: -2rem;

  color: ${color.lightRed};
  ${SofiaSmallMixin};
`;

export const StyledField = styled.div`
  width: 19rem;

  position: relative;

  ${FlexAlignItemsCenterMixin};

  margin: 0 0 0.7rem auto;

  input {
    max-width: 19rem;
    height: 4rem;

    border: none;
    box-shadow: inset 0 0.1rem 0.3rem 0 ${addOpacityToColor(color.black, 50)};

    padding: 0.8rem 5rem 0.8rem 0.8rem;
  }
`;

export const StyledButton = styled(Button)`
  max-width: 4rem;
  height: 3.2rem;

  position: absolute;
  right: 0.5rem;

  ${SofiaSmallMixin};
  text-transform: uppercase;

  background-color: ${color.black};

  margin: 0;

  &:hover {
    background-color: ${color.black};

    &:disabled {
      background-color: ${color.grey};
    }
  }
`;
