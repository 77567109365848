import React from 'react';
import { useForm } from 'react-hook-form';
import { current } from './values';
import { Button, type } from 'common/components/bits';
import options from './options';
import { StyledField, StyledInput } from './styles';

const Search = ({ onSearch }: { onSearch: ({ search }: { search: string }) => void }) => {
  const defaultValues = { search: '' };
  // @ts-ignore
  const { register, handleSubmit } = useForm(options({ defaultValues }));

  return (
    <form onSubmit={handleSubmit(onSearch)}>
      <StyledField>
        {/*// @ts-ignore*/}
        <StyledInput type="text" {...register(current.name)} placeholder="search..." />
        <Button type={type.submit} text="Search" small />
      </StyledField>
    </form>
  );
};

export default Search;
