import React from 'react';
import { Col, Row } from 'react-grid-system';
import { StakeCardProps } from './types';
import { style } from 'styles/enums';
import { Loader } from 'common/components/bits';
import {
  StyledChildren,
  StyledCol,
  StyledDetails,
  StyledInfo,
  StyledStakeCard,
  StyledTitle,
  StyledWarning,
} from './styles';

const StakeCard = ({ children, balance, image, title, info, warning, disabled }: StakeCardProps) => (
  <StyledStakeCard>
    <Loader visible={disabled} spinner />
    <Row>
      <StyledCol xs={2}>
        <img src={image} alt={title} />
      </StyledCol>
      <Col xs={6}>
        <StyledDetails>
          <div>
            <StyledTitle>{title}</StyledTitle>
            <StyledInfo>{info}</StyledInfo>
          </div>
          {warning && (
            <StyledWarning>
              <span className={style.warning}>WARNING: </span>
              {warning}
            </StyledWarning>
          )}
          {balance}
        </StyledDetails>
      </Col>
      <Col xs={4}>
        <StyledChildren>{children}</StyledChildren>
      </Col>
    </Row>
  </StyledStakeCard>
);

export default StakeCard;
