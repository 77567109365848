import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';
import { color } from 'styles/enums';
import { FlexColumnCenterMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledLendTab = styled.div<{ height: number | undefined }>`
  min-height: ${({ height }) => (height ? `${height + 10}rem` : 'calc(100% - 10rem)')};

  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 2rem 3rem 3rem;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 2rem;
`;

export const StyledCol = styled(Col)<{ height: number | undefined }>`
  max-height: ${({ height }) => (height ? `${height + 10}rem` : 'calc(100% - 10rem)')};

  position: sticky !important;
  top: 20rem;

  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 2rem !important;
`;

export const StyledPlaceholder = styled.div<{ height: number | undefined }>`
  min-height: ${({ height }) => (height ? `${height - 8.4}rem` : '100%')};

  ${FlexColumnCenterMixin};

  text-align: center;

  padding-bottom: 10rem;
`;
