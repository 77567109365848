import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';
import { color } from 'styles/enums';
import { FlexColumnCenterMixin, SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledFlashTab = styled.div<{ height: number | undefined }>`
  min-height: ${({ height }) => (height ? `${height + 10}rem` : 'calc(100% - 10rem)')};

  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 2rem 3rem 3rem;
`;

export const StyledRow = styled(Row)`
  height: 2.5rem;

  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
`;

export const StyledCol = styled(Col)<{ height: number | undefined }>`
  max-height: ${({ height }) => (height ? `${height + 4}rem` : 'calc(100% - 4)')};

  position: sticky !important;
  top: 20rem;

  background-color: ${addOpacityToColor(color.black, 45)};

  padding: 2rem !important;
`;

export const StyledPlaceholder = styled.div<{ height: number | undefined }>`
  min-height: ${({ height }) => (height ? `${height - 8.4}rem` : '100%')};

  ${FlexColumnCenterMixin};

  text-align: center;

  padding-bottom: 10rem;
`;

export const StyledCode = styled.div`
  height: 23rem;
  width: 50rem;

  position: relative;

  ${SofiaSmallMixin};
  text-align: left;
  white-space: pre;

  border: 0.1rem solid ${color.grey};
  background-color: ${addOpacityToColor(color.white, 5)};

  margin: 2rem auto 0;
  padding: 2rem;
`;
