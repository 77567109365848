import React from 'react';
import { useSelector } from 'react-redux';
import { AcceptedLoanOfferProps } from './types';
import { PlatformFee } from 'common/components/assets';
import { Tooltip, tooltip } from 'common/components/bits';
import { useCurrency } from 'common/hooks';
import { parseDecimalPlaces, parseFromDecimal, parseTimesDecimal } from 'common/utils/parsers';
import { selectPawnshop } from 'app/App/slice';
import { StyledAcceptedLoanOffer, StyledCol, StyledLabel, StyledLoanConst } from './styles';

const AcceptedLoanOffer = ({ currency_id, offer }: AcceptedLoanOfferProps) => {
  const {
    platform: { deposit_fee, redemption_fee },
  } = useSelector(selectPawnshop);
  const currency_deposit_fee = deposit_fee.find(({ id }) => id === currency_id) || { value: 0 };
  const currency_redemption_fee = redemption_fee.find(({ id }) => id === currency_id) || { value: 0 };

  const handleCurrency = useCurrency();
  const decimal = handleCurrency(currency_id).decimal;

  const loan_value = parseFromDecimal(offer.loan_value, decimal);
  const loan_value_platform_fee = parseDecimalPlaces(
    // @ts-ignore
    parseTimesDecimal(offer.loan_value, decimal, currency_deposit_fee.value, false),
  );
  const repayment_value = parseDecimalPlaces(parseFromDecimal(offer.repayment_value, decimal, 6));
  const repayment_value_platform_fee = parseTimesDecimal(offer.repayment_value, decimal, currency_redemption_fee.value);

  const tooltipDepositFee = {
    [tooltip.for]: `${offer.id}_accepted_deposit`,
    [tooltip.tip]: `Reduced by ${currency_deposit_fee.value * 100}% platform fee`,
  };

  const tooltipRedemptionFee = {
    [tooltip.for]: `${offer.id}_accepted_redemption`,
    [tooltip.tip]: `Increased by ${currency_redemption_fee.value * 100}% platform fee`,
  };

  return (
    <StyledAcceptedLoanOffer>
      <span>Accepted offer</span>
      <StyledCol>
        <div>
          <StyledLabel>Loan value: </StyledLabel>
          <span>
            {loan_value} {handleCurrency(currency_id).name}
          </span>
        </div>
        {currency_deposit_fee.value > 0 && (
          <>
            <PlatformFee {...tooltipDepositFee}>
              {loan_value_platform_fee} {handleCurrency(currency_id).name}
            </PlatformFee>
            <Tooltip id={tooltipDepositFee[tooltip.for]} />
          </>
        )}
      </StyledCol>
      <StyledCol>
        <div>
          <StyledLabel>Repayment: value </StyledLabel>
          <span>
            {repayment_value} {handleCurrency(currency_id).name}
          </span>
        </div>
        <StyledLoanConst loan_value={loan_value} repayment_value={repayment_value} />
        {currency_redemption_fee.value > 0 && offer.status !== 6 && (
          <>
            <PlatformFee {...tooltipRedemptionFee}>
              {repayment_value_platform_fee} {handleCurrency(currency_id).name}
            </PlatformFee>
            <Tooltip id={tooltipRedemptionFee[tooltip.for]} />
          </>
        )}
      </StyledCol>
    </StyledAcceptedLoanOffer>
  );
};

export default AcceptedLoanOffer;
