import styled from 'styled-components';
import { Container } from 'react-grid-system';
import { FixedContainerMixin, FlexMixin } from 'styles/mixins';

export const StyledPageLayout = styled.div<{ unset?: boolean }>`
  min-width: 100%;
  min-height: 100%;

  ${FlexMixin({})};

  padding-top: 20rem;
`;

export const StyledContainer = styled(Container)`
  ${FixedContainerMixin};

  min-height: calc(100vh - 20rem);
`;
