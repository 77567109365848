import React, { FormEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PaginationProps } from './types';
import { current } from './values';
import options from './options';
import { StyledPagination } from './styles';

const Pagination = ({ page, page_count, onPageChange }: PaginationProps) => {
  const location = useLocation();
  const defaultValues = { page };
  const validate = { page_count };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    // @ts-ignore
  } = useForm(options({ defaultValues, validate }));

  const selected = watch(current.name);

  useEffect(() => {
    handleSubmit((data) => onPageChange(data.page))();
  }, [selected]);

  useEffect(() => {
    reset();
  }, [location.pathname]);

  const handlePreventDefault = (e: FormEvent) => e.preventDefault();

  return (
    <StyledPagination>
      <form onSubmit={handlePreventDefault} autoComplete="off">
        <input
          type="number"
          {...register(current.name, { valueAsNumber: true })}
          disabled={page_count === 1}
          min={1}
          max={page_count}
          step={1}
          onKeyDown={handlePreventDefault}
        />
      </form>
      <span>of</span>
      {page_count}
    </StyledPagination>
  );
};

export default Pagination;
