import React from 'react';
import { useSelector } from 'react-redux';
import { FlashLoanProps } from 'features/loans/types';
import { FlashLoanOffersProps } from './types';
import { loanValue } from 'features/loans/components/FlashTab/components/FlashOfferForm/values';
import { fromUnixTime } from 'date-fns';
import { PlatformFee } from 'common/components/assets';
import { Button, HyperLink, tooltip, Tooltip } from 'common/components/bits';
import { LoanCost } from 'features/loans/components';
import { useCurrency, useNetwork } from 'common/hooks';
import {
  parseDecimalPlaces,
  parseFromDecimal,
  parseTimesDecimal,
  parseToDecimal,
  parseToString,
} from 'common/utils/parsers';
import { renderDate, renderShortAddress } from 'common/utils/presenters';
import { selectPawnshop } from 'app/App/slice';
import { StyledCol, StyledFlashLoanOffers, StyledFlashLoanOffersHeader } from './styles';

const FlashLoanOffers = ({ offers, text, onClick, disabled }: FlashLoanOffersProps) => {
  const {
    platform: { flash_fee },
  } = useSelector(selectPawnshop);

  const handleCurrency = useCurrency();

  const { testnet } = useNetwork();

  return (
    <div style={{ marginBottom: '3rem' }}>
      <StyledFlashLoanOffersHeader>
        <span>User</span>
        <span>Loan value</span>
        <span>Repayment value</span>
        <span>Offer due</span>
        <span />
      </StyledFlashLoanOffersHeader>
      <StyledFlashLoanOffers>
        {offers.map((offer: FlashLoanProps) => {
          const name = handleCurrency(offer.currency_id).name;
          const decimal = handleCurrency(offer.currency_id).decimal;
          const currency_flash_fee = flash_fee.find(({ id }) => id === offer.currency_id) || { value: 0 };

          const loan_value = parseFromDecimal(offer.loan_value, decimal);
          const loan_value_flash_fee = parseTimesDecimal(offer.loan_value, decimal, currency_flash_fee.value);
          const repayment_value = parseDecimalPlaces(parseFromDecimal(offer.repayment_value, decimal, 6));
          // @ts-ignore
          const loan_cost = parseFloat(parseToDecimal(loan_value_flash_fee, decimal)) - parseFloat(offer.loan_value);
          const total_to_repay = parseDecimalPlaces(
            parseFromDecimal(parseToString(parseFloat(offer.repayment_value) + loan_cost), decimal, 6),
          );

          const tooltipFlashFee = {
            [tooltip.for]: `${offer.id}_flash_deposit`,
            [tooltip.tip]: `Increased by platform fee (${currency_flash_fee.value * 100}% of ${loanValue.label})`,
          };

          const address_href = `https://${testnet ? 'rinkeby.' : ''}etherscan.io/address/${offer.lender_address}`;

          return (
            <div key={offer.id}>
              <HyperLink href={address_href} text={renderShortAddress(offer.lender_address)} address />
              <StyledCol>
                <span>
                  {loan_value} {name}
                </span>
              </StyledCol>
              <StyledCol>
                <span>
                  {repayment_value} {name}
                </span>
                <LoanCost loan_value={loan_value} repayment_value={repayment_value} />
                {currency_flash_fee.value > 0 && (
                  <>
                    <PlatformFee {...tooltipFlashFee}>
                      {total_to_repay} {name}
                    </PlatformFee>
                    <Tooltip id={tooltipFlashFee[tooltip.for]} />
                  </>
                )}
              </StyledCol>
              <span>{renderDate(fromUnixTime(offer.expiration_time))}</span>
              <div>
                <Button text={text} onClick={() => onClick(offer)} disabled={disabled} small />
              </div>
            </div>
          );
        })}
      </StyledFlashLoanOffers>
    </div>
  );
};

export default FlashLoanOffers;
