import styled, { css } from 'styled-components';
import { ButtonProps } from './types';
import { color } from 'styles/enums';
import { SofiaLargerMixin, SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledButton = styled.button<Partial<ButtonProps>>`
  width: 22rem;
  max-height: 4rem;

  color: ${color.white};
  ${SofiaLargerMixin};

  background-color: ${color.red};
  border: 0.2rem solid transparent;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
  padding: 0.4rem;

  &:hover {
    background-color: ${color.lightRed};
  }

  &:disabled {
    background-color: ${color.grey};

    cursor: not-allowed;
  }

  ${({ ghost }) =>
    ghost &&
    css`
      background-color: ${addOpacityToColor(color.black, 50)};

      &:hover {
        background-color: ${addOpacityToColor(color.black, 70)};
      }
    `};

  ${({ outlined }) =>
    outlined &&
    css`
      background-color: transparent;
      border: 0.2rem solid ${color.red};

      &:hover {
        background-color: transparent;
        border-color: ${color.lightRed};
      }

      &:disabled {
        background-color: transparent;
        border-color: ${color.grey};

        cursor: not-allowed;
      }
    `};

  ${({ short }) =>
    short &&
    css`
      max-width: 19rem;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 9rem;
      height: 3.6rem;

      ${SofiaSmallMixin};

      margin: 0;
      padding: 0.1rem 0.2rem;
    `};
`;
