import { isNull } from 'lodash';
import { Response } from './types';

const onFulfilled = (response: Response) => ({
  ok: response.ok,
  body: isNull(response.body) ? {} : response.body,
  text: response.text,
});

export default onFulfilled;
