import { useCallback, useEffect, useRef } from 'react';

const useOutsideClick = (callback: () => void) => {
  const toggleRef = useRef();

  const handleOutsideClick = useCallback(
    (e) => {
      // @ts-ignore
      if (toggleRef.current && toggleRef.current.contains(e.target)) return;
      callback();
    },
    [callback],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return toggleRef;
};

export default useOutsideClick;
