import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ceil } from 'lodash';
import { FiltersData } from './types';
import { useState } from 'common/hooks';
import { parseToString } from 'common/utils/parsers';
import { selectContracts } from 'app/App/slice';

export const useFilters = () => {
  const contracts = useSelector(selectContracts);

  const ref = useRef(null);

  const { state: height, onChange: onSetHeight } = useState(0);

  useEffect(() => {
    // @ts-ignore
    const height = ceil(ref.current && ref.current.getBoundingClientRect().height / 10);

    height > 0 && onSetHeight(height);
  }, [ref.current]);

  const multi_contracts = contracts.map(({ id }: { id: number }) => parseToString(id));

  const defaultValues = { multi_contracts };

  const { state, onChange } = useState({ multi_contracts });

  const handleFilter = (value: FiltersData) => onChange(value);

  return {
    state,
    defaultValues,
    onFilter: handleFilter,
    ref,
    height,
  };
};
