import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { LoanProps } from 'features/loans/types';
import { nfts, path } from 'app/Router';
import {
  Filters,
  Pagination,
  queryBuild,
  Search,
  Sorting,
  StatusForm,
  useFilters,
  usePagination,
  useSearch,
  useSorting,
  useStatusForm,
} from 'common/components/assets';
import { OwnedLoanAsset } from './components';
import { useState } from 'common/hooks';
import dispatchRequest from 'common/utils/dispatchRequest';
import { parseToString } from 'common/utils/parsers';
import { selectCategories, selectContracts } from 'app/App/slice';
import { selectAccount } from 'features/accounts/slice';
import { borrowLoansFetchRequest, selectBorrowLoans } from 'features/loans/slice';
import { actions, options } from './borrow';
import { StyledBorrowTab, StyledCol, StyledPlaceholder, StyledRow } from './styles';

const BorrowTab = () => {
  const account = useSelector(selectAccount);
  const categories = useSelector(selectCategories);
  const contracts = useSelector(selectContracts);
  const { count, loans } = useSelector(selectBorrowLoans);

  const filters = useFilters();
  const pagination = usePagination({ count });
  const search = useSearch();
  const sorting = useSorting({ defaultOption: options[4], options });
  const statusForm = useStatusForm({ actions });

  const query = queryBuild({
    page: parseToString(pagination.page),
    exclude: '4,5' + statusForm.state,
    ordering: sorting.state,
    search: search.state,
    ...filters.state,
  });

  const { state: id, ...disabled } = useState(undefined);

  const handleFetch = () =>
    dispatchRequest({
      request: borrowLoansFetchRequest,
      values: { query },
    });

  useEffect(() => {
    account.address && !!contracts.length && handleFetch();
  }, [account, contracts, query]);

  return (
    <Row nogutter>
      <StyledCol xs={2} height={filters.height}>
        <Search {...search} />
        {(!!categories.length || !!contracts.length) && <Filters {...filters} />}
      </StyledCol>
      <Col xs={10}>
        <StyledBorrowTab height={filters.height}>
          <>
            <StyledRow>
              <Col xs={6}>
                <Sorting {...sorting} />
              </Col>
              <Col xs={3} offset={{ xs: 3 }}>
                {count > 0 && <Pagination {...pagination} />}
              </Col>
            </StyledRow>
            <StatusForm {...statusForm} />
            {!account.address ? (
              <StyledPlaceholder height={43.9}>Please Log In to start trading.</StyledPlaceholder>
            ) : !!loans.length ? (
              loans.map((loan: LoanProps) => (
                <OwnedLoanAsset key={loan.id} {...loan} onFetch={handleFetch} disabled={{ id, ...disabled }} />
              ))
            ) : (
              <StyledPlaceholder height={filters.height}>
                No assets for collateral.
                <br />
                <br />
                Deposit some NFT tokens or change filters.
                <br />
                <br />
                <Link to={path.nfts}>{nfts.goTo}</Link>
              </StyledPlaceholder>
            )}
          </>
        </StyledBorrowTab>
      </Col>
    </Row>
  );
};

export default BorrowTab;
