import styled from 'styled-components';
import { color } from 'styles/enums';
import { SofiaRegularMixin, SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';
import HyperLink from 'common/components/bits/HyperLink';

export const StyledTutorialTab = styled.div`
  a {
    color: ${color.white};
    ${SofiaRegularMixin};
  }

  ul {
    margin-bottom: 2rem;
  }

  .accordion {
    background-color: ${addOpacityToColor(color.black, 45)};
  }

  .accordion__item {
    border-bottom: 0.1rem solid ${addOpacityToColor(color.white, 20)};

    &:last-of-type {
      border-bottom-color: transparent;
    }
  }

  .accordion__button {
    width: 100%;

    text-align: left;

    border: none;

    padding: 2rem;

    cursor: pointer;

    &:hover {
      background-color: ${addOpacityToColor(color.black, 30)};
    }

    &:before {
      content: '';
      height: 0.8rem;
      width: 0.8rem;

      display: inline-block;

      border-bottom: 0.2rem solid ${color.white};
      border-right: 0.2rem solid ${color.white};

      margin-right: 1.2rem;

      transform: rotate(-45deg);
    }
  }

  .accordion__button[aria-expanded='true'] {
    border-bottom: 0.1rem solid ${addOpacityToColor(color.white, 20)};
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    padding: 2rem;

    animation: fadein 0.35s ease-in;

    > div:first-of-type {
      margin-bottom: 1.6rem;
      padding-bottom: 2.4rem;
    }
  }

  .accordion__ghost {
    margin: -2rem;

    .accordion__button {
      background-color: ${addOpacityToColor(color.black, 25)};

      &:hover {
        background-color: ${addOpacityToColor(color.black, 35)};
      }
    }
  }
`;

export const StyledImage = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  padding-right: 5rem;

  img {
    width: 90rem;
    margin: 2rem auto 2rem 0;
  }
`;

export const StyledCode = styled.div`
  width: 57rem;

  position: relative;

  ${SofiaSmallMixin};
  text-align: left;
  white-space: pre;

  border: 0.1rem solid ${color.grey};
  background-color: ${addOpacityToColor(color.white, 5)};

  margin: 2rem 0 3rem;
  padding: 2rem;
`;

export const StyledHyperLink = styled(HyperLink)`
  color: ${color.lightRed} !important;
  font-size: 1.3rem !important;
`;
