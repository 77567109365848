import styled from 'styled-components';
import { Container } from 'react-grid-system';
import { color } from 'styles/enums';
import { FixedContainerMixin, FlexColumnCenterMixin, SofiaLargestMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledPlaceholder = styled(Container)`
  ${FixedContainerMixin};
  ${FlexColumnCenterMixin};

  min-height: 22.8rem;
  height: 60vh !important;

  ${SofiaLargestMixin};
  text-align: center;

  background-color: ${addOpacityToColor(color.black, 45)};

  margin-top: 20rem;
  padding: 1rem 2rem 5rem 2rem;
`;
