import styled from 'styled-components';
import close from './images/close.svg';

export const StyledButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 4rem;
  height: 4rem;

  align-self: flex-end;

  background-color: transparent;
  background-image: url(${close});
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 50%;

  margin: 0;
  padding: 0;

  cursor: pointer;
`;
