export enum minimumValue {
  name = 'minimum_value',
  label = 'Minimal offer',
}

export enum currencyId {
  name = 'currency_id',
}

export enum confirm {
  label = 'Confirm Collateral',
}
