import styled, { css } from 'styled-components';
import { flex } from 'styles/enums';
import { FlexMixin } from 'styles/mixins';

export const StyledToast = styled.div<{ error: number | { transactionHash: string } | undefined; warning?: string }>`
  position: relative;

  ${FlexMixin({
    direction: flex.column,
  })};

  line-height: 2rem;

  padding-left: 2.6rem;

  :before {
    content: '🟢';
    height: 2rem;
    width: 2rem;

    position: absolute;
    top: 0;
    left: 0;

    ${({ warning }) =>
      warning &&
      css`
        content: '🟡';
      `};

    ${({ error }) =>
      error &&
      css`
        content: '🔴';
      `};
  }
`;
