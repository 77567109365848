import { css } from 'styled-components';
import { Flex } from '../types/flex';
import { flex } from 'styles/enums';

export const FlexMixin = (flex: Flex) => css`
  display: flex;
  flex-direction: ${flex.direction};
  justify-content: ${flex.justifyContent};
  align-items: ${flex.alignItems};
  flex-wrap: ${flex.flexWrap};
`;

export const FlexColumnCenterMixin = css`
  ${FlexMixin({
    direction: flex.column,
    justifyContent: flex.center,
    alignItems: flex.center,
  })};
`;

export const FlexRowCenterMixin = css`
  ${FlexMixin({
    justifyContent: flex.center,
    alignItems: flex.center,
  })};
`;

export const FlexAlignItemsCenterMixin = css`
  ${FlexMixin({
    alignItems: flex.center,
  })};
`;
