const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
];

const EIP712Offer = [
  { name: 'nonce', type: 'uint32' },
  { name: 'expirationTime', type: 'uint40' },
  { name: 'loanTokenAddress', type: 'address' },
  { name: 'collateralItem', type: 'Item' },
  { name: 'loanParams', type: 'LoanParams' },
];

const EIP712Item = [
  { name: 'tokenAddress', type: 'address' },
  { name: 'tokenId', type: 'uint256' },
  { name: 'depositTimestamp', type: 'uint256' },
];

const EIP712LoanParams = [
  { name: 'itemValue', type: 'uint256' },
  { name: 'redemptionPrice', type: 'uint256' },
  { name: 'timelockPeriod', type: 'uint32' },
];

export const primaryType = 'Offer';

export const types = {
  EIP712Domain,
  Offer: EIP712Offer,
  Item: EIP712Item,
  LoanParams: EIP712LoanParams,
};
