import React from 'react';
import { HyperLink } from 'common/components/bits';
import { StyledSupportTab } from './styles';

const SupportTab = () => (
  <StyledSupportTab>
    Please join our Discord channel if you need further help.
    <br />
    <br />
    <HyperLink href={'https://discord.com/invite/8q6JBsVrUF'} text="Go to Hoard Discord" />
  </StyledSupportTab>
);

export default SupportTab;
