import styled from 'styled-components';
import { Container } from 'react-grid-system';
import { color } from 'styles/enums';
import { FixedContainerMixin, FlexAlignItemsCenterMixin } from 'styles/mixins';

export const StyledHeader = styled.header`
  height: 10rem;

  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  background-color: ${color.black};

  @media (max-width: 1139px) {
    height: 15rem;
  }
`;

export const StyledContainer = styled(Container)`
  ${FixedContainerMixin};

  height: 10rem;

  ${FlexAlignItemsCenterMixin};

  > div {
    width: 100%;

    > div {
      ${FlexAlignItemsCenterMixin};
    }
  }

  @media (max-width: 1139px) {
    min-width: unset !important;
    height: 15rem;
  }
`;

export const StyledLogo = styled.img`
  height: 4rem;

  margin: 2rem 0;

  @media (max-width: 1139px) {
    height: 3rem;
  }
`;
