import { css } from 'styled-components';
import { color } from 'styles/enums';

export const FixedContainerMixin = css`
  min-width: 114rem !important;
  max-width: 114rem !important;
`;

export const TruncateMixin = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HyperlinkMixin = css`
  color: ${color.white};
  font-size: 1.3rem;
  text-decoration: underline;
`;
