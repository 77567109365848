export const adaptLoanOfferStatus = (status: number) => {
  switch (status) {
    case 0:
      return 'Awaiting approval';
    case 1:
      return 'Expired';
    case 2:
      return 'In progress';
    case 3:
      return 'Cancelled';
    case 4:
      return 'Rejected';
    case 5:
      return 'Repaid';
    case 6:
      return 'Overdue';
    default:
      return 'Not known';
  }
};
