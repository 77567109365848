import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastProps } from 'common/components/bits/Toast/types';
import { Balance } from 'common/hooks/useBalance/types';
import { Currency } from 'common/hooks/useCurrency/types';
import { StakePageState } from './types';
import { StakeFormData } from 'features/stake/components/StakeForm/types';
import { UnstakeFormData } from 'features/stake/components/UstakeForm/types';
import { action } from './action';
import { Button, HyperLink, notify, Toast } from 'common/components/bits';
import { StakeCard, StakeForm, UnstakeForm } from 'features/stake/components';
import { PageLayout } from 'common/components/layouts';
import { ToggleRef, useBalance, useContract, useNetwork, useState, useToggle } from 'common/hooks';
import { max } from 'common/utils/library';
import { parseFromDecimal, parseToDecimal } from 'common/utils/parsers';
import usePawnshop from 'contracts/pawnshop';
import { selectAddress, selectCurrencies } from 'app/App/slice';
import { selectAccount, selectAccountCountryFlag } from 'features/accounts/slice';
import { ABI } from './contract';
import { claim, stake, unstake } from './images';
import { StyledPlaceholder, StyledStakeBalance, StyledStakePage } from './styles';

const stakeActionProps = {
  image: stake,
  title: 'Stake Tokens',
  info:
    'Stake your HRD derivative tokens (ETHHRD UNIV2) and claim rewards in crypto currencies supported on the Marketplace. You receive a percentage of each rewards pool proportional to your staking contributions.',
  warning:
    'Direct transfer to the Marketplace contracts will not create stake! Retrieving tokens sent directly to the Marketplace contract might be difficult!',
};

const unstakeActionProps = {
  image: unstake,
  title: 'Unstake Tokens',
  info:
    'You can withdraw your stake anytime you want. The longer you keep your tokens staked the more rewards you receive.',
};

const claimActionProps = {
  image: claim,
  title: 'Claim Rewards',
  info: 'Claim your rewards. Your rewards will be transferred to your MetaMask wallet.',
};

const StakePage = () => {
  const account = useSelector(selectAccount);
  const address = useSelector(selectAddress);
  const currencies = useSelector(selectCurrencies);
  const country_flag = useSelector(selectAccountCountryFlag);

  const handleBalance = useBalance();
  const handleContract = useContract({ ABI });
  const { library } = useNetwork();
  const pawnshop = usePawnshop();

  const { state: token, onChange: onChangeToken } = useState({
    id: 0,
    name: 'HRDETHUniV2',
    address: '',
    decimal: 18,
  });

  useEffect(() => {
    !!library &&
      !!address.pawnshop.length &&
      pawnshop
        .stakingToken()
        .call()
        .then((result: string) => onChangeToken({ ...token, address: result }));
  }, [address.pawnshop]);

  const { state, onChange } = useState({
    stake_token: 0,
    staked_amount: 0,
    claimable_rewards: [],
    loader: '',
  });

  const rewards = !!state.claimable_rewards.filter(({ amount }: { amount: string }) => parseFloat(amount) > 0).length;

  const depositForm = useToggle({ outsideClick: true });
  const withdrawForm = useToggle({ outsideClick: true });

  const handleStakeTokenBalance = () =>
    handleContract(token.address)
      .methods.balanceOf(account.address)
      .call()
      .then((result: string) => {
        const amount = parseFromDecimal(result, token.decimal);

        onChange((state: StakePageState) => ({
          ...state,
          stake_token: amount,
        }));
      });

  const handleStakedAmountBalance = () =>
    pawnshop
      .stakedAmount(account.address)
      .call()
      .then((result: string) => {
        const amount = parseFromDecimal(result, token.decimal);

        onChange((state: StakePageState) => ({
          ...state,
          staked_amount: amount,
        }));
      });

  const handleClaimableRewardsBalance = () =>
    !!currencies.length && state.claimable_rewards.length === currencies.length
      ? currencies.forEach((currency: Currency) => {
          pawnshop
            .claimableReward(account.address, currency.address)
            .call()
            .then((result: string) => {
              const amount = parseFromDecimal(result, token.decimal);

              onChange((state: StakePageState) => ({
                ...state,
                claimable_rewards: state.claimable_rewards.map((token: Currency) =>
                  token.id === currency.id ? { ...token, amount } : token,
                ),
              }));
            });
        })
      : currencies.forEach((currency: Currency) => {
          pawnshop
            .claimableReward(account.address, currency.address)
            .call()
            .then((result: string) => {
              const amount = parseFromDecimal(result, token.decimal);

              onChange((state: StakePageState) => ({
                ...state,
                claimable_rewards: [...state.claimable_rewards, { id: currency.id, name: currency.name, amount }],
              }));
            });
        });

  const handleStakeBalance = () => {
    handleStakeTokenBalance();
    handleStakedAmountBalance();
    handleClaimableRewardsBalance();
  };

  const handleRewardsBalance = () => {
    handleClaimableRewardsBalance();
    handleBalance();
  };

  useEffect(() => {
    account.address && !!address.pawnshop.length && !!token.address.length && handleStakeBalance();
  }, [account, address.pawnshop, currencies, token]);

  const handleTransaction = async ({
    receipt: { blockNumber, status },
    callback,
    message,
    redirect,
  }: {
    receipt: { blockNumber: number; status: boolean };
    callback: () => void;
    message: string;
    redirect?: { path: string; text: string };
  }) => {
    let currentBlockNumber;

    while (!currentBlockNumber || !(blockNumber + 2 <= currentBlockNumber)) {
      currentBlockNumber = await library.eth.getBlockNumber();

      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    if (status) {
      notify(<Toast message={message} redirect={redirect} />);
      callback();
    }
  };

  const handleSend = ({
    transaction,
    callback,
    message,
    loader,
  }: {
    transaction: any;
    callback: () => void;
    message: string;
    loader: string;
  }) => {
    onChange((state: StakePageState) => ({ ...state, loader }));

    transaction
      .send({ from: account.address })
      .then((receipt: { blockNumber: number; status: boolean }) => handleTransaction({ receipt, callback, message }))
      .catch((error: ToastProps) => notify(<Toast {...error} />))
      .finally(() => onChange((state: StakePageState) => ({ ...state, loader: '' })));
  };

  const handleStake = (data: StakeFormData) => {
    const staked_amount = parseToDecimal(data.staked_amount, token.decimal);

    handleSend({
      transaction: pawnshop.stake(staked_amount),
      callback: handleStakeBalance,
      message: 'Tokens staked',
      loader: action.stake,
    });

    depositForm.toggleClick();
  };

  const handleAllowance = (data: StakeFormData) => {
    onChange((state: StakePageState) => ({ ...state, loader: action.stake }));

    handleContract(token.address)
      .methods.approve(address.pawnshop, max)
      .send({ from: account.address })
      .then(() => {
        notify(<Toast message={token.name + ' allowance increased to maximum'} />);
        handleStake(data);
      })
      .catch((error: ToastProps) => {
        notify(<Toast {...error} />);
        onChange((state: StakePageState) => ({ ...state, loader: '' }));
      });
  };

  const handleConfirm = (data: StakeFormData) =>
    handleContract(token.address)
      .methods.allowance(account.address, address.pawnshop)
      .call()
      .then((result: string) => {
        const allowance = parseFromDecimal(result, token.decimal);

        return parseFloat(allowance) < parseFloat(data.staked_amount) ? handleAllowance(data) : handleStake(data);
      })
      .catch((error: ToastProps) => notify(<Toast {...error} />));

  const handleUnstake = (data: UnstakeFormData) => {
    const unstaked_amount = parseToDecimal(data.unstaked_amount, token.decimal);

    handleSend({
      transaction: pawnshop.unstake(unstaked_amount),
      callback: handleStakeBalance,
      message: 'Withdrawn succeeded',
      loader: action.unstake,
    });

    withdrawForm.toggleClick();
  };

  const handleClaim = () =>
    handleSend({
      transaction: pawnshop.claimRewards(),
      callback: handleRewardsBalance,
      message: 'Rewards claimed',
      loader: action.claim,
    });

  return (
    <PageLayout>
      {country_flag === 'US' ? (
        <StyledPlaceholder>
          We detect that you are in the USA.
          <br />
          Please note that the Stake functionality is not available for users in the USA.
        </StyledPlaceholder>
      ) : (
        !!country_flag.length && (
          <StyledStakePage>
            <StakeCard {...stakeActionProps} disabled={state.loader === action.stake}>
              {account.address && (
                <ToggleRef ref={depositForm.toggleRef}>
                  <StyledStakeBalance>
                    <span>
                      Your Balance: {state.stake_token}{' '}
                      <HyperLink
                        href="https://app.uniswap.org/#/add/v2/ETH/0xC617D51E3a1f621dA8aE67b2f652d6aC02Eb8D95"
                        text="ETHHRD UNIV2"
                      />
                    </span>
                  </StyledStakeBalance>
                  {depositForm.toggleOpen ? (
                    <StakeForm stake_token={state.stake_token} onSubmit={handleConfirm} disabled={state.loader} />
                  ) : (
                    <Button text={action.stake} onClick={depositForm.toggleClick} disabled={state.loader} short />
                  )}
                </ToggleRef>
              )}
            </StakeCard>
            <StakeCard {...unstakeActionProps} disabled={state.loader === action.unstake}>
              {account.address && (
                <ToggleRef ref={withdrawForm.toggleRef}>
                  <StyledStakeBalance>
                    <span>
                      Your Stake: {state.staked_amount}{' '}
                      <HyperLink
                        href="https://app.uniswap.org/#/add/v2/ETH/0xC617D51E3a1f621dA8aE67b2f652d6aC02Eb8D95"
                        text="ETHHRD UNIV2"
                      />
                    </span>
                  </StyledStakeBalance>
                  {withdrawForm.toggleOpen ? (
                    <UnstakeForm staked_amount={state.staked_amount} onSubmit={handleUnstake} disabled={state.loader} />
                  ) : (
                    <Button text={action.unstake} onClick={withdrawForm.toggleClick} disabled={state.loader} short />
                  )}
                </ToggleRef>
              )}
            </StakeCard>
            <StakeCard
              {...claimActionProps}
              balance={
                account.address && (
                  <div>
                    <StyledStakeBalance row>
                      <span>Your rewards:</span>
                      {state.claimable_rewards.map(({ id, name, amount }: Balance) => (
                        <div key={id}>
                          {amount} {name}
                        </div>
                      ))}
                    </StyledStakeBalance>
                  </div>
                )
              }
              disabled={state.loader === action.claim}
            >
              {account.address && (
                <div style={{ paddingTop: '9.4rem' }}>
                  <Button text={action.claim} onClick={handleClaim} disabled={state.loader || !rewards} short />
                </div>
              )}
            </StakeCard>
          </StyledStakePage>
        )
      )}
    </PageLayout>
  );
};

export default StakePage;
