import styled from 'styled-components';
import { SofiaLargeMixin } from 'styles/mixins';
import { color } from 'styles/enums';

export const StyledChildren = styled.div`
  max-width: 100%;

  text-align: center;
  margin: 2rem 0;
  padding: 1rem 9rem;

  a {
    color: ${color.white};
    ${SofiaLargeMixin};
  }
`;
