import React, { forwardRef, LegacyRef, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isEqual, mapValues } from 'lodash';
import { FiltersProps } from './types';
import { Button, type } from 'common/components/bits';
import { Currencies } from './components';
import { usePrevious } from 'common/hooks';
import options from './options';
import { StyledFilters } from './styles';

const Filters = forwardRef(({ defaultValues, onFilter }: FiltersProps, ref: LegacyRef<any>) => {
  const prev = usePrevious(defaultValues);
  // @ts-ignore
  const { control, handleSubmit, setValue, ...methods } = useForm(options({ defaultValues }));

  useEffect(() => {
    // @ts-ignore
    !isEqual(prev, defaultValues) && mapValues(defaultValues, (value: string[], key: string) => setValue(key, value));
  }, [defaultValues]);

  return (
    // @ts-ignore
    <StyledFilters ref={ref}>
      <FormProvider control={control} handleSubmit={handleSubmit} setValue={setValue} {...methods}>
        <form onSubmit={handleSubmit(onFilter)}>
          <Currencies />
          <Button type={type.submit} text="Apply Filters" small />
        </form>
      </FormProvider>
    </StyledFilters>
  );
});

export default Filters;
