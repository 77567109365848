import React from 'react';
import { useSelector } from 'react-redux';
import { ToastProps } from 'common/components/bits/Toast/types';
import { FlashOfferData } from 'features/loans/hooks/useFlashOffer/types';
import { FlashOfferFormData } from '../FlashOfferForm/types';
import { Button, Loader, notify, Toast } from 'common/components/bits';
import { FlashOfferForm } from '../index';
import { useContract, useCurrency, useNetwork, useState, useToggle } from 'common/hooks';
import useFlashOffer, { adaptFlashOfferToMessage, primaryType, types } from 'features/loans/hooks/useFlashOffer';
import dispatchRequest from 'common/utils/dispatchRequest';
import { max } from 'common/utils/library';
import { parseFromDecimal, parseToDecimal } from 'common/utils/parsers';
import { selectAddress, selectDomain } from 'app/App/slice';
import { accountNonceFetchRequest, selectAccount } from 'features/accounts/slice';
import { flashLoanCreateRequest } from 'features/loans/slice';
import { ABI } from './contract';
import { StyledFlashOffer } from './styles';

const FlashOffer = ({ disabled, onFetch }: { disabled: boolean; onFetch: () => void }) => {
  const account = useSelector(selectAccount);
  const address = useSelector(selectAddress);
  const domain = useSelector(selectDomain);

  const handleContract = useContract({ ABI });
  const handleCurrency = useCurrency();
  const handleFlashOffer = useFlashOffer();
  const { library } = useNetwork();

  const loanForm = useToggle({ outsideClick: true });

  const { state, onChange, onReset } = useState({ loader: false });

  const handleSuccessSubmit = () => {
    onFetch();
    loanForm.toggleClick();
    onReset();
  };

  const handleSubmit = (data: FlashOfferData & { nonce: number; signature: string }) =>
    dispatchRequest({
      request: flashLoanCreateRequest,
      values: { data },
      onSuccess: handleSuccessSubmit,
    });

  const handleError = (error: ToastProps) => {
    notify(<Toast {...error} />);
    onReset();
  };

  const handleSign = async (data: FlashOfferFormData) => {
    const decimal = handleCurrency(data.currency_id).decimal;

    const nonce = await dispatchRequest({
      request: accountNonceFetchRequest,
      onSuccess: ({ offer_nonce }: { offer_nonce: number }) => offer_nonce,
    });

    const offer = (await handleFlashOffer)({
      data: {
        ...data,
        loan_value: parseToDecimal(data.loan_value, decimal),
        repayment_value: parseToDecimal(data.repayment_value, decimal),
      },
      nonce: nonce + 1,
    });

    // @ts-ignore
    library.eth.givenProvider.sendAsync(
      {
        method: 'eth_signTypedData_v3',
        params: [
          account.address,
          JSON.stringify({
            types,
            domain,
            primaryType,
            message: adaptFlashOfferToMessage(offer),
          }),
        ],
        from: account.address,
      },
      (error: ToastProps, { result: signature }: { result: string }) =>
        error
          ? handleError(error)
          : handleSubmit({
              currency_id: data.currency_id,
              expiration_time: offer.expirationTime,
              loan_value: offer.loanAmount,
              nonce: nonce + 1,
              repayment_value: offer.returnAmount,
              signature,
            }),
    );
  };

  const handleAllowance = (data: FlashOfferFormData) =>
    handleContract(handleCurrency(data.currency_id).address)
      .methods.approve(address.pawnshop, max)
      .send({ from: account.address })
      .then(() => {
        notify(<Toast message={handleCurrency(data.currency_id).name + ' allowance increased to maximum'} />);
        handleSign(data);
      })
      .catch(handleError);

  const handleConfirm = (data: FlashOfferFormData) => {
    onChange(() => ({ loader: true }));

    const decimal = handleCurrency(data.currency_id).decimal;

    handleContract(handleCurrency(data.currency_id).address)
      .methods.allowance(account.address, address.pawnshop)
      .call()
      .then((result: string) => {
        const allowance = parseFromDecimal(result, decimal);

        return parseFloat(allowance) < parseFloat(data.loan_value) ? handleAllowance(data) : handleSign(data);
      })
      .catch(handleError);
  };

  return (
    <StyledFlashOffer ref={loanForm.toggleRef}>
      {account.is_authenticated ? (
        loanForm.toggleOpen ? (
          <>
            <FlashOfferForm onSubmit={handleConfirm} disabled={!!state.loader} />
            <Loader visible={state.loader} spinner />
          </>
        ) : (
          <Button text="Offer Flash Loan" onClick={loanForm.toggleClick} disabled={disabled} short />
        )
      ) : (
        <span>Please Log In to Offer Flash Loan.</span>
      )}
    </StyledFlashOffer>
  );
};

export default FlashOffer;
