import styled from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexMixin, SofiaMediumMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';

export const StyledPagination = styled.div`
  ${FlexMixin({
    justifyContent: flex.flexEnd,
    alignItems: flex.center,
  })};

  ${SofiaMediumMixin};

  input {
    width: 5rem;
    height: 2.5rem;

    text-align: center;

    border: none;
    box-shadow: inset 0 0.1rem 0.3rem 0 ${addOpacityToColor(color.black, 50)};

    &:disabled {
      background-color: ${addOpacityToColor(color.white, 70)};
    }
  }

  span {
    margin: 0 1.5rem 0 1.5rem;
  }
`;
