import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputCheckboxProps } from './types';
import { parseToString } from 'common/utils/parsers';
import { StyledInputCheckbox } from './styles';

const InputCheckbox = ({ id, name, label }: InputCheckboxProps) => {
  const { register, watch } = useFormContext();

  const active = watch(name).includes(parseToString(id));

  return (
    <StyledInputCheckbox key={id} active={active}>
      <input type="radio" hidden {...register(name)} id={`${name}_${id}`} value={id} />
      <label htmlFor={`${name}_${id}`}>{label}</label>
    </StyledInputCheckbox>
  );
};

export default InputCheckbox;
