import styled, { css } from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexAlignItemsCenterMixin, FlexMixin, SofiaMediumMixin, SofiaSmallMixin } from 'styles/mixins';
import { addOpacityToColor } from 'styles/utils';
import { Button } from 'common/components/bits';

export const StyledForm = styled.form`
  ${FlexMixin({ direction: flex.column })}

  margin: 2rem 1rem 1.5rem 0;

  button {
    margin: 0 0 0 auto;
  }
`;

export const StyledError = styled.span`
  width: 21rem;

  position: absolute;
  top: 0;

  color: ${color.lightRed};
  ${SofiaSmallMixin};
`;

export const StyledField = styled.div<{ error: boolean; length: number | undefined }>`
  width: 22rem;

  position: relative;

  ${FlexAlignItemsCenterMixin};

  margin: 0 0 0.7rem auto;

  label {
    width: 30%;

    position: absolute;
    top: 0;
    left: -8rem;

    ${SofiaMediumMixin};
    text-align: right;

    overflow-wrap: normal;
  }

  input {
    max-width: 12rem;
    height: 4rem;

    border: none;
    box-shadow: inset 0 0.1rem 0.3rem 0 ${addOpacityToColor(color.black, 50)};

    padding: 0.8rem;
  }

  ${({ error, length }) =>
    error &&
    css`
      padding-top: ${length && length > 30 ? 4 : 2}rem;

      label {
        top: ${length && length > 30 ? 4 : 2}rem;
      }
    `};
`;

export const StyledSelect = styled.select`
  width: 10rem;
  height: 4rem;

  color: ${color.white};
  text-align-last: center;

  background-color: ${color.black};
  border: none;
`;

export const StyledButton = styled(Button)`
  max-width: 4rem;
  height: 3.2rem;

  position: absolute;
  right: 0.5rem;

  ${SofiaSmallMixin};
  text-transform: uppercase;

  background-color: ${color.black};
  margin: 0;

  &:hover {
    background-color: ${color.black};

    &:disabled {
      background-color: ${color.grey};
    }
  }
`;
