import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Currency } from 'common/hooks/useCurrency/types';
import { OwnedLoanFormProps } from './types';
import { confirm, currencyId, minimumValue } from './values';
import { Button, type } from 'common/components/bits';
import { InputNumber } from 'common/components/form';
import { selectCurrencies } from 'app/App/slice';
import options from './options';
import { StyledError, StyledField, StyledForm, StyledSelect } from './styles';

const OwnedLoanForm = ({ disabled, onSubmit }: OwnedLoanFormProps) => {
  const currencies = useSelector(selectCurrencies);
  // @ts-ignore
  const defaultValues = { currency_id: currencies[0].id };
  const validate = {};

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, ...formState },
    watch,
    ...methods
    // @ts-ignore
  } = useForm(options({ defaultValues, validate }));

  const minimum_value = watch(minimumValue.name);

  return (
    <FormProvider
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      formState={{ errors, ...formState }}
      watch={watch}
      {...methods}
    >
      <StyledForm
        // @ts-ignore
        onSubmit={handleSubmit((data) => onSubmit({ ...data, minimum_value }))}
      >
        <StyledField
          error={errors[minimumValue.name]}
          length={errors[minimumValue.name] && errors[minimumValue.name].message.length}
        >
          {errors[minimumValue.name] && (
            <StyledError
              error={errors[minimumValue.name]}
              length={errors[minimumValue.name] && errors[minimumValue.name].message.length}
            >
              {errors[minimumValue.name].message}
            </StyledError>
          )}
          <InputNumber {...minimumValue} />
          <StyledSelect {...register(currencyId.name, { valueAsNumber: true })} disabled={disabled}>
            {currencies.map(({ id, name }: Currency) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </StyledSelect>
        </StyledField>
        <Button type={type.submit} text={confirm.label} disabled={disabled} />
      </StyledForm>
    </FormProvider>
  );
};

export default OwnedLoanForm;
