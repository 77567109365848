import styled from 'styled-components';
import { color, flex } from 'styles/enums';
import { FlexMixin } from 'styles/mixins';

export const StyledLoan = styled.div<{ accepted_offer?: boolean }>`
  ${FlexMixin({
    direction: flex.column,
    justifyContent: flex.center,
    alignItems: flex.flexStart,
  })};
`;

export const StyledToggle = styled.div`
  position: relative;

  > div {
    height: 2rem;

    position: absolute;
    top: 1rem;
    right: 1rem;

    padding: 1rem;

    &:before {
      display: inline-block;
      content: '';

      height: 0.6rem;
      width: 0.6rem;

      border-bottom: 0.2rem solid ${color.white};
      border-right: 0.2rem solid ${color.white};

      transform: rotate(45deg);
    }
  }
`;
