const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
];

const EIP712FlashOffer = [
  { name: 'nonce', type: 'uint32' },
  { name: 'expirationTime', type: 'uint40' },
  { name: 'loanTokenAddress', type: 'address' },
  { name: 'loanAmount', type: 'uint256' },
  { name: 'returnAmount', type: 'uint256' },
];

export const primaryType = 'FlashOffer';

export const types = {
  EIP712Domain,
  FlashOffer: EIP712FlashOffer,
};
